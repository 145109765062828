import Dashboard from "../pages/Dashboard";
import Request from "../pages/Request";
import SingleRequest from "../pages/SingleRequest";
import Loans from "../pages/Loans";
import SingleLoan from "../pages/SingleLoan";
import Disbursements from "../pages/Disbursements";
import SingleDisbursement from "../pages/SingleDisbursement";
import Repayments from "../pages/Repayments";
import SingleRepayment from "../pages/SingleRepayment";
import Customers from "../pages/Customers";
import API from "../pages/API";
import APIWelcomePage from "../pages/APIWelcomePage";
import CreateAPP from "../pages/CreateAPP";
import { AppProvider } from "../context/APIContext";
import Root from "../pages/Root";

export const dashboardRoutes = (user) => [
  {
    path: "/",
    exact: true,
    title: () => <h1 className="text-2xl font-bold">Dashboard</h1>,
    main: () => <Root />,
  },

  user?.data?.lead_generation && {
    path: "/home",
    exact: true,
    title: () => <h1 className="text-2xl font-bold">Dashboard</h1>,
    main: () => <Dashboard />,
  },
  user?.data?.lead_generation && {
    path: "/requests",
    exact: true,
    title: () => <h1 className="text-2xl font-bold">Loan Requests</h1>,
    main: () => <Request />,
  },

  user?.data?.lead_generation && {
    path: "/requests/:id",
    title: () => <h1 className="text-2xl font-bold">Loan Request</h1>,
    main: () => <SingleRequest />,
  },

  user?.data?.disburse_with_ec === "yes" && {
    path: "/loans",
    exact: true,
    title: () => <h1 className="text-2xl font-bold">Loan Overview</h1>,
    main: () => <Loans />,
  },

  user?.data?.disburse_with_ec === "yes" && {
    path: "/loans/:id",
    title: () => <h1 className="text-2xl font-bold">Loan Repayment</h1>,
    main: () => <SingleLoan />,
  },

  user?.data?.disburse_with_ec === "yes" && {
    path: "/disbursements",
    exact: true,
    title: () => <h1 className="text-2xl font-bold">Disbursements</h1>,
    main: () => <Disbursements />,
  },

  user?.data?.disburse_with_ec === "yes" && {
    path: "/disbursements/:id",
    title: () => <h1 className="text-2xl font-bold">Disbursement</h1>,
    main: () => <SingleDisbursement />,
  },

  user?.data?.disburse_with_ec === "yes" && {
    path: "/repayments",
    exact: true,
    title: () => <h1 className="text-2xl font-bold">Repayments</h1>,
    main: () => <Repayments />,
  },

  user?.data?.disburse_with_ec === "yes" && {
    path: "/repayments/:id",
    title: () => <h1 className="text-2xl font-bold">Repayment</h1>,
    main: () => <SingleRepayment />,
  },

  user?.data?.disburse_with_ec === "yes" && {
    path: "/customers",
    exact: true,
    title: () => <h1 className="text-2xl font-bold">Customer</h1>,
    main: () => <Customers />,
  },

  user?.data?.bs_analyzer && {
    path: "/analyser",
    exact: true,
    title: () => <h1 className="text-2xl font-bold">API Services</h1>,
    main: () => <APIWelcomePage />,
  },

  user?.data?.bs_analyzer && {
    path: "/apps",
    exact: true,
    title: () => <h1 className="text-2xl font-bold">APPs</h1>,
    main: () => (
      <AppProvider>
        <CreateAPP />
      </AppProvider>
    ),
  },

  user?.data?.bs_analyzer && {
    path: "/analyser/:id",
    exact: true,
    title: () => <h1 className="text-2xl font-bold">API Services</h1>,
    main: () => (
      <AppProvider>
        <API />
      </AppProvider>
    ),
  },
];
