import React, { useState } from "react";
import { Button, notification } from "antd";
import networkRequest from "../../utlis/Request";
import { useAuth } from "../../context/AuthContext";

function ChargeBtn({ ID }) {
  const { user } = useAuth();
  const [loading, setLoading] = useState(false);

  const chargenow = async () => {
    setLoading(true);
    const charge = await networkRequest.GET(
      `/repayment/${ID}/debit`,
      user?.data?.token
    );

    if (charge.status !== "success") {
      setLoading(false);
      notification.error({ message: charge?.message });
      return;
    }

    setLoading(false);
    notification.success({ message: charge?.message });
  };
  return (
    <div>
      <Button loading={loading} type="primary" onClick={chargenow}>
        Charge now
      </Button>
    </div>
  );
}

export default ChargeBtn;
