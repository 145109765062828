import React, { useState } from "react";
import useSWR, { trigger } from "swr";
import networkRequest from "../utlis/Request";

import { useAuth } from "../context/AuthContext";
import decision from "../assets/images/decision.png";
import { useParams } from "react-router-dom";
import {
  Button,
  Card,
  Divider,
  Avatar,
  message,
  Row,
  Col,
  Badge,
  Modal,
  Input,
  Form,
  notification,
  Empty,
} from "antd";
import { ArrowLeftOutlined, UserOutlined } from "@ant-design/icons";
import { currency } from "../utlis/currencyFormater";
import { useHistory } from "react-router-dom";
import moment from "moment";

function SingleRepayment(props) {
  const history = useHistory();
  const [isMarkPaidModal, setIsMarkedPaidModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [viewMore, setViewMore] = useState(false);
  const { user } = useAuth();
  const { id } = useParams();

  const { data: repayment, error } = useSWR(`/repayment/${id}`, (url) =>
    networkRequest.GET(url, user?.data?.token)
  );

  const { data: charge_attempts, errorAttempts } = useSWR(
    `/repayments/${id}/micro/charge_attempts`,
    (url) => networkRequest.GET(url, user?.data?.token)
  );

  const { data: more_trans, errorMore_Trans } = useSWR(
    `/repayments/${id}/sub`,
    (url) => networkRequest.GET(url, user?.data?.token)
  );
  const summary = repayment?.data;

  const handlePaid = async (values) => {
    setLoading(true);
    const approval = await networkRequest.PUT_V3(
      `/repayment/${id}/mark_repaid`,
      user?.data?.token,
      {
        note: values.note,
        direct_bank_transfer: true,
      }
    );

    if (approval.status !== "success") {
      setLoading(false);
      notification.error({ message: approval.message });
      return;
    }
    setLoading(false);
    message.success(approval.message);
    trigger(`/repayment/${id}`);
    setIsMarkedPaidModal(false);
  };

  if (error) {
    message.loading("retrying...");
    console.log(errorAttempts, errorMore_Trans);
  }

  return (
    <div className="mb-20">
      <div className="flex justify-between mt-2">
        <span
          className="text-gray-500 cursor-pointer"
          onClick={() => history.goBack()}
        >
          <span>
            <ArrowLeftOutlined />
          </span>
          <span> back</span>
        </span>
      </div>
      <div className="mt-5">
        <Row gutter={[10, 10]}>
          <Col xs={24} md={12} lg={12}>
            <Card loading={!repayment}>
              <h2 className="font-bold text-lg"> Repayment Details</h2>
              <p className="text-gray-500 mt-2">
                Review the transaction details
              </p>
              <Divider />
              <div className="flex justify-between">
                <h2 className="text-lg font-bold capitalize">summary</h2>
                <Button
                  type="default"
                  style={{
                    backgroundColor: summary?.paid ? "#c4f8e2" : "black",
                    color: summary?.paid ? "#06a561" : "white",
                  }}
                >
                  {summary?.status}
                </Button>
              </div>
              <div className="flex justify-between mt-5">
                <div className="flex">
                  <div>
                    <Avatar
                      shape="square"
                      size="large"
                      icon={<UserOutlined />}
                    />
                  </div>
                  <div className="ml-5">
                    <h2 className="font-bold">
                      {`${summary?.borrower?.firstname || ""} .${
                        user?.data?.type === "micro"
                          ? summary?.borrower?.lastname[0] || ""
                          : summary?.borrower?.lastname || ""
                      }`}
                    </h2>
                    {/* <h4 className="text-gray-500">103829193 - Access bank</h4> */}
                  </div>
                </div>
              </div>
              <div className="my-3">
                <h2 className="text-gray-500">
                  Due:{" "}
                  {moment(summary?.due_date.split(" ")[0]).format(
                    "dddd, MMMM Do YYYY, h:mm a"
                  )}
                </h2>
              </div>
              <Divider />
              <div>
                <div className="flex justify-between">
                  <h2 className="text-gray-500">Transaction ID</h2>
                  <h4 className="text-gray-500">{summary?.id}</h4>
                </div>
              </div>

              <Divider />
              <div className="flex justify-between mt-5 mb-10">
                <h2 className="font-bold">{`${
                  summary?.paid ? "Amount Repaid" : "Amount to be repaid"
                }`}</h2>
                <h4 className="font-bold text-blue-700 text-lg">
                  {currency(summary?.amount)}
                </h4>
              </div>

              <div className="flex justify-between">
                {!summary?.paid && (
                  <Button onClick={() => setIsMarkedPaidModal(true)}>
                    Mark as paid
                  </Button>
                )}

                <Button
                  type="link"
                  onClick={() => setViewMore((prev) => !prev)}
                >
                  {viewMore ? "View Less" : "More Details"}
                </Button>
              </div>
              {viewMore && (
                <div>
                  {!more_trans?.data && (
                    <div className="my-3">
                      <Empty />
                    </div>
                  )}
                  {more_trans?.data?.map((charges, index) => (
                    <div className="my-3" key={index}>
                      <Divider />
                      <div className="flex justify-between mt-5 mb-10">
                        <h2 className="font-bold">Amount</h2>
                        <h4 className="font-bold text-gray-700 text-lg">
                          {currency(charges?.amount)}
                        </h4>
                      </div>

                      <div className="flex justify-between mt-5 mb-10">
                        <h2 className="font-bold">Attempt Message</h2>
                        <h4 className="font-bold text-gray-700 text-sm">
                          {charges?.attempt_message}
                        </h4>
                      </div>

                      <div className="flex justify-between mt-5 mb-10">
                        <h2 className="font-bold">Attempt Status</h2>
                        <h4 className="font-bold text-gray-700 text-sm">
                          {charges?.attempt_status}
                        </h4>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </Card>
          </Col>
          <Col xs={24} md={12} lg={12}>
            {charge_attempts?.data && (
              <Card>
                <h2 className="font-bold text-lg">
                  Charge Attempts (on this repayment)
                </h2>
                <Divider />
                {charge_attempts?.data?.map((charge, index) => (
                  <div className="my-5" key={index}>
                    <div className="flex justify-center">
                      <Badge
                        count={index + 1}
                        size="default"
                        style={{
                          backgroundColor: charge?.success ? "green" : "red",
                        }}
                      />
                    </div>
                    <div className="flex justify-between border-0 border-b pb-2 pt-2 border-solid">
                      <div>Amount</div>
                      <div>{currency(summary?.amount)}</div>
                    </div>

                    <div className="flex justify-between border-0 border-b pb-2 pt-2 border-solid">
                      <div>Gateway Response</div>
                      <div>{charge?.message}</div>
                    </div>

                    <div className="flex justify-between border-0 border-b pb-2 pt-2 border-solid">
                      <div>Date</div>
                      <div>
                        {moment(charge?.created_at).format("ddd, MMM Do YYYY")}
                      </div>
                    </div>
                  </div>
                ))}
              </Card>
            )}
          </Col>
        </Row>
      </div>

      <Modal
        visible={isMarkPaidModal}
        footer={null}
        onCancel={() => setIsMarkedPaidModal(false)}
      >
        <div>
          <div className=" w-full flex justify-center">
            <img
              alt="success"
              src={decision}
              className="object-center justify-self-auto mt-10 w-20"
            />
          </div>

          <p className="text-lg my-5 text-center">
            You are about to confirm that this repayment has been paid
          </p>
          <div>
            <Form
              layout="vertical"
              initialValues={{ remember: true }}
              onFinish={handlePaid}
            >
              <Row gutter={[2, 2]}>
                <Col xs={24} md={24} lg={24}>
                  <Form.Item
                    label="How was this payment made"
                    name="note"
                    rules={[
                      {
                        required: true,
                        message: "Note is required!",
                      },
                    ]}
                  >
                    <Input.TextArea />
                  </Form.Item>
                </Col>
              </Row>

              <Form.Item>
                <div className="p-2 w-full flex justify-end">
                  <Button
                    htmlType="submit"
                    loading={loading}
                    size="large"
                    type="danger"
                  >
                    Submit
                  </Button>
                </div>
              </Form.Item>
            </Form>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default SingleRepayment;
