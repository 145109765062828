import React from "react";
import moment from "moment";
import { Divider, Row, Col } from "antd";
import { formatNumber } from "../../../utlis/numberFormter";
import { currency } from "../../../utlis/currencyFormater";

function HealthFacility({ healthFacilities }) {
  const services = healthFacilities?.services_offered;
  return (
    <div className="mt-4">
      <Row gutter={[10, 10]}>
        <Col xs={24} md={8}>
          <div className="flex flex-col justify-between  h-10 ">
            <h3 className="text-gray-500 "> Name </h3>
            <h3 className="">{healthFacilities?.name}</h3>
          </div>
          <Divider />
        </Col>

        <Col xs={24} md={8}>
          <div className="flex flex-col justify-between  h-10 ">
            <h3 className="text-gray-500 "> Email </h3>
            <h3 className="text-black ">{healthFacilities?.email_address}</h3>
          </div>
          <Divider />
        </Col>

        <Col xs={24} md={8}>
          <div className="flex flex-col justify-between  h-10 ">
            <h3 className="text-gray-500 "> Phone </h3>
            <h3 className="text-black ">{healthFacilities?.phone_number}</h3>
          </div>
          <Divider />
        </Col>

        <Col xs={24} md={8}>
          <div className="flex flex-col justify-between  h-10 ">
            <h3 className="text-gray-500 "> Average Monthly Revenue </h3>
            <h3 className="text-black ">
              {currency(healthFacilities?.avg_monthly_revenue)}
            </h3>
          </div>
          <Divider />
        </Col>

        <Col xs={24} md={8}>
          <div className="flex flex-col justify-between  h-10 ">
            <h3 className="text-gray-500 "> Business Registration </h3>
            <h3 className="text-black ">
              {healthFacilities?.business_registration}
            </h3>
          </div>
          <Divider />
        </Col>

        <Col xs={24} md={8}>
          <div className="flex flex-col justify-between  h-10 ">
            <h3 className="text-gray-500 "> Daily Visit Count</h3>
            <h3 className="text-black ">
              {formatNumber(healthFacilities?.daily_visit_count)}
            </h3>
          </div>
          <Divider />
        </Col>

        <Col xs={24} md={8}>
          <div className="flex flex-col justify-between  h-10 ">
            <h3 className="text-gray-500 "> Doctors Count </h3>
            <h3 className="text-black ">
              {formatNumber(healthFacilities?.doctors_count)}
            </h3>
          </div>
          <Divider />
        </Col>

        <Col xs={24} md={8}>
          <div className="flex flex-col justify-between  h-10 ">
            <h3 className="text-gray-500 "> Facility Type </h3>
            <h3 className="text-black ">{healthFacilities?.facility_type}</h3>
          </div>
          <Divider />
        </Col>

        <Col xs={24} md={8}>
          <div className="flex flex-col justify-between  h-10 ">
            <h3 className="text-gray-500 "> Doctors Count </h3>
            <h3 className="text-black ">
              {formatNumber(healthFacilities?.doctors_count)}
            </h3>
          </div>
          <Divider />
        </Col>

        <Col xs={24} md={8}>
          <div className="flex flex-col justify-between  h-10 ">
            <h3 className="text-gray-500 "> Lab Technicians Count </h3>
            <h3 className="text-black ">
              {formatNumber(healthFacilities?.lab_technicians_count)}
            </h3>
          </div>
          <Divider />
        </Col>

        <Col xs={24} md={8}>
          <div className="flex flex-col justify-between  h-10 ">
            <h3 className="text-gray-500"> Local Government Area </h3>
            <h3 className="text-black ">{healthFacilities?.lga}</h3>
          </div>
          <Divider />
        </Col>

        <Col xs={24} md={8}>
          <div className="flex flex-col justify-between  h-10 ">
            <h3 className="text-gray-500 ">Patients Monthly Visit </h3>
            <h3 className="text-black ">
              {formatNumber(healthFacilities?.monthly_count)}
            </h3>
          </div>
          <Divider />
        </Col>

        <Col xs={24} md={8}>
          <div className="flex flex-col justify-between  h-10 ">
            <h3 className="text-gray-500 "> Number of Locations </h3>
            <h3 className="text-black ">
              {healthFacilities?.number_of_locations}
            </h3>
          </div>
          <Divider />
        </Col>

        <Col xs={24} md={8}>
          <div className="flex flex-col justify-between  h-10 ">
            <h3 className="text-gray-500 "> Nurse Count </h3>
            <h3 className="text-black ">
              {formatNumber(healthFacilities?.nurse_count)}
            </h3>
          </div>
          <Divider />
        </Col>

        <Col xs={24} md={8}>
          <div className="flex flex-col justify-between  h-10 ">
            <h3 className="text-gray-500 "> Operating Hours </h3>
            <h3 className="text-black ">{healthFacilities?.operating_hours}</h3>
          </div>
          <Divider />
        </Col>

        <Col xs={24} md={8}>
          <div className="flex flex-col justify-between  h-10 ">
            <h3 className="text-gray-500 "> Ownership Type </h3>
            <h3 className="text-black ">{healthFacilities?.ownership_type}</h3>
          </div>
          <Divider />
        </Col>

        <Col xs={24} md={8}>
          <div className="flex flex-col justify-between  h-10 ">
            <h3 className="text-gray-500 "> Pharmacist Count </h3>
            <h3 className="text-black ">
              {formatNumber(healthFacilities?.pharmacist_count)}
            </h3>
          </div>
          <Divider />
        </Col>

        <Col xs={24} md={8}>
          <div className="flex flex-col justify-between  h-10 ">
            <h3 className="text-gray-500 ">
              {" "}
              Government Registration (RC Number){" "}
            </h3>
            <h3 className="text-black ">{healthFacilities?.rc_number}</h3>
          </div>
          <Divider />
        </Col>

        <Col xs={24} md={8}>
          <div className="flex flex-col justify-between  h-10 ">
            <h3 className="text-gray-500 ">Number Of Registered Patients</h3>
            <h3 className="text-black ">
              {formatNumber(healthFacilities?.registered_patients_count)}
            </h3>
          </div>
          <Divider />
        </Col>

        <Col xs={24} md={8}>
          <div className="flex flex-col justify-between  h-10 ">
            <h3 className="text-gray-500 "> Registration Date </h3>
            <h3 className="text-black ">
              {moment(healthFacilities?.registration_date).format("YYYY-MM-DD")}
            </h3>
          </div>
          <Divider />
        </Col>

        <Col xs={24} md={8}>
          <div className="flex flex-col justify-between  h-10 ">
            <h3 className="text-gray-500 "> Start Date </h3>
            <h3 className="text-black ">
              {moment(healthFacilities?.start_date).format("YYYY-MM-DD")}
            </h3>
          </div>
          <Divider />
        </Col>

        <Col xs={24} md={8}>
          <div className="flex flex-col justify-between  h-10 ">
            <h3 className="text-gray-500 "> State </h3>
            <h3 className="text-black ">{healthFacilities?.state}</h3>
          </div>
          <Divider />
        </Col>

        <Col xs={24} md={8}>
          <div className="flex flex-col justify-between  h-10 ">
            <h3 className="text-gray-500 ">State Medical License Number</h3>
            <h3 className="text-black ">
              {healthFacilities?.state_medical_licence_number}
            </h3>
          </div>
          <Divider />
        </Col>

        <Col xs={24} md={8}>
          <div className="flex flex-col justify-between  h-10 ">
            <h3 className="text-gray-500 "> Total Staff Count </h3>
            <h3 className="text-black ">
              {formatNumber(healthFacilities?.total_staff_count)}
            </h3>
          </div>
          <Divider />
        </Col>

        <Col xs={24} md={8}>
          <div className="flex flex-col justify-between  h-10 ">
            <h3 className="text-gray-500 "> Using Helium EMR </h3>
            <h3 className="text-black ">
              {healthFacilities?.using_helium_emr ? "YES" : "NO"}
            </h3>
          </div>
          <Divider />
        </Col>

        <Col xs={24} md={24}>
          <div className="flex flex-col justify-between  h-10 ">
            <h3 className="text-gray-500 "> Address </h3>
            <h3 className=" text-black">{healthFacilities?.address}</h3>
          </div>
          <Divider />
        </Col>

        <Col xs={24} md={24}>
          <div className="flex flex-col justify-between  h-10 ">
            <h3 className="text-gray-500 "> Services Offered </h3>

            <Row gutter={[10, 10]}>
              {services?.map((service) => (
                <Col xs={24} md={5}>
                  <h3 key={service} className="text-black ">
                    {service}
                  </h3>
                </Col>
              ))}
            </Row>
          </div>
          <Divider />
        </Col>
      </Row>
    </div>
  );
}

export default HealthFacility;
