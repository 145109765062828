import React from "react";
import { Divider } from "antd";
import BarChart from "../charts/BarChart";

const Perfomance = () => {
  return (
    <div>
      <h3 className="font-bold text-base">Last 7 Days Performance</h3>
      <div className="mt-5">
        <h3 className="font-bold text-2xl">0</h3>
        <h3 className="text-gray-500">Loan Disbursed</h3>
      </div>
      <div className="mt-5">
        <h3 className="font-bold text-2xl">0</h3>
        <h3 className="text-gray-500">Total Loan Amount</h3>
      </div>
      <Divider />
      <div className="pl-4 mb-5">
        <div className="stats position-relative" style={{ height: "257px" }}>
          <BarChart title="Loan Value" />
        </div>
      </div>
    </div>
  );
};

export default Perfomance;
