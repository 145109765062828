import React from "react";
import {
  VictoryTheme,
  VictoryChart,
  VictoryLine,
  VictoryAxis,
  VictoryTooltip,
} from "victory";

function LineChart() {
  return (
    <VictoryChart
      theme={VictoryTheme.material}
      domainPadding={40}
      width={1000}
      height={500}
    >
      <VictoryAxis
        dependentAxis
        tickFormat={(y) => `${y}`}
        style={{ axis: { stroke: "transparent" } }}
      />
      <VictoryAxis
        style={{ axis: { stroke: "none" } }}
        tickFormat={(x) => `${x}am`}
        scale={{ x: "time" }}
      />
      <VictoryLine
        style={{
          data: { stroke: "#5b45e0", strokeWidth: 5 },
        }}
        data={[
          { x: 1, y: 30, label: "35 Loans May 22, 8:00AM" },
          { x: 1, y: 12 },
          { x: 1, y: 23 },
          { x: 1, y: 45 },
          { x: 1, y: 52 },
          { x: 1, y: 15 },
          { x: 1, y: 25 },
          { x: 1, y: 11 },
          { x: 1, y: 14 },
          { x: 1, y: 40 },
          { x: 1, y: 30 },
          { x: 2, y: 35 },
        ]}
        labelComponent={
          <VictoryTooltip
            flyoutWidth={95}
            flyoutHeight={35}
            cornerRadius={5}
            pointerLength={40}
            flyoutStyle={{
              stroke: "#868C97",
              strokeWidth: 3,
              fill: "#333752",
            }}
            style={{
              fill: "#fff",
              fontSize: 20,
              fontWeight: 500,
              textAnchor: "middle",
            }}
          />
        }
      />
    </VictoryChart>
  );
}

export default LineChart;
