import React from "react";
import useSWR from "swr";
import networkRequest from "../utlis/Request";
import { useAuth } from "../context/AuthContext";
import { Row, Col, Card, message, Tooltip } from "antd";
import { formatNumber } from "../utlis/numberFormter";
import { ReactComponent as Chart2 } from "../assets/svg/chart2.svg";
import { ReactComponent as Chart6 } from "../assets/svg/chart6.svg";
import { ReactComponent as Chart7 } from "../assets/svg/chart7.svg";
import { currency } from "../utlis/currencyFormater";
import AllRepaymentsTable from "../components/Table/AllRepayments";

function Repayment(props) {
  const { user } = useAuth();
  const { data: repayment, error } = useSWR("/repayments", (url) =>
    networkRequest.GET(url, user?.data?.token)
  );

  const { data: analytics, analyticsError } = useSWR(
    "/repayments/analytics",
    (url) => networkRequest.GET(url, user?.data?.token)
  );

  if (error || analyticsError) {
    message.loading("retrying...");
  }

  return (
    <div>
      <div className="flex justify-between mb-7">
        <h2 className="font-bold text-2xl">Repayments</h2>
      </div>
      <div>
        <Row gutter={[32, 20]} className="flex justify-between">
          <Col xs={24} md={12} lg={8}>
            <Card>
              <Row>
                <Col xs={24} md={14}>
                  <Tooltip title={analytics?.data?.total_repayment_count?.msg}>
                    <h3 className="text-gray-500 text-xs">Total Repayments</h3>

                    <h3 className="font-bold text-lg">
                      {formatNumber(
                        analytics?.data?.total_repayment_count?.value
                      )}
                    </h3>
                  </Tooltip>

                  <Tooltip title={analytics?.data?.total_repayment_value?.msg}>
                    <h3 className="text-gray-500 text-xs my-5">
                      Total Repayments value
                    </h3>

                    <h3 className="font-bold text-lg">
                      {currency(analytics?.data?.total_repayment_value?.value)}
                    </h3>
                  </Tooltip>
                </Col>
                <Col xs={24} md={10}>
                  <div className="w-full h-full sm:justify-end flex items-end">
                    <Chart2 />
                  </div>
                </Col>
              </Row>
            </Card>
          </Col>
          <Col xs={24} md={12} lg={8}>
            <Card>
              <Row>
                <Col xs={24} md={14}>
                  <Tooltip
                    title={analytics?.data?.total_outstanding_count?.msg}
                  >
                    <h3 className="text-gray-500 text-xs">
                      Outstanding Repayments
                    </h3>

                    <h3 className="font-bold text-lg">
                      {formatNumber(
                        analytics?.data?.total_outstanding_count?.value
                      )}
                    </h3>
                  </Tooltip>

                  <Tooltip
                    title={analytics?.data?.total_outstanding_value?.msg}
                  >
                    <h3 className="text-gray-500 text-xs my-5">
                      Outstanding Repayments value
                    </h3>

                    <h3 className="font-bold text-lg">
                      {currency(
                        analytics?.data?.total_outstanding_value?.value
                      )}
                    </h3>
                  </Tooltip>
                </Col>
                <Col xs={24} md={10}>
                  <div className="w-full h-full sm:justify-end flex items-end">
                    <Chart6 />
                  </div>
                </Col>
              </Row>
            </Card>
          </Col>
          <Col xs={24} md={12} lg={8}>
            <Card>
              <Row>
                <Col xs={24} md={14}>
                  <Tooltip title={analytics?.data?.total_repaid_count?.msg}>
                    <h3 className="text-gray-500 text-xs">Total Repaid</h3>

                    <h3 className="font-bold text-lg">
                      {formatNumber(analytics?.data?.total_repaid_count?.value)}
                    </h3>
                  </Tooltip>

                  <Tooltip title={analytics?.data?.total_repaid_value?.msg}>
                    <h3 className="text-gray-500 text-xs my-5">
                      Total Repaid value
                    </h3>

                    <h3 className="font-bold text-lg">
                      {currency(analytics?.data?.total_repaid_value?.value)}
                    </h3>
                  </Tooltip>
                </Col>
                <Col xs={24} md={10}>
                  <div className="w-full h-full sm:justify-end flex items-end">
                    <Chart7 />
                  </div>
                </Col>
              </Row>
            </Card>
          </Col>

          <Col xs={24} md={12} lg={8}>
            <Card>
              <Row>
                <Col xs={24} md={14}>
                  <Tooltip title={analytics?.data?.collections_wallet?.msg}>
                    <h3 className="text-gray-500 text-xs">
                      Collections Wallet
                    </h3>

                    <h3 className="font-bold text-lg">
                      {currency(analytics?.data?.collections_wallet?.value)}
                    </h3>
                  </Tooltip>
                </Col>
                <Col xs={24} md={10}>
                  <div className="w-full h-full sm:justify-end flex items-end">
                    <Chart7 />
                  </div>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </div>
      <div className="my-10">
        <Card>
          <h2 className="text-lg text-black font-bold">Top Repayments</h2>
          <AllRepaymentsTable
            data={repayment?.data}
            showChargenowBtn={user?.data?.type === "micro"}
            ordID={user?.data?.org_id}
          />
        </Card>
      </div>
    </div>
  );
}

export default Repayment;
