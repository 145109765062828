import * as React from "react"

function OrangeSwipe(props) {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#prefix__clip0)">
        <rect
          opacity={0.1}
          x={40}
          width={40}
          height={40}
          rx={20}
          transform="rotate(90 40 0)"
          fill="#E09945"
        />
        <mask
          id="prefix__a"
          maskUnits="userSpaceOnUse"
          x={14}
          y={12}
          width={12}
          height={16}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M20.834 15.825L17.5 12.5l-3.333 3.325h2.5v5.842h1.666v-5.842h2.5zM22.5 27.5l3.334-3.325h-2.5v-5.842h-1.667v5.842h-2.5L22.5 27.5z"
            fill="#fff"
          />
        </mask>
        <g mask="url(#prefix__a)">
          <path fill="#E09945" d="M40.833-.833v41.667H-.834V-.833z" />
          <mask
            id="prefix__b"
            maskUnits="userSpaceOnUse"
            x={-1}
            y={-1}
            width={42}
            height={42}
          >
            <path
              transform="rotate(90 40.834 -.833)"
              fill="#fff"
              d="M40.834-.833h41.667v41.667H40.834z"
            />
          </mask>
        </g>
      </g>
      <defs>
        <clipPath id="prefix__clip0">
          <path fill="#fff" transform="rotate(90 20 20)" d="M0 0h40v40H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default OrangeSwipe;
