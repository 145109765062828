import React from "react";
import { Divider, Row, Col } from "antd";
import { currency } from "../../../utlis/currencyFormater";

function FacilityExpenses({ facilityExpenses }) {
  return (
    <div className="mt-4">
      <Row gutter={[10, 10]}>
        <Col xs={24} md={8}>
          <div className="flex flex-col justify-between  h-10 ">
            <h3 className="text-gray-500"> Annual Rent </h3>
            <h3>{currency(facilityExpenses?.annual_rent)}</h3>
          </div>
          <Divider />
        </Col>

        <Col xs={24} md={8}>
          <div className="flex flex-col justify-between  h-10 ">
            <h3 className="text-gray-500"> Average Monthly Expense </h3>
            <h3>{currency(facilityExpenses?.avg_monthly_exp)}</h3>
          </div>
          <Divider />
        </Col>

        <Col xs={24} md={8}>
          <div className="flex flex-col justify-between  h-10 ">
            <h3 className="text-gray-500"> Average Monthly Revenue </h3>
            <h3>{currency(facilityExpenses?.avg_monthly_rev)}</h3>
          </div>
          <Divider />
        </Col>

        <Col xs={24} md={8}>
          <div className="flex flex-col justify-between  h-10 ">
            <h3 className="text-gray-500"> Electricity Cost </h3>
            <h3>{currency(facilityExpenses?.electricity_cost)}</h3>
          </div>
          <Divider />
        </Col>

        <Col xs={24} md={8}>
          <div className="flex flex-col justify-between  h-10 ">
            <h3 className="text-gray-500"> Existing Loan </h3>
            <h3 className="text-black">
              {facilityExpenses?.existing_loan ? "YES" : "NO"}
            </h3>
          </div>
          <Divider />
        </Col>

        <Col xs={24} md={8}>
          <div className="flex flex-col justify-between  h-10 ">
            <h3 className="text-gray-500"> Fuel Cost </h3>
            <h3 className="text-black">
              {currency(facilityExpenses?.fuel_cost)}
            </h3>
          </div>
          <Divider />
        </Col>

        <Col xs={24} md={8}>
          <div className="flex flex-col justify-between  h-10 ">
            <h3 className="text-gray-500"> Internet and Others </h3>
            <h3 className="text-black">
              {currency(facilityExpenses?.internet_and_others)}
            </h3>
          </div>
          <Divider />
        </Col>

        <Col xs={24} md={8}>
          <div className="flex flex-col justify-between  h-10 ">
            <h3 className="text-gray-500"> Last 12 Months Revenue </h3>
            <h3 className="text-black">
              {currency(facilityExpenses?.last12_months_rev)}
            </h3>
          </div>
          <Divider />
        </Col>

        <Col xs={24} md={8}>
          <div className="flex flex-col justify-between  h-10 ">
            <h3 className="text-gray-500"> License and Insurance </h3>
            <h3 className="text-black">
              {currency(facilityExpenses?.licence_and_insurance)}
            </h3>
          </div>
          <Divider />
        </Col>

        <Col xs={24} md={8}>
          <div className="flex flex-col justify-between  h-10 ">
            <h3 className="text-gray-500"> Monthly Loan Servicing Amount </h3>
            <h3 className="text-black">
              {currency(facilityExpenses?.monthly_loan_servicing_amount)}
            </h3>
          </div>
          <Divider />
        </Col>

        <Col xs={24} md={8}>
          <div className="flex flex-col justify-between  h-10 ">
            <h3 className="text-gray-500"> Outstanding Loan Amount </h3>
            <h3 className="text-black">
              {currency(facilityExpenses?.outstanding_loan_amount)}
            </h3>
          </div>
          <Divider />
        </Col>

        <Col xs={24} md={8}>
          <div className="flex flex-col justify-between  h-10 ">
            <h3 className="text-gray-500"> Service Cost </h3>
            <h3 className="text-black">
              {currency(facilityExpenses?.service_cost)}
            </h3>
          </div>
          <Divider />
        </Col>

        <Col xs={24} md={8}>
          <div className="flex flex-col justify-between  h-10 ">
            <h3 className="text-gray-500"> Phone Cost </h3>
            <h3 className="text-black">
              {currency(facilityExpenses?.telephone_cost)}
            </h3>
          </div>
          <Divider />
        </Col>

        <Col xs={24} md={8}>
          <div className="flex flex-col justify-between  h-10 ">
            <h3 className="text-gray-500"> Phone Cost </h3>
            <h3 className="text-black">
              {currency(facilityExpenses?.telephone_cost)}
            </h3>
          </div>
          <Divider />
        </Col>

        <Col xs={24} md={8}>
          <div className="flex flex-col justify-between  h-10 ">
            <h3 className="text-gray-500"> Total Salaries </h3>
            <h3 className="text-black">
              {currency(facilityExpenses?.total_salaries)}
            </h3>
          </div>
          <Divider />
        </Col>

        <Col xs={24} md={8}>
          <div className="flex flex-col justify-between  h-10 ">
            <h3 className="text-gray-500"> Transportation Cost </h3>
            <h3 className="text-black">
              {currency(facilityExpenses?.transportation_cost)}
            </h3>
          </div>
          <Divider />
        </Col>
      </Row>
    </div>
  );
}

export default FacilityExpenses;
