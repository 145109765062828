import React from "react";
import moment from "moment";
import { Row, Col, Card, Divider, Progress, Tooltip } from "antd";
import BlueSwipe from "../../../assets/customIcons/blueSwipe";
import GreenSwipe from "../../../assets/customIcons/greenSwipe";
import OrangeSwipe from "../../../assets/customIcons/orangeSwipe";
import { currency } from "../../../utlis/currencyFormater";

function Repayments({ loanAnalytics, user }) {
  const green = "#00af7b";
  const due = loanAnalytics?.next_repayment?.due_date?.split(" ")[0];

  return (
    <div>
      <div>
        <Row gutter={32} className="flex justify-between">
          <Col className="gutter-row" span={8}>
            <Card>
              <Tooltip title={loanAnalytics?.expected_total?.msg}>
                <div className="flex justify-between">
                  <h2>Total Repayment</h2>
                  <BlueSwipe />
                </div>
                <h2 className="font-bold text-2xl">
                  {currency(loanAnalytics?.expected_total?.value)}
                </h2>
              </Tooltip>
              <Divider />
              <Tooltip title={loanAnalytics?.interest_rate?.msg}>
                <div className="flex">
                  <h2 className="rounded-full py-3 px-6 bg-blue-200 text-blue-500 text-xs h-1 mr-2 flex items-center justify-center">
                    {loanAnalytics?.interest_rate?.value}%
                  </h2>
                  <span className="text-gray-500">{`${
                    user?.data?.type === "micro" ? "Tip" : "Interest rate"
                  }`}</span>
                </div>
              </Tooltip>
            </Card>
          </Col>
          <Col className="gutter-row" span={8}>
            <Card>
              <Tooltip title={loanAnalytics?.total_paid?.msg}>
                <div className="flex justify-between">
                  <h2>Amount Paid so far</h2>
                  <GreenSwipe />
                </div>
                <h2 className="font-bold text-2xl">
                  {currency(loanAnalytics?.total_paid?.value)}
                </h2>
              </Tooltip>
              <Divider />
              <div className="flex item-center">
                <div>
                  <Tooltip
                    title={`${loanAnalytics?.percentage_paid?.value}%`}
                    color={green}
                  >
                    <Progress
                      percent={loanAnalytics?.percentage_paid?.value}
                      steps={10}
                      showInfo={false}
                      strokeColor={green}
                      strokeWidth={5}
                      width={500}
                    />
                  </Tooltip>
                </div>

                <h2 className="text-gray-500 flex justify-end  w-full">
                  {loanAnalytics?.percentage_paid?.value}%
                </h2>
              </div>
            </Card>
          </Col>
          <Col className="gutter-row" span={8}>
            <Card>
              <div className="flex justify-between">
                <h2>Next Repayment</h2>
                <OrangeSwipe />
              </div>
              <h2 className="font-bold text-2xl">
                {currency(loanAnalytics?.next_repayment?.amount)}
              </h2>
              <Divider />
              <div className="flex justify-between">
                <h2 className="text-gray-500 text-xs">Next repayment date</h2>
                <h4 className="text-red-600 text-xs">
                  {moment.utc(due).format("MMMM Do YYYY")}
                </h4>
              </div>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default Repayments;
