import React from "react";
import { Divider, Row, Col } from "antd";
import { currency } from "../../../utlis/currencyFormater";

function EmploymentDetails({ employmentDetails, loan_request }) {
  return (
    <div className="mt-4">
      <Row gutter={[10, 10]}>
        <Col xs={24} md={8}>
          <div className="flex flex-col justify-between  h-10 ">
            <h3 className="text-gray-500">Company </h3>
            <h3>
              {employmentDetails?.employer ||
                employmentDetails?.employment?.employer}
            </h3>
          </div>
          <Divider />
        </Col>

        <Col xs={24} md={8}>
          <div className="flex flex-col justify-between  h-10 ">
            <h3 className="text-gray-500"> Monthly Salary </h3>
            <h3 className="text-black">
              {loan_request?.product_id === "ckl6lnutkau5a09023mo3irar"
                ? "*****"
                : currency(
                    employmentDetails?.monthly_income ||
                      employmentDetails?.employment?.monthly_income
                  )}
            </h3>
          </div>
          <Divider />
        </Col>

        <Col xs={24} md={8}>
          <div className="flex flex-col justify-between  h-10 ">
            <h3 className="text-gray-500"> Employer's Address </h3>
            <h3 className="text-black">
              {employmentDetails?.employers_address ||
                employmentDetails?.employment?.employers_address}
            </h3>
          </div>
          <Divider />
        </Col>

        <Col xs={24} md={8}>
          <div className="flex flex-col justify-between  h-10 ">
            <h3 className="text-gray-500"> Employment status </h3>
            <h3 className="text-black">
              {employmentDetails?.employment_status}
            </h3>
          </div>
          <Divider />
        </Col>

        <Col xs={24} md={8}>
          <div className="flex flex-col justify-between px-5 h-10 ">
            <h3 className="text-gray-500">Occupation</h3>
            <h3 className="text-black">
              {employmentDetails?.occupation ||
                employmentDetails?.employment?.occupation_type?.title}
            </h3>
          </div>
          <Divider />
        </Col>
      </Row>
    </div>
  );
}

export default EmploymentDetails;
