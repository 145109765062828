import React from "react";
import { Table, Button } from "antd";
import { useHistory } from "react-router-dom";
import { currency } from "../../utlis/currencyFormater";

function LoanOverviewTable({ data, showChargenowBtn }) {
  const history = useHistory();
  const columns = [
    {
      title: "Loan Reference",
      dataIndex: "reference",
      key: "reference",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => a.name.length - b.name.length,
    },

    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },

    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
    },

    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (text, record) => (
        <Button
          onClick={() => {
            history.push(`/loans/${record.key}`);
          }}
        >
          View Loan
        </Button>
      ),
    },
  ];

  const cleanData = (data) =>
    data?.map((res, index) => ({
      key: res.id,
      reference: res.ref,
      status: res.status,

      name: showChargenowBtn
        ? res?.borrower?.firstname + " ." + res?.borrower?.lastname[0]
        : res?.borrower?.firstname + " " + res?.borrower?.lastname,
      amount: currency(res.amount),
    }));

  return (
    <Table
      columns={columns}
      dataSource={cleanData(data)}
      pagination={{ position: ["bottomLeft"] }}
      scroll={{ x: 1400, y: 1500 }}
    />
  );
}

export default LoanOverviewTable;
