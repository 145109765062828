import React, { useState } from "react";
import { Row, Col, Form, Button, Input, notification, message } from "antd";
import { useHistory } from "react-router-dom";
import Logo from "../assets/images/logo.png";
import Pattern from "../assets/images/patterns.png";
import Auth from "../utlis/Auth";

function EmailReset(props) {
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const handleLogin = async (values) => {
    setLoading(true);
    const login = await Auth.SendResetEmail(values.email);

    if (login.status) {
      setLoading(false);
      message.success(login.message);
      history.push(`/reset-password?email=${values.email}`);
      return;
    }
    setLoading(false);

    notification.error({ message: login.message });
  };
  return (
    <div className="h-screen flex">
      <div className="flex-grow p-10 mx-32">
        <img src={Logo} alt="evolve credit logo" />

        <div className="my-20">
          <h1 className="text-2xl font-semibold my-5">
            Enter your email address
          </h1>
          <Form
            onFinish={handleLogin}
            layout="vertical"
            initialValues={{ remember: true }}
          >
            <Row gutter={[2, 2]}>
              <Col xs={24} md={24} lg={24}>
                <Form.Item
                  label="Email Address"
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: "Email address is required!",
                    },
                  ]}
                >
                  <Input
                    placeholder="you@example.com"
                    size="large"
                    type="email"
                    className="contact-form_input"
                  />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item>
              <Button
                style={{ width: "100%" }}
                loading={loading}
                type="primary"
                size="large"
                htmlType="submit"
                className="contact-form_submitbutton"
              >
                Send password reset email
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
      <div
        className="flex-shrink-0 w-2/5 h-full bg-green-400 relative"
        style={{ paddingBottom: "50%" }}
      >
        <img
          src={Pattern}
          alt="pattern"
          className="absolute h-full w-full object-cover"
        />
      </div>
    </div>
  );
}

export default EmailReset;
