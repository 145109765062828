import React, { useEffect } from "react";
import { useAuth } from "../context/AuthContext";
import { Spin } from "antd";
import { useHistory } from "react-router-dom";

function Root(props) {
  const { user } = useAuth();
  const history = useHistory();
  useEffect(() => {
    if (
      user?.data?.lead_generation === false &&
      user?.data?.disburse_with_ec === "no" &&
      user?.data?.bs_analyzer
    ) {
      return history.push("/analyser");
    }

    history.push("/requests");
  });

  return (
    <div className="h-screen">
      <Spin />
    </div>
  );
}

export default Root;
