const BASE_URL = process.env.REACT_APP_BASE_URL;
const BASE_URL_V3 = process.env.REACT_APP_BASE_URL_V3;
const BASE_URL_V2 = process.env.REACT_APP_BASE_URL_V2;
const Request = {};

Request.GET = async (path, token) => {
  const res = await fetch(`${BASE_URL}${path}`, {
    method: "GET", // *GET, POST, PUT, DELETE, etc.
    headers: {
      "Content-Type": "application/json",
      Authorization: token ? token : "",
    },
  });
  if (res.ok) {
    return res.json();
  }
};

Request.POST = async (path, token, data) => {
  const res = await fetch(`${BASE_URL}${path}`, {
    method: "POST", // *GET, POST, PUT, DELETE, etc.
    headers: {
      Authorization: token ? token : "",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
  if (res.ok) {
    return res.json();
  }
};

Request.PUT = async (path, token, data) => {
  const res = await fetch(`${BASE_URL}${path}`, {
    method: "PUT", // *GET, POST, PUT, DELETE, etc.
    headers: {
      "Content-Type": "application/json",
      Authorization: token ? token : "",
    },
    body: JSON.stringify(data),
  });
  if (res.ok) {
    return res.json();
  }
};

Request.GET_WITHOUT_AUTH = async (path) => {
  const res = await fetch(`${BASE_URL}${path}`, {
    method: "GET", // *GET, POST, PUT, DELETE, etc.
  });
  if (res.ok) {
    return res.json();
  }
};

Request.POST_WITHOUT_AUTH = async (path, data) => {
  const res = await fetch(`${BASE_URL}${path}`, {
    method: "POST", // *GET, POST, PUT, DELETE, etc.
    body: JSON.stringify(data),
  });
  if (res.ok) {
    return res.json();
  }
};

Request.POST_WITHOUT_AUTH3 = async (path, data) => {
  const res = await fetch(`${process.env.REACT_APP_BASE_URL_AUTH_v3}${path}`, {
    method: "POST", // *GET, POST, PUT, DELETE, etc.
    body: JSON.stringify(data),
  });
  if (res.ok) {
    return res.json();
  }
};

Request.POST_WITHOUT_AUTH2 = async (path, data) => {
  const res = await fetch(`${process.env.REACT_APP_BASE_URL_AUTH}${path}`, {
    method: "POST", // *GET, POST, PUT, DELETE, etc.
    body: JSON.stringify(data),
  });
  if (res.ok) {
    return res.json();
  }
};

Request.GET_WITHOUT_AUTH2 = async (path, data) => {
  const res = await fetch(`${process.env.REACT_APP_BASE_URL_AUTH}${path}`, {
    method: "GET", // *GET, POST, PUT, DELETE, etc.
  });
  if (res.ok) {
    return res.json();
  }
};

Request.GET_V3 = async (path, token) => {
  const res = await fetch(`${BASE_URL_V3}${path}`, {
    method: "GET", // *GET, POST, PUT, DELETE, etc.
    headers: {
      "Content-Type": "application/json",
      Authorization: token ? token : "",
    },
  });
  if (res.ok) {
    return res.json();
  }
};

Request.POST_V3 = async (path, token, data) => {
  const res = await fetch(`${BASE_URL_V3}${path}`, {
    method: "POST", // *GET, POST, PUT, DELETE, etc.
    headers: {
      Authorization: token ? token : "",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
  if (res.ok) {
    return res.json();
  }
};

Request.POST_V2 = async (path, token, data) => {
  const res = await fetch(`${BASE_URL_V2}${path}`, {
    method: "POST", // *GET, POST, PUT, DELETE, etc.
    headers: {
      Authorization: token ? token : "",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
  if (res.ok) {
    return res.json();
  }
};

Request.GET_V2 = async (path, token, data) => {
  const res = await fetch(`${BASE_URL_V2}${path}`, {
    method: "GET", // *GET, POST, PUT, DELETE, etc.
    headers: {
      Authorization: token ? token : "",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
  if (res.ok) {
    return res.json();
  }
};


Request.PUT_V2 = async (path, token, data) => {
  const res = await fetch(`${BASE_URL_V2}${path}`, {
    method: "PUT", // *GET, POST, PUT, DELETE, etc.
    headers: {
      Authorization: token ? token : "",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
  if (res.ok) {
    return res.json();
  }
};

Request.PUT_V3 = async (path, token, data) => {
  const res = await fetch(`${BASE_URL_V3}${path}`, {
    method: "PUT", // *GET, POST, PUT, DELETE, etc.
    headers: {
      "Content-Type": "application/json",
      Authorization: token ? token : "",
    },
    body: JSON.stringify(data),
  });
  if (res.ok) {
    return res.json();
  }
};

export default Request;
