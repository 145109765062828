import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Form,
  Button,
  Input,
  notification,
  message,
  Alert,
} from "antd";
import { useHistory, useLocation } from "react-router-dom";
import Logo from "../assets/images/logo.png";
import Pattern from "../assets/images/patterns.png";
import Auth from "../utlis/Auth";

function ResetPassword(props) {
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const formRef = React.createRef();

  const email = new URLSearchParams(useLocation().search);

  const handleLogin = async (values) => {
    setLoading(true);
    const login = await Auth.ResetPassword({
      email: values.email,
      new_password: values.new_password,
      reset_token: values.reset_token,
    });

    if (login.status) {
      setLoading(false);
      message.success(login.message);
      history.push("/sign-in");
      return;
    }
    setLoading(false);

    notification.error({ message: login.message });
  };

  useEffect(() => {
    formRef.current.setFieldsValue({
      email: email.get("email"),
    });
  });

  return (
    <div className="h-screen flex">
      <div className="flex-grow p-10 mx-32">
        <img src={Logo} alt="evolve credit logo" />
        <div className="my-10">
          <Alert
            message={`A token was sent to ${
              email.get("email") || "your email"
            }`}
            closable
          />
        </div>

        <div>
          <h1 className="text-2xl font-semibold my-5">Reset Your password</h1>
          <Form
            ref={formRef}
            onFinish={handleLogin}
            layout="vertical"
            initialValues={{ remember: true }}
          >
            <Row gutter={[2, 2]}>
              <Col xs={24} md={24} lg={24}>
                <Form.Item
                  label="Email Address"
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: "Email address is required!",
                    },
                  ]}
                >
                  <Input
                    placeholder="you@example.com"
                    size="large"
                    type="email"
                    className="contact-form_input"
                  />
                </Form.Item>
              </Col>
              <Col xs={24} md={24} lg={24}>
                <Form.Item
                  label="Reset token"
                  name="reset_token"
                  rules={[
                    {
                      required: true,
                      message: "Reset Token is required!",
                    },
                  ]}
                >
                  <Input
                    placeholder="H7210"
                    size="large"
                    className="contact-form_input"
                  />
                </Form.Item>
              </Col>
              <Col xs={24} md={24} lg={24}>
                <Form.Item
                  name="new_password"
                  label="New Password"
                  rules={[
                    {
                      required: true,
                      message: "password is required!",
                    },
                  ]}
                >
                  <Input.Password size="large" className="contact-form_input" />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item>
              <Button
                style={{ width: "100%" }}
                loading={loading}
                type="primary"
                size="large"
                htmlType="submit"
                className="contact-form_submitbutton"
              >
                Sign in
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
      <div
        className="flex-shrink-0 w-2/5 h-full bg-green-400 relative"
        style={{ paddingBottom: "50%" }}
      >
        <img
          src={Pattern}
          alt="pattern"
          className="absolute h-full w-full object-cover"
        />
      </div>
    </div>
  );
}

export default ResetPassword;
