import React from "react";
import { Row, Col, Card, Tooltip } from "antd";
import { ReactComponent as Chart1 } from "../../assets/svg/whiteChart.svg";
import { ReactComponent as Chart2 } from "../../assets/svg/chart2.svg";
import { ReactComponent as Chart3 } from "../../assets/svg/chart.svg";
import { currency } from "../../utlis/currencyFormater";

function DisbursementAnalytics({ analytics }) {
  return (
    <div>
      <Row gutter={[10, 10]} className="flex justify-between">
        <Col className="gutter-row" xs={24} md={12} lg={8}>
          <Card style={{ backgroundColor: "#1b1252" }}>
            <div className="flex justify-between">
              <div className="flex-1">
                <Tooltip title={analytics?.wallet_balance?.msg}>
                  <h3 className="text-gray-500 text-xs">Wallet Balance</h3>
                  <h3 className="font-bold text-lg text-white">
                    {currency(analytics?.wallet_balance?.value)}
                  </h3>
                </Tooltip>
              </div>

              <div className="flex-1 self-end">
                <Chart1 className="h-24 w-20 lg:h-32 lg:w-32" />
              </div>
            </div>
          </Card>
        </Col>

        <Col className="gutter-row" xs={24} md={12} lg={8}>
          <Card>
            <Tooltip title={analytics?.available_balance?.msg}>
              <div className="flex justify-between">
                <div>
                  <h3 className="text-gray-500 text-xs">Available Balance</h3>
                  <h3 className="font-bold text-lg">
                    {currency(analytics?.available_balance?.value)}
                  </h3>
                </div>

                <div className="flex-1 self-end">
                  <Chart3 className="h-24 w-20 lg:h-32 lg:w-32" />
                </div>
              </div>
            </Tooltip>
          </Card>
        </Col>

        <Col className="gutter-row" xs={24} md={12} lg={8}>
          <Card>
            <Tooltip title={analytics?.total_disbursement_value?.msg}>
              <div className="flex justify-between">
                <div className="flex-1">
                  <h3 className="text-gray-500 text-xs">Total Disbursement</h3>
                  <h3 className="font-bold text-lg">
                    {currency(analytics?.total_disbursement_value?.value)}
                  </h3>
                </div>

                <div className="flex-1 self-end">
                  <Chart2 className="h-24 w-20 lg:h-32 lg:w-32" />
                </div>
              </div>
            </Tooltip>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default DisbursementAnalytics;
