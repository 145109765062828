import React from "react";
import moment from "moment";
import { CSVLink } from "react-csv";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Table, Popover } from "antd";
import { useTable } from "../../context/TableContext";
import { currency } from "../../utlis/currencyFormater";
function BankStatementTable({ data, loading, loan_request, statement }) {
  const { requestTableSorter, setRequestTableSorters } = useTable();

  const cleanData = (data) =>
    data?.map((res, index) => ({
      key: res.id,
      type: res.type,
      amount: currency(res.amount),
      balance: currency(res.balance),
      okay: res.okay,
      narration:
        loan_request?.product_id === "ckl6lnutkau5a09023mo3irar"
          ? "**********"
          : res.narration,
      date: moment(res.date).format("YYYY-MM-DD"),
    }));

  const columns = [
    {
      title: "Narration",
      width: 300,
      dataIndex: "narration",
      key: "1",
      fixed: "left",
    },
    {
      title: "Date",
      width: 150,
      dataIndex: "date",
      key: "date",
      sorter: (a, b) => new Date(a.date).valueOf() - new Date(b.date).valueOf(),
      render: (text) => moment(text).format("YYYY-MM-DD"),
    },

    {
      title: "Type",
      width: 150,
      dataIndex: "type",
      key: "type",
      filteredValue: requestTableSorter.type || null,
      filters: [
        {
          text: "Credit",
          value: "Credit",
        },
        {
          text: "Debit",
          value: "Debit",
        },
      ],
      onFilter: (value, record) => record.type.indexOf(value) === 0,
    },

    {
      title: "Amount",
      width: 150,
      dataIndex: "amount",
      key: "4",
      render: (text, record) => (
        <div className="flex items-center">
          <span>{text}</span>
          {!record.okay && (
            <Popover content="Modified">
              <ExclamationCircleOutlined
                style={{ color: "red" }}
                className="mx-2 cursor-pointer"
              />
            </Popover>
          )}
        </div>
      ),
    },

    {
      title: "Balance",
      width: 150,
      dataIndex: "balance",
      key: "5",
    },
  ];

  const handleChange = (
    pagination,
    filters,
    sorter,
    selectedRowKeys,
    selectedRows
  ) => {
    setRequestTableSorters((prevState) => ({ ...prevState, ...filters }));
  };

  return (
    <>
      <div className="flex justify-between items-center m-2 ">
        <p className="text-base uppercase">Transactions</p>
        <CSVLink
          style={{
            backgroundColor: "#5B45E0",
            color: "#fff",
            borderRadius: 5,
            padding: 6,
          }}
          data={statement?.data?.prettyfied || []}
          filename={`${statement?.data?.account_name}.csv`}
        >
          Export to CSV
        </CSVLink>
      </div>

      <Table
        loading={loading === undefined && !loading}
        onChange={handleChange}
        columns={columns}
        dataSource={cleanData(data)}
        size="small"
      />
    </>
  );
}

export default BankStatementTable;
