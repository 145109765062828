import { Card, Row, Col, Progress, Badge } from "antd";

function Fraud({ fraud }) {
  return (
    <div className="my-2">
      <Row gutter={[10, 10]}>
        <Col xs={24} lg={8}>
          <Card className="h-48">
            <div>
              <Progress
                type="circle"
                strokeColor={fraud?.fraud_tendency < 25 ? "#52c41a" : "red"}
                percent={fraud?.fraud_tendency}
                width={50}
              />
              <span className="mx-3">
                {fraud?.fraud_tendency < 25 ? (
                  <Badge style={{ backgroundColor: "#52c41a" }} count="Good" />
                ) : (
                  <Badge count="Bad" />
                )}
              </span>
            </div>

            <p className="my-3">
              This bank statement has a {`${fraud?.fraud_tendency}%`} tendency
              to have been tampered with.
            </p>
          </Card>
        </Col>
        <Col xs={24} lg={8}>
          <Card className="h-48">
            <h2 className=" border-0 border-b border-gray-200 border-solid py-2">
              {fraud?.transaction_count || 0} Transactions
            </h2>

            <p className="my-2">
              There are{" "}
              <strong className="text-purple-800 text-lg mx-1">
                {fraud?.suspicious_credit_count || 0}
              </strong>
              suspicious credit transactions,
              <strong className="text-purple-800 text-lg mx-1">
                {fraud?.suspicious_debit_count || 0}
              </strong>{" "}
              suspicious debit transactions and
              <strong className="text-purple-800 text-lg mx-1">
                {fraud?.transaction_anomaly_count || 0}
              </strong>{" "}
              anomalies in total.
            </p>
          </Card>
        </Col>
        <Col xs={24} lg={8}>
          <Card className="h-48">
            <h2 className=" border-0 border-b border-gray-200 border-solid py-2">
              Recommendation
            </h2>

            <p className="my-2">
              If the fraud tendency is less than 20% you can ignore, otherwise,
              please comb through the transactions. We have tagged the
              suspicious ones for ease of identification.
            </p>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default Fraud;
