import { useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import SecureLS from "secure-ls";
import { authRoutes } from "./routes/authRoutes";
import SiderBar from "./components/layout/SiderBar";
import "./styles/App.less";
import Main from "./components/layout/Main";

const securedStorage = new SecureLS();

function App() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Router>
      {securedStorage.get("user") ? (
        <div className="flex h-screen">
          <SiderBar />

          <Main />
        </div>
      ) : (
        <>
          <Switch>
            {authRoutes.map((route, index) => (
              <Route
                key={index}
                path={route.path}
                exact={route.exact}
                children={<route.main />}
              />
            ))}

            <Route path="*">
              <h1>404</h1>
            </Route>
          </Switch>
        </>
      )}
    </Router>
  );
}

export default App;
