import React from "react";
import { useHistory } from "react-router-dom";
import Enter from "../../assets/customIcons/enter";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { Button } from "antd";

function Heading({ userDetail }) {
  const history = useHistory();
  return (
    <div className="mt-2">
      <Button
        type="link"
        onClick={() => history.goBack()}
        className="text-gray-500"
      >
        <ArrowLeftOutlined />
        <span> back</span>
      </Button>
      <div className="flex  justify-end">
        <Button
          type="default"
          style={{ backgroundColor: "#00af7b" }}
          className=" flex text-white"
        >
          <Enter className="mt-1 mr-1" />
          Loan Disbursed
        </Button>
      </div>
    </div>
  );
}

export default Heading;
