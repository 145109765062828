import { useState } from "react";
import { PlusCircleFilled } from "@ant-design/icons";
import useSWR, { trigger } from "swr";
import {
  Button,
  Card,
  Row,
  Col,
  Modal,
  Divider,
  Form,
  Input,
  notification,
  message,
  Tag,
} from "antd";
import networkRequest from "../utlis/Request";
import { useAuth } from "../context/AuthContext";
import AppCard from "../components/API/AppCard";

function CreateAPP(props) {
  const { user } = useAuth();

  const [openCreateApp, setOpenCreateApp] = useState(false);
  const [loading, setLoading] = useState(false);

  const { data: apps } = useSWR(`/apps`, (url) =>
    networkRequest.GET_V3(url, user?.data?.token)
  );

  const CreateAPP = async (values) => {
    setLoading(true);
    const creatingAPP = await networkRequest.POST_V3(
      `/apps`,
      user?.data?.token,
      values
    );

    if (creatingAPP.status !== "success") {
      setLoading(false);
      notification.error({ message: creatingAPP.message });
      return;
    }
    setLoading(false);
    message.success(creatingAPP.message);
    trigger(`/apps`);
    setOpenCreateApp(false);
  };

  return (
    <div>
      {/* <APIAnalytics app={analytics?.data} api_key={app_key} /> */}

      {/* <Card className="w-full my-10" bodyStyle={{ padding: 0 }}>
        <div className="w-full bg-gray-200 h-36 p-5 px-5 rounded-lg relative">
          <p className="font-bold text-lg">Get started with Apps</p>
          <p className="text-sm">All active apps</p>

          <Button className="my-3">Learn more</Button>
          <img
            src={Shapes}
            width="100"
            alt="shapes"
            className="absolute bottom-0 right-3"
          />
        </div>
      </Card> */}
      <div className="flex justify-center">
        <Tag color="magenta" className="text-base">
          BETA
        </Tag>
      </div>

      <div className="my-10">
        <Row gutter={[10, 10]}>
          {apps?.data?.map((app) => (
            <Col xs={24} md={12} lg={8} key={app?.id}>
              <AppCard app={app} />
            </Col>
          ))}
          <Col xs={24} md={12} lg={8}>
            <Card
              style={{ borderRadius: 9 }}
              onClick={() => setOpenCreateApp(true)}
            >
              <PlusCircleFilled className="text-3xl text-purple-800" />

              <div className="py-3">
                <p className="text-lg text-gray-400">Create an app</p>
              </div>
            </Card>
          </Col>
        </Row>
      </div>

      <Modal
        visible={openCreateApp}
        footer={null}
        onCancel={() => setOpenCreateApp(false)}
      >
        <div className="my-5">
          <Form layout="vertical" onFinish={CreateAPP}>
            <Row>
              <Col xs={24} md={24} lg={24}>
                <Form.Item
                  label="App name"
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: "reason is required!",
                    },
                  ]}
                >
                  <Input size="large" />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item>
              <div className="flex justify-end">
                <Button
                  loading={loading}
                  type="primary"
                  size="large"
                  htmlType="submit"
                  style={{ backgroundColor: "#5b45e0", color: "#fff" }}
                >
                  Create app
                </Button>
              </div>
            </Form.Item>
          </Form>
        </div>
        <Divider />
      </Modal>
    </div>
  );
}

export default CreateAPP;
