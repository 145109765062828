import React from "react";
import { Card, message } from "antd";
import useSWR from "swr";
import { useAuth } from "../context/AuthContext";
import networkRequest from "../utlis/Request";
import { useParams } from "react-router-dom";
import Heading from "../components/loan/Heading";
import Information from "../components/loan/Information";
import Summary from "../components/loan/Summary";
import SingleRepaymentsTable from "../components/Table/SingleRepayments";

function SIngleLoan(props) {
  const { user } = useAuth();
  const { id } = useParams();

  const { data: loan, error } = useSWR(`/loan/${id}`, (url) =>
    networkRequest.GET(url, user?.data?.token)
  );

  const {
    data: repayments,
    error: errorRepayment,
  } = useSWR(`/repayments/by_loan_id/${id}`, (url) =>
    networkRequest.GET(url, user?.data?.token)
  );

  if (error || errorRepayment) {
    message.loading("retrying....");
  }

  return (
    <div>
      <Heading />
      <Summary loan={{ ...loan?.data, ...loan?.data1 }} />
      <Information loanAnalytics={loan?.data1} user={user} />
      <div className="my-10">
        <Card>
          <h2 className="font-bold text-lg mb-10">
            All Repayments (on this Loan)
          </h2>
          <SingleRepaymentsTable data={repayments?.data} />
        </Card>
      </div>
    </div>
  );
}

export default SIngleLoan;
