import React from "react";
import { Table } from "antd";
import { CSVLink } from "react-csv";
import { currency } from "../../utlis/currencyFormater";

const { Column, ColumnGroup } = Table;

function SingleAnalyserStatement({ data, statement }) {
  const cleanData = (data) =>
    data?.map((res, index) => ({
      month: `${res?.month} ${res?.year}`,
      balance: res?.balance,
      total_credit: res?.total_credit,
      avg_credit: res?.avg_credit,
      median_credit: res?.median_credit,
      max_credit: res?.max_credit,
      total_debit: res?.total_debit,
      avg_debit: res?.avg_debit,
      median_debit: res?.median_debit,
      max_debit: res?.max_debit,
      color_balance:
        data[index].balance === 0
          ? "gray"
          : index > 0
          ? data[index - 1].balance > data[index].balance
            ? "red"
            : "green"
          : "green",

      color_total_credit:
        data[index].total_credit === 0
          ? "gray"
          : index > 0
          ? data[index - 1].total_credit > data[index].total_credit
            ? "red"
            : "green"
          : "green",

      color_avg_credit:
        data[index].avg_credit === 0
          ? "gray"
          : index > 0
          ? data[index - 1].avg_credit > data[index].avg_credit
            ? "red"
            : "green"
          : "green",

      color_median_credit:
        data[index].median_credit === 0
          ? "gray"
          : index > 0
          ? data[index - 1].median_credit > data[index].median_credit
            ? "red"
            : "green"
          : "green",

      color_max_credit:
        data[index].max_credit === 0
          ? "gray"
          : index > 0
          ? data[index - 1].max_credit > data[index].max_credit
            ? "red"
            : "green"
          : "green",

      color_total_debit:
        data[index].total_debit === 0
          ? "gray"
          : index > 0
          ? data[index - 1].total_debit > data[index].total_debit
            ? "red"
            : "green"
          : "green",

      color_avg_debit:
        data[index].avg_debit === 0
          ? "gray"
          : index > 0
          ? data[index - 1].avg_debit > data[index].avg_debit
            ? "red"
            : "green"
          : "green",

      color_median_debit:
        data[index].median_debit === 0
          ? "gray"
          : index > 0
          ? data[index - 1].median_debit > data[index].median_debit
            ? "red"
            : "green"
          : "green",

      color_max_debit:
        data[index].max_debit === 0
          ? "gray"
          : index > 0
          ? data[index - 1].max_debit > data[index].max_debit
            ? "red"
            : "green"
          : "green",
    }));

  return (
    <>
      <div className="flex justify-between items-center">
        {data && (
          <div className="px-3">
            <p className="text-base my-1">
              Notification: We're aware of the issue with months showing out of
              order. This will be corrected in the next few days. For now,
              please Export to CSV.
            </p>
          </div>
        )}

        <div className="items-center flex p-2">
          <CSVLink
            style={{
              backgroundColor: "#5B45E0",
              color: "#fff",
              borderRadius: 5,
              padding: 6,
            }}
            data={data || []}
            filename={`${statement?.data?.account_name}.csv`}
          >
            Export to CSV
          </CSVLink>
        </div>
      </div>

      <Table
        dataSource={cleanData(data)}
        size="small"
        bordered
        loading={data === undefined && !data}
        style={{ borderColor: "black" }}
      >
        <Column title="Month" dataIndex="month" key="month" />
        <ColumnGroup title="Inflows">
          <Column
            title="Balance"
            dataIndex="balance"
            key="balance"
            render={(text, record) => (
              <span style={{ color: record.color_balance }}>
                {currency(text)}
              </span>
            )}
          />
          <Column
            title="Total Credit"
            dataIndex="total_credit"
            key="total_credit"
            render={(text, record) => (
              <span style={{ color: record.color_total_credit }}>
                {currency(text)}
              </span>
            )}
          />
        </ColumnGroup>

        <ColumnGroup title="Outflows">
          <Column
            title="Total Debit"
            dataIndex="total_debit"
            key="total_debit"
            render={(text, record) => (
              <span style={{ color: record.color_total_debit }}>
                {currency(text)}
              </span>
            )}
          />
          <Column
            title="Avg. Debit"
            dataIndex="avg_debit"
            key="avg_debit"
            render={(text, record) => (
              <span style={{ color: record.color_avg_debit }}>
                {currency(text)}
              </span>
            )}
          />
        </ColumnGroup>
      </Table>
    </>
  );
}

export default SingleAnalyserStatement;
