import { createContext, useContext, useState } from "react";
import moment from "moment";

const TableContext = createContext();

function TableProvider({ children }) {
  const [searchDate, setSearchDate] = useState({
    end: moment().endOf("month"),
    start: moment().max("2020-01-20T20:00:00+0800"),
  });
  const [page, setPage] = useState(1);
  const [requestTableSorter, setRequestTableSorters] = useState({
    adminStatus: null,
    lender: null,
    lenderStatus: null,
    type: null,
    name: [],
    userStatus: null,
  });
  const [requestTableNameSearchText, setRequestTableNameSearchText] =
    useState("");
  const [requestTableNameSearchedColumn, setRequestTableNameSearchedColumn] =
    useState("");

  const [requestTableLenderSearchText, setRequestTableLenderSearchText] =
    useState("");
  const [
    requestTableLenderSearchedColumn,
    setRequestTableLenderSearchedColumn,
  ] = useState("");

  const value = {
    searchDate,
    page,
    setPage,
    setSearchDate,
    requestTableSorter,
    setRequestTableSorters,
    requestTableNameSearchText,
    setRequestTableNameSearchText,
    requestTableNameSearchedColumn,
    setRequestTableNameSearchedColumn,
    requestTableLenderSearchedColumn,
    setRequestTableLenderSearchedColumn,
    requestTableLenderSearchText,
    setRequestTableLenderSearchText,
  };

  return (
    <TableContext.Provider value={value}>{children}</TableContext.Provider>
  );
}

const useTable = () => {
  const context = useContext(TableContext);

  if (context === undefined) {
    throw new Error("useTable must be used within an TableProvider");
  }

  return context;
};

export { TableProvider, useTable };
