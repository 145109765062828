import React, { useState } from "react";
import {
  Modal,
  Button,
  Col,
  Row,
  Input,
  Form,
  notification,
  message,
} from "antd";
import { useAuth } from "../../context/AuthContext";
import { StopOutlined } from "@ant-design/icons";
import decision from "../../assets/images/decision.png";
import networkRequest from "../../utlis/Request";
import { trigger } from "swr";
const DeclineModal = ({ id, username, loan_request }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const { user } = useAuth();
  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleDisApprove = async (values) => {
    setLoading(true);
    const approval = await networkRequest.POST(
      `/loan_request/${id}/decline`,
      user?.data?.token,
      {
        declined_comment: values.declined_comment,
      }
    );

    if (approval.status !== "success") {
      setLoading(false);
      notification.error({ message: approval.message });
      return;
    }
    setLoading(false);
    message.success(approval.message);
    trigger(`/loan_request/${id}`);
    setIsModalVisible(false);
  };

  return (
    <>
      <Button
        type="default"
        className="text-gray-500"
        icon={<StopOutlined />}
        onClick={showModal }
      >
        Decline
      </Button>
      <Modal footer={null} visible={isModalVisible} onCancel={handleCancel}>
        <div>
          <div className=" w-full flex justify-center">
            <img
              alt="success"
              src={decision}
              className="object-center justify-self-auto mt-10 w-20"
            />
          </div>

          <p className="text-lg my-5 text-center">
            Your are about to decline {username || ""} loan request.
          </p>
          <div>
            <Form
              onFinish={handleDisApprove}
              layout="vertical"
              initialValues={{ remember: true }}
            >
              <Row gutter={[2, 2]}>
                <Col xs={24} md={24} lg={24}>
                  <Form.Item
                    label="Awks. We thought that was a good lead. Please let the borrower know what they did wrong."
                    name="declined_comment"
                    rules={[
                      {
                        required: true,
                        message: "reason is required!",
                      },
                    ]}
                  >
                    <Input.TextArea />
                  </Form.Item>
                </Col>
              </Row>

              <Form.Item>
                <div className="p-2 w-full flex justify-end">
                  <Button
                    htmlType="submit"
                    loading={loading}
                    size="large"
                    type="danger"
                  >
                    Decline
                  </Button>
                </div>
              </Form.Item>
            </Form>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default DeclineModal;
