import React from "react";
import { Menu, Dropdown, Divider, Typography } from "antd";
import { DownOutlined } from "@ant-design/icons";
const { Paragraph } = Typography;

const Overview = ({ app }) => {
  const menu = (
    <Menu>
      <Menu.Item>Last 24 Hours</Menu.Item>
      <Menu.Item>Last 7 days</Menu.Item>
      <Menu.Item>Last Week</Menu.Item>
      <Menu.Item>Last Month</Menu.Item>
    </Menu>
  );

  return (
    <div>
      <div className="flex justify-between mt-2">
        <h4 className="font-bold text-lg">API Calls Overview</h4>
        <Dropdown overlay={menu} className="mx-3">
          <span className="ant-dropdown-link text-gray-500 font-bold">
            Last 12 Hours <DownOutlined className="mx-2" />
          </span>
        </Dropdown>
      </div>
      <div className="flex justify-between mt-8">
        <div>
          <h2 className="font-bold text-xl">{app?.total_successful_calls}</h2>
          <h4 className="text-gray-500">Successful Calls</h4>
        </div>
        <div>
          <h2 className="font-bold text-xl">{app?.total_failed_calls}</h2>
          <h4 className="text-gray-500">Failed Calls</h4>
        </div>
        <div className="mt-5">
          <h2 className="text-gray-500">--</h2>
        </div>
        <div className="mt-5">
          <h2 className="text-gray-500">--</h2>
        </div>
      </div>
      <div className="pl-4 mb-5">
        <Divider />
        <div className="stats position-relative my-10">
          <p className="font-extrabold text-base text-gray-500">APP Name</p>
          <Paragraph>{app?.name}</Paragraph>
        </div>

        <div className="stats position-relative my-10">
          <p className="font-extrabold text-base text-gray-500">APP Live Key</p>
          <Paragraph copyable>{app?.live_key}</Paragraph>
        </div>
      </div>
    </div>
  );
};

export default Overview;
