import React from "react";
import { Divider, Row, Col } from "antd";
import { FolderFilled } from "@ant-design/icons";

function VerificationDetails({
  bvnDetails,
  bankDetails,
  userDetails,
  loan_request,
}) {
  return (
    <div className="mt-4">
      <Row gutter={[10, 10]}>
        <Col xs={24} md={8}>
          <div className="flex flex-col justify-between  h-10 ">
            <h3 className="text-gray-500">Full name (from application)</h3>
            <h3>{loan_request?.fullname}</h3>
          </div>
          <Divider />
        </Col>

        <Col xs={24} md={8}>
          <div className="flex flex-col justify-between  h-10 ">
            <h3 className="text-gray-500">Bank account name</h3>
            <h3>{bankDetails?.account_name}</h3>
          </div>
          <Divider />
        </Col>

        <Col xs={24} md={8}>
          <div className="flex flex-col justify-between  h-10 ">
            <h3 className="text-gray-500">Name on BVN</h3>
            <h3>
              {bvnDetails?.first_name}
              {bvnDetails?.last_name}
            </h3>
          </div>
          <Divider />
        </Col>

        <Col xs={24} md={8}>
          <div className="flex flex-col justify-between  h-10 ">
            <h3 className="text-gray-500"> Date of Birth </h3>
            <h3 className="text-black">{bvnDetails?.formatted_dob}</h3>
          </div>
          <Divider />
        </Col>

        <Col xs={24} md={8}>
          <div className="flex flex-col justify-between  h-10 ">
            <h3 className="text-gray-500"> Bank Verification Number </h3>
            <h3 className="text-black">{bvnDetails?.bvn}</h3>
          </div>
          <Divider />
        </Col>

        <Col xs={24} md={8}>
          <div className="flex flex-col justify-between  h-10 ">
            <h3 className="text-gray-500">BVN Match Bank</h3>

            <h3
              className="text-black w-40 px-3"
              style={{
                color: "#fff",
                background: userDetails?.bvn_verified ? "green" : "red",
              }}
            >
              {userDetails?.bvn_verified ? "YES" : "NO"}
            </h3>
          </div>
          <Divider />
        </Col>

        {loan_request?.cac_certificate && (
          <Col xs={24} md={8}>
            <div className="flex flex-col justify-between  h-10 ">
              <h3 className="text-gray-500">
                {loan_request?.cac_certificate[0]?.type}
              </h3>
              <a
                target="_blank"
                rel="noreferrer"
                href={loan_request?.cac_certificate[0]?.source}
                className="text-black text-sm font-bold"
              >
                <FolderFilled />
                {loan_request?.cac_certificate[0]?.type}
              </a>
            </div>
            <Divider />
          </Col>
        )}

        {loan_request?.business_permit_licence && (
          <Col xs={24} md={8}>
            <div className="flex flex-col justify-between  h-10 ">
              <h3 className="text-gray-500">
                {loan_request?.business_permit_licence[0]?.type}
              </h3>
              <a
                target="_blank"
                rel="noreferrer"
                href={loan_request?.business_permit_licence[0]?.source}
                className="text-black text-sm font-bold"
              >
                <FolderFilled />
                {loan_request?.business_permit_licence[0]?.type}
              </a>
            </div>
            <Divider />
          </Col>
        )}

        {loan_request?.goverment_ids && (
          <Col xs={24} md={8}>
            <div className="flex flex-col justify-between  h-10 ">
              <h3 className="text-gray-500">Guarantor's ID Card</h3>
              <a
                target="_blank"
                rel="noreferrer"
                href={loan_request?.goverment_ids[0]?.source}
                className="text-black text-sm font-bold"
              >
                <FolderFilled />
                Guarantor's ID Card
              </a>
            </div>
            <Divider />
          </Col>
        )}

        {loan_request?.bank_statements && (
          <Col xs={24} md={8}>
            <div className="flex flex-col justify-between  h-10 ">
              <h3 className="text-gray-500">
                {loan_request?.bank_statements[0]?.type}
              </h3>
              <a
                target="_blank"
                rel="noreferrer"
                href={loan_request?.bank_statements[0]?.source}
                className="text-black text-sm font-bold"
              >
                <FolderFilled />
                {loan_request?.bank_statements[0]?.type}
              </a>
            </div>
            <Divider />
          </Col>
        )}
      </Row>
    </div>
  );
}

export default VerificationDetails;
