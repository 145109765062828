import React from "react";
import { Card, Tabs } from "antd";
import PersonalDetails from "./details/PersonalDetails";
import { Divider } from "antd";
import VerificationDetails from "./details/Verification";
import BankDetails from "./details/BankDetails";
import EmploymentDetails from "./details/EmploymentDetails";
import DecisionButton from "../button/decisionButton";
import BankStatement from "../Bankstatement";
import SMEDetails from "./details/SME";
import Activities from "./details/Activities";

const { TabPane } = Tabs;

function callback(key) {
  return key;
}

function Information({
  bvnDetail,
  bankDetail,
  verification,
  userDetail,
  loan_request,
}) {
  // const bank_statement = verification?.bank_statements || verification?.bank_statements[0];
  return (
    <div className="mt-2">
      <Card loading={!userDetail?.firstname}>
        <Tabs defaultActiveKey="1" onChange={callback}>
          <TabPane tab="Personal Details" key="1">
            <PersonalDetails userDetails={userDetail} userInfo={verification} loan_request={loan_request} />
          </TabPane>
          {loan_request?.loan_type === "personal_loan" && loan_request?.product_id !== "ckl6lnutkau5a09023mo3irar" &&  (
            <TabPane tab="Employment Details" key="2">
              <EmploymentDetails employmentDetails={userDetail} loan_request={loan_request} />
            </TabPane>
          )}
          {loan_request?.loan_type === "sme_loan" && (
            <TabPane tab="Business information" key="3">
              <SMEDetails id={loan_request?.id} />
            </TabPane>
          )}

          <TabPane tab="Bank Details" key="4">
            <BankDetails bankDetails={bankDetail} loan_request={loan_request} />
          </TabPane>
          <TabPane tab="Verification & Documents" key="5">
            <VerificationDetails
              loan_request={loan_request}
              bankDetails={bankDetail}
              bvnDetails={bvnDetail}
              documents={verification}
              userDetails={userDetail}
            />
          </TabPane>

          <TabPane tab="Bank Statements" key="6">
            <BankStatement id={userDetail?.id} userDetails={userDetail} loan_request={loan_request}/>
          </TabPane>
          <TabPane tab="Activities" key="7">
            <Activities loan_request={loan_request} />
          </TabPane>
        </Tabs>
      </Card>
      <Divider />
      <DecisionButton approved={verification} loan_request={loan_request} />
    </div>
  );
}

export default Information;
