import React from "react";
import { VictoryChart, VictoryBar, VictoryAxis, VictoryTooltip } from "victory";

function BarChart() {
  return (
    <VictoryChart domainPadding={20} width={600} height={800}>
      <VictoryAxis
        dependentAxis
        tickFormat={(y) => `${y}`}
        style={{
          axis: { stroke: "none" },
          ticks: { stroke: "transparent" },
          tickLabels: { fill: "transparent" },
        }}
      />
      <VictoryAxis
        tickFormat={(x) => `${x}`}
        style={{ axis: { stroke: "none" }, fontSize: 32 }}
      />
      <VictoryBar
        cornerRadius={{
          top: 10,
          bottom: 10,
        }}
        style={{
          data: { fill: "#1fd286", width: 30 },
        }}
        data={[
          { x: 12, y: 0 },
          { x: 13, y: 0 },
          { x: 14, y: 0 },
          { x: 15, y: 0 },
          { x: 16, y: 0 },
          { x: 17, y: 0 },
          { x: 18, y: 1 },
        ]}
        labelComponent={
          <VictoryTooltip
            flyoutWidth={95}
            flyoutHeight={35}
            cornerRadius={5}
            pointerLength={40}
            flyoutStyle={{
              stroke: "#868C97",
              strokeWidth: 3,
              fill: "#333752",
            }}
            style={{
              fill: "#fff",
              fontSize: 20,
              fontWeight: 500,
              textAnchor: "middle",
            }}
          />
        }
      />
    </VictoryChart>
  );
}

export default BarChart;
