import React from "react";

function List(props) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 15v2H4v-2h2zm13 0a1 1 0 110 2H9a1 1 0 110-2h10zM6 11v2H4v-2h2zm13 0a1 1 0 110 2H9a1 1 0 110-2h10zM6 7v2H4V7h2zm13 0a1 1 0 110 2H9a1 1 0 010-2h10z"
        fill={props.match ? "#fff" : "#7E84A3"}
      />
    </svg>
  );
}

export default List;
