import React from "react";
import { Card, Avatar } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { currency } from "../../utlis/currencyFormater";
import { formatNumber } from "../../utlis/numberFormter";

function Summary({ loan_request, userDetail }) {
  return (
    <div>
      <Card className="mt-5" loading={!loan_request}>
        <div className="flex space-x-12">
          <div className="mt-5">
            {" "}
            <Avatar size={130} icon={<UserOutlined />} />
          </div>
          <div className="flex space-x-64 mt-5">
            <div className="space-y-3">
              <h2 className="font-bold text-lg">{loan_request?.fullname}</h2>
              <h3 className="text-gray-500">
                Amount Requested:
                <span className="text-black ml-7">
                  {currency(loan_request?.amount || 0)}
                </span>
              </h3>
              <h3 className="text-gray-500">
                Product Name:{" "}
                <span className="text-black ml-14 uppercase">
                  {loan_request?.product_name}
                </span>
              </h3>
              <h3 className="text-gray-500">
                Loan Type:{" "}
                <span className="text-black ml-20 uppercase">
                  {loan_request?.loan_type.split("_").join(" ")}
                </span>
              </h3>
            </div>
            <div className="mt-12 space-y-4">
              <h3 className="text-gray-500">
                Loan Status:
                <span className="ml-7 uppercase">
                  {loan_request?.lender_status}
                </span>
              </h3>
              <h3 className="text-gray-500">
                Expected Tenure:
                <span className="ml-7 uppercase">
                  {formatNumber(
                    loan_request?.expected_tenure
                      ? loan_request?.expected_tenure
                      : userDetail?.expected_tenure || 0
                  )}{" "}
                  {loan_request?.product_id === "ckl6lnutkau5a09023mo3irar"  ? "Days" : "Months"}
                </span>
              </h3>
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
}

export default Summary;
