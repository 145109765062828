import React from "react";
import { Drawer, Card, Divider, Avatar, Row, Col } from "antd";
import { UserOutlined } from "@ant-design/icons";
import LoanDisbursed from "../request/LoanDisbursedModal";
import Percent from "../../assets/customIcons/percent";
import { banks } from "../../utlis/listOfBanks";
import { currency } from "../../utlis/currencyFormater";

function DisburseDrawer({
  handleCancel,
  isDrawerVisible,
  loan_request,
  bankDetails,
}) {
  const rate = Number(loan_request?.terms?.rate) / 100;
  const rateofamount = rate * Number(loan_request?.terms?.amount);
  const repay = Number(loan_request?.terms?.amount) + rateofamount;
  const bankName = banks.find((bank) => bank.code === bankDetails?.bank_code);

  return (
    <div>
      <Drawer
        width={720}
        onClose={handleCancel}
        visible={isDrawerVisible}
        bodyStyle={{ paddingBottom: 80 }}
        footer={
          <div
            style={{
              textAlign: "right",
            }}
          ></div>
        }
      >
        <div>
          <h2 className="font-bold text-lg"> Disburse Loans</h2>
          <div className="mt-5">
            <Avatar icon={<UserOutlined />} />
            <span className="ml-5 text-green-500">
              Approved by :{loan_request?.approved_by || ""}
            </span>
          </div>
          <p className="mt-5 w-3/5 text-gray-500">
            {`You are about to disburse a loan to “${
              loan_request?.fullname || ""
            }”. Review the
            details of the loan below:`}
          </p>
          <Divider />
          <Card>
            <div className="flex justify-between mt-7">
              <div>
                <h3 className="text-gray-500">Loan amount</h3>
                <h2 className="text-green-500 font-bold text-2xl">
                  {currency(loan_request?.terms?.amount)}
                </h2>
              </div>
              <div>
                <Percent />
              </div>
            </div>
            <div className="flex justify-between mt-5">
              <div>
                <h3 className="text-gray-500">
                  Total Payment(
                  {`${loan_request?.terms?.rate}% of principal `}) + principal
                </h3>
                <h2 className="font-bold text-2xl">{currency(repay)}</h2>
              </div>

              <div>
                <h2 className="text-lg capitalize text-gray-500">
                  Repayment Period
                </h2>
                <h2 className="font-bold">
                  {loan_request?.terms?.tenure} Months
                </h2>
              </div>
            </div>
            <Divider />

            <div className="flex justify-between mt-5">
              <div>
                <h3 className="text-gray-500">
                  Monthly Payment(Total repayment / Tenure)
                </h3>
                <h2 className="font-bold text-2xl">
                  {currency(repay / loan_request?.terms?.tenure)}
                </h2>
              </div>
            </div>
            <Divider />
          </Card>

          <Card>
            <div className="mt-4">
              <Row gutter={[10, 10]}>
                <Col xs={24} md={8}>
                  <div className="flex flex-col justify-between  h-10 ">
                    <h3 className="text-gray-500"> Account Number </h3>
                    <h3>
                      {loan_request?.product_id === "ckl6lnutkau5a09023mo3irar"
                        ? "*****"
                        : bankDetails?.account_number}
                    </h3>
                  </div>
                  <Divider />
                </Col>

                <Col xs={24} md={8}>
                  <div className="flex flex-col justify-between  h-10 ">
                    <h3 className="text-gray-500"> Bank Name </h3>
                    <h3 className="text-black">{bankName?.name}</h3>
                  </div>
                  <Divider />
                </Col>

                <Col xs={24} md={8}>
                  <div className="flex flex-col justify-between  h-10 ">
                    <h3 className="text-gray-500"> Account Name </h3>
                    <h3 className="text-black">
                      {loan_request?.product_id === "ckl6lnutkau5a09023mo3irar"
                        ? "*****"
                        : bankDetails?.account_name}
                    </h3>
                  </div>
                  <Divider />
                </Col>
              </Row>
            </div>
          </Card>

          <LoanDisbursed
            id={loan_request?.id}
            loan_request={loan_request}
            closeDrawer={handleCancel}
          />
        </div>
      </Drawer>
    </div>
  );
}

export default DisburseDrawer;
