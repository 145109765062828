import React from "react";
import { Card, Tabs } from "antd";
import Repayments from "./details/Repayments";

const { TabPane } = Tabs;

function callback(key) {
  return key;
}

function Information({ loanAnalytics, user }) {
  return (
    <div className="mt-2">
      <Card>
        <Tabs defaultActiveKey="1" onChange={callback}>
          <TabPane tab="Repayments" key="1">
            <Repayments loanAnalytics={loanAnalytics} user={user} />
          </TabPane>
        </Tabs>
      </Card>
    </div>
  );
}

export default Information;
