import React from "react";
import useSWR from "swr";
import networkRequest from "../utlis/Request";
import { useAuth } from "../context/AuthContext";
import { useParams } from "react-router-dom";
import { message } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import SuccessPage from "../components/disbursement/SuccessPage";
import { useHistory } from "react-router-dom";

function SingleDisbursement(props) {
  const { user } = useAuth();
  const { id } = useParams();

  const { data: disbursement, error } = useSWR(`/disbursement/${id}`, (url) =>
    networkRequest.GET(url, user?.data?.token)
  );

  if (error) {
    message.loading("retrying...");
  }
  const history = useHistory();
  return (
    <div>
      <div className="flex justify-between mt-2">
        <span
          className="text-gray-500 cursor-pointer"
          onClick={() => history.goBack()}
        >
          <span>
            <ArrowLeftOutlined />
          </span>
          <span> back</span>
        </span>
        {/* <div className="flex justify-between">
          <Button type="default" style={{color: '#5B45E0'}} icon={<SettingOutlined />}>
            Manage
          </Button>
        </div> */}
      </div>
      <SuccessPage
        disbursement={disbursement?.data}
        summary={disbursement?.data?.flw_trans_res?.data}
      />
    </div>
  );
}

export default SingleDisbursement;
