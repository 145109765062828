import React from "react";
import { Table, Tooltip } from "antd";
import { currency } from "../../utlis/currencyFormater";

const { Column, ColumnGroup } = Table;

function PremiumTable({ data, statement }) {
  const cleanData = (data) =>
    data?.map((res, index) =>
      res?.type
        ? {
            type: res?.type,
            inflowCountByChannel:
              res?.type === "pos" || res?.type === "atm"
                ? "N/A"
                : res?.credit_count.value,
            inflowCountByChannelMsg: res?.credit_count.message,
            percentTransCountChannel:
              res?.type === "pos" || res?.type === "atm"
                ? "N/A"
                : `${res?.percentage_of_total_credit?.value?.toFixed(2) || 0}%`,
            percentTransCountChannelMsg:
              res?.percentage_of_total_credit.message,
            totalNGNInflow:
              res?.type === "pos" || res?.type === "atm"
                ? "N/A"
                : currency(res?.credit_ngn?.value),
            totalNGNInflowMsg: res?.credit_ngn?.message,

            outflowCountByChannel: res?.debit_count.value,
            outflowCountByChannelMsg: res?.debit_count.message,
            outpercentTransCountChannel: res?.percentage_of_total_debit.value,
            outpercentTransCountChannelMsg:
              res?.percentage_of_total_debit.message,
            outtotalNGNInflow: res?.debit_ngn?.value,
            outtotalNGNInflowMsg: res?.debit_ngn?.message,
          }
        : null
    );

  return (
    <>
      <p className="py-1 px-2">Transaction Channel Breakdown</p>
      <Table
        dataSource={cleanData(data)?.filter(Boolean)}
        loading={data === undefined && !data}
        size="small"
        bordered
        // loading={data === undefined && !data}
        style={{ borderColor: "black" }}
      >
        <Column
          title=""
          dataIndex="type"
          key="type"
          width={100}
          render={(text, record) => (
            <span className="uppercase text-purple-800 font-bold">{text}</span>
          )}
        />
        <ColumnGroup title="Inflows">
          <Column
            title="Total Inflow Count by channel"
            dataIndex="inflowCountByChannel"
            key="inflowCountByChannel"
            render={(text, record) => (
              <Tooltip title={record?.inflowCountByChannelMsg || ""}>
                <span>{text}</span>
              </Tooltip>
            )}
          />

          <Column
            title="% of Total Inflow Transaction Count by channel"
            dataIndex="percentTransCountChannel"
            key="percentTransCountChannel"
            render={(text, record) => (
              <Tooltip title={record?.percentTransCountChannelMsg || ""}>
                <span>{`${text || 0}`}</span>
              </Tooltip>
            )}
          />
          <Column
            title="Total Inflow NGN value by channel"
            dataIndex="totalNGNInflow"
            key="totalNGNInflow"
            render={(text, record) => (
              <Tooltip title={record?.totalNGNInflowMsg || ""}>
                {" "}
                <span>{text}</span>{" "}
              </Tooltip>
            )}
          />
        </ColumnGroup>

        <ColumnGroup title="Outflows">
          <Column
            title="Total Outflow Count by channel"
            dataIndex="outflowCountByChannel"
            key="outflowCountByChannel"
            render={(text, record) => (
              <Tooltip title={record?.outflowCountByChannelMsg || ""}>
                {" "}
                <span>{text || 0}</span>{" "}
              </Tooltip>
            )}
          />

          <Column
            title="% of Total Outflow Transaction Count by channel"
            dataIndex="outpercentTransCountChannel"
            key="outpercentTransCountChannel"
            render={(text, record) => (
              <Tooltip title={record?.outpercentTransCountChannelMsg || ""}>
                {" "}
                <span>{`${text?.toFixed(2) || 0}%`}</span>{" "}
              </Tooltip>
            )}
          />
          <Column
            title="Total Outflow NGN value by channel"
            dataIndex="outtotalNGNInflow"
            key="outtotalNGNInflow"
            render={(text, record) => (
              <Tooltip title={record?.outtotalNGNInflowMsg || ""}>
                {" "}
                <span>{currency(text)}</span>{" "}
              </Tooltip>
            )}
          />
        </ColumnGroup>
      </Table>
    </>
  );
}

export default PremiumTable;
