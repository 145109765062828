import React from "react"

function Folder(props) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.5 2L12 5h8a2 2 0 012 2v12a2 2 0 01-2 2H4a2 2 0 01-2-2V4a2 2 0 012-2h5.5zm1.563 5l-2.5-3H4v15h16V7h-8.937z"
        fill={props.match ? "#fff" : "#7E84A3"}
      />
    </svg>
  )
}

export default Folder;
