import { createContext, useContext, useState } from "react";
const AppContext = createContext();

function AppProvider({ children }) {
  const [app, setApp] = useState(null);

  const value = {
    app,
    setApp,
  };

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
}

const useApp = () => {
  const context = useContext(AppContext);

  if (context === undefined) {
    throw new Error("useApp must be used within an AppProvider");
  }

  return context;
};

export { AppProvider, useApp };
