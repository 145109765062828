import React from "react";
import moment from "moment";
import { Table } from "antd";
import { currency } from "../../utlis/currencyFormater";
function BankStatementTable({ data, loading, loan_request }) {
  const cleanData = (data) =>
    data?.map((res, index) => ({
      key: res.id,
      credit: res.type === "credit" ? currency(res.amount / 100) : "0.00",
      debit: res.type === "debit" ? currency(res.amount / 100) : "0.00",
      narration: loan_request?.product_id === "ckl6lnutkau5a09023mo3irar"  ? "**********" : res.narration,
      date: moment(res.date).format("YYYY-MM-DD"),
    }));

  const columns = [
    {
      title: "Narration",
      width: 300,
      dataIndex: "narration",
      key: "1",
      fixed: "left",
    },
    {
      title: "Date",
      width: 150,
      dataIndex: "date",
      key: "date",
      sorter: (a, b) => new Date(a.date).valueOf() - new Date(b.date).valueOf(),
      render: (text) => moment(text).format("YYYY-MM-DD"),
    },

    {
      title: "Credit",
      width: 150,
      dataIndex: "credit",
      key: "2",
      onFilter: (value, record) => record.type.indexOf(value) === 0,
    },

    {
      title: "Debit",
      width: 150,
      dataIndex: "debit",
      key: "2",
      onFilter: (value, record) => record.type.indexOf(value) === 0,
    },
  ];

  return (
    <Table
      loading={loading === undefined && !loading}
      columns={columns}
      dataSource={cleanData(data)}
    />
  );
}

export default BankStatementTable;
