import React from "react";
import useSWR from "swr";
import networkRequest from "../utlis/Request";
import { useAuth } from "../context/AuthContext";
import { Card, message } from "antd";

import DisbursementTable from "../components/Table/Disbursement";

import DisbursementAnalytics from "../components/disbursement/DisbursementAnalytics";

function Disbursement(props) {
  const { user } = useAuth();
  const { data: disbursements, error } = useSWR("/disbursements", (url) =>
    networkRequest.GET(url, user?.data?.token)
  );

  const { data: analytics, analyticsError } = useSWR(
    "/disbursements/analytics",
    (url) => networkRequest.GET(url, user?.data?.token)
  );

  const { data: charts, chartError } = useSWR("/disbursements/graph", (url) =>
    networkRequest.GET(url, user?.data?.token)
  );

  if (error || analyticsError || chartError) {
    message.loading("retrying...");
  }
  if (false) {
    console.log(charts);
  }

  return (
    <div>
      <div className="flex justify-between mb-7">
        <h2 className="font-bold text-xl">Disbursements</h2>
        <div className="button space-x-4">
          {/* <Button
            type="default"
            style={{ color: "#5B45E0" }}
            icon={<SettingOutlined />}
          >
            Manage
          </Button> */}
        </div>
      </div>
      <DisbursementAnalytics analytics={analytics?.data} />
      <div className="mt-10">
        {/* <Card>
          <div className="flex justify-between mt-2">
            <h4 className="font-bold text-lg">Disbursement Overview</h4>
            <Dropdown overlay={menu} className="mx-3">
              <span className="ant-dropdown-link text-gray-500 font-bold">
                Last 12 Hours <DownOutlined className="mx-2" />
              </span>
            </Dropdown>
          </div>
          <DisbursementChart chart={charts?.data} />
        </Card> */}
      </div>
      <div className="my-10">
        <Card>
          <h2 className="text-lg text-black font-bold">Top Disbursements</h2>
          <DisbursementTable
            data={disbursements?.data}
            showChargenowBtn={user?.data?.type === "micro"}
          />
        </Card>
      </div>
    </div>
  );
}

export default Disbursement;
