import React from "react";
import moment from "moment";
import useSWR from "swr";
import { useAuth } from "../context/AuthContext";
import networkRequest from "../utlis/Request";
import { useTable } from "../context/TableContext";
import { Card, DatePicker, message } from "antd";
import LoanOverviewTable from "../components/Table/LoanOverview";
import LoanAnalytics from "../components/loan/LoanAnalytics";

const { RangePicker } = DatePicker;

function Loans(props) {
  const { searchDate, setSearchDate } = useTable();
  const { user } = useAuth();
  const dateFormat = "YYYY-MM-DD";

  const { data: requests, error } = useSWR(
    `/loans?query=${searchDate.start}&${searchDate.end}`,
    (url) =>
      networkRequest.POST(url, user?.data?.token, {
        start: moment(searchDate.start).format("YYYY-MM-DD"),
        end: moment(searchDate.end).format("YYYY-MM-DD"),
      })
  );

  const { data: analytics, error: analyticsError } = useSWR(
    "/loans/analytics",
    (url) => networkRequest.GET(url, user?.data?.token)
  );

  if (analyticsError || error) {
    message.loading("retrying...");
  }

  return (
    <div className="mt-2">
      <div className="flex justify-between mb-5">
        <h2 className="font-bold text-lg">Loan Overview</h2>
        <div className="button space-x-4">
          {/* <Button
            type="default"
            style={{ color: "#5B45E0" }}
            icon={<SettingOutlined />}
          >
            Manage
          </Button> */}
        </div>
      </div>
      <LoanAnalytics analytics={analytics?.data} />
      <div className="my-10">
        <Card>
          <div className="flex justify-end mb-5">
            <RangePicker
              onChange={(value) =>
                setSearchDate({ start: value[0], end: value[1] })
              }
              defaultValue={[searchDate.end, searchDate.start]}
              format={dateFormat}
            />
          </div>
          <LoanOverviewTable
            data={requests?.data}
            showChargenowBtn={user?.data?.type === "micro"}
          />
        </Card>
      </div>
    </div>
  );
}

export default Loans;
