import React from "react";
import useSWR from "swr";
import networkRequest from "../../utlis/Request";
import { useAuth } from "../../context/AuthContext";
import { ReactComponent as Cart } from "../../assets/svg/cart.svg";
import { ReactComponent as User } from "../../assets/svg/user.svg";
import { ReactComponent as Users } from "../../assets/svg/users.svg";
import { Row, Card, Col, message } from "antd";
import { formatNumber } from "../../utlis/numberFormter";

function RequestAnalytics(props) {
  const { user } = useAuth();
  const { data: analytics, error } = useSWR("/analytics", (url) =>
    networkRequest.GET(url, user?.data?.token)
  );
  if (error) {
    message.loading(error);
  }
  return (
    <div>
      <Row>
        <Col xs={24} md={6} lg={6}>
          <Card bodyStyle={{ height: "40%" }}>
            <div className="flex justify-between">
              <div>
                <h1 className="font-bold text-xl">
                  {formatNumber(analytics?.data?.total_requests?.count || 0)}
                </h1>
                <p className="text-grey my-3">All Requests</p>
              </div>

              <Users />
            </div>
          </Card>
        </Col>
        <Col xs={24} md={6} lg={6}>
          <Card bodyStyle={{ height: "40%" }}>
            <div className="flex justify-between">
              <div>
                <h1 className="font-bold text-xl">
                  {formatNumber(analytics?.data?.approved_applications || 0)}
                </h1>
                <p className="text-grey my-3">Approved</p>
              </div>

              <Cart />
            </div>
          </Card>
        </Col>
        <Col xs={24} md={6} lg={6}>
          <Card bodyStyle={{ height: "40%" }}>
            <div className="flex justify-between">
              <div>
                <h1 className="font-bold text-xl">
                  {formatNumber(analytics?.data?.declined_applications || 0)}
                </h1>
                <p className="text-grey my-3">Declined</p>
              </div>

              <User />
            </div>
          </Card>
        </Col>
        <Col xs={24} md={6} lg={6}>
          <Card bodyStyle={{ height: "40%" }}>
            <div className="flex justify-between">
              <div>
                <h1 className="font-bold text-xl">
                  {formatNumber(analytics?.data?.inprogress || 0)}
                </h1>
                <p className="text-grey my-3">Pending</p>
              </div>

              <Users />
            </div>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default RequestAnalytics;
