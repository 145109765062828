import React from "react";
import { Card, Row, Col, Divider } from "antd";
import { UpOutlined, DownOutlined } from '@ant-design/icons';
import { formatNumber } from "../utlis/numberFormter";
import CustomersTable from "../components/Table/Customers";

function Customers(props) {
  return (
    <section>
      <div className="flex justify-between">
        <Card className="w-full">
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="grid grid-flow-col grid-rows-1 grid-cols-4">
            <Col className="gutter-row" span={4}>
              <div>
                <h3 className="text-xs text-gray-500">Total customers</h3>
                <h2 className="font-bold text-lg">{formatNumber(394923999)}</h2>
                <h3 className="text-green-500 mt-5 flex">22.5%<UpOutlined className="ml-2 mt-1" /></h3>
              </div>
            </Col>
            <Col className="gutter-row" span={4}>
              <div className="flex">
                <div><Divider type="vertical" style={{height: "100px", width: "50px"}} /></div>
                <div>
                  <h3 className="text-xs text-gray-500 break-normal">New Customer (Today)</h3>
                  <h2 className="text-lg font-bold">{formatNumber(1958)}</h2>
                  <h3 className="text-green-500 mt-5 flex">15.34%<UpOutlined className="ml-2 mt-1" /></h3>
                </div>
              </div>
            </Col>
            <Col className="gutter-row" span={4}>
              <div className="flex">
                <div><Divider type="vertical" style={{height: "100px", width: "50px"}} /></div>
                <div>
                  <h3 className="text-xs text-gray-500">Total Active Customers</h3>
                  <h2 className="text-lg font-bold">{formatNumber(359948)}</h2>
                  <h3 className="text-red-600 mt-5 flex">18.25%<DownOutlined className="ml-2 mt-1" /></h3>
                </div>
              </div>
            </Col>
            <Col className="gutter-row" span={4}>
              <div className="flex">
                <div><Divider type="vertical" style={{height: "100px", width: "50px"}} /></div>
                <div>
                  <h3 className="text-xs text-gray-500">Inactive Customers</h3>
                  <h2 className="text-lg font-bold">{formatNumber(9985)}</h2>
                  <h3 className="text-red-600 mt-5 flex">10.24%<DownOutlined className="ml-2 mt-1" /></h3>
                </div>
              </div>
            </Col>
          </Row>
        </Card>
      </div>

      <div className="my-10">
        <Card>
          <CustomersTable />
        </Card>
      </div>
    </section>
  );
}

export default Customers;
