import React from "react";
import { Button, Card, Divider } from "antd";
import { currency } from "../../utlis/currencyFormater";

function SuccessPage({ summary, disbursement }) {
  console.log(disbursement)
  return (
    <div className="mt-5">
      {disbursement?.manually_disbursed === false && (
        <Card style={{ width: " 634px" }} loading={!summary}>
        <h2 className="font-bold text-lg"> Disbursement Details</h2>
        <p className="text-gray-500 mt-2">Review the transaction details</p>
        <p className="text-center text-lg font-bold mt-5 -mb-6">
          {summary?.narration}
        </p>
        <Divider />
        <div className="flex justify-between">
          <h2 className="text-lg font-bold capitalize">summary</h2>
          <Button
            type="default"
            className="uppercase"
            style={{ backgroundColor: "#c4f8e2", color: "#06a561" }}
          >
            success
          </Button>
        </div>
        {/* <div className="flex justify-between mt-5">
          <div className="flex">
            <div><Avatar shape="square" size="large" icon={<UserOutlined />} /></div>
            <div className="ml-5">
              <h2 className="font-bold">{summary?.full_name}</h2>
              <h4 className="text-gray-500">{summary?.account_number} - {summary?.bank_name}</h4>
            </div>
            <div className="ml-48">
              <h2 className="text-gray-500 font-bold">{moment(summary?.created_at).format("dddd, MMMM Do YYYY, h:mm a")}</h2>
            </div>
          </div>
        </div> */}
        <Divider />
        <div>
          <div className="flex justify-between mt-5">
            <h2 className="text-gray-500">Transaction ID</h2>
            <h4 className="text-gray-500 font-bold">{summary?.id}</h4>
          </div>
          <div className="flex justify-between mt-5">
            <h2 className="text-gray-500">Loan Reference</h2>
            <div>
              <h4 className="text-gray-500 font-bold">{disbursement?.loan_ref}</h4>
              {/* <a href="#loan" className="text-gray-500 place-self-end">
                View Loans <SelectOutlined rotate="90" />
              </a> */}
            </div>
          </div>
        </div>
        <Divider />
        <div className="flex justify-between mt-5 mb-3">
          <h2 className="font-bold">Total Amount Disbursed</h2>
          <h4 className="font-bold text-blue-700 text-lg">
            {currency(summary?.amount)}
          </h4>
        </div>
      </Card>
      )
      }
      {disbursement?.manually_disbursed === true && (
        <Card style={{ width: " 634px" }} loading={!disbursement}>
        <h2 className="font-bold text-lg"> Disbursement Details</h2>
        <p className="text-gray-500 mt-2">Review the transaction details</p>
        <p className="text-center text-lg font-bold mt-5 -mb-6">
          {summary?.narration}
        </p>
        <Divider />
        <div className="flex justify-between">
          <h2 className="text-lg font-bold capitalize">summary</h2>
          <Button
            type="default"
            className="uppercase"
            style={{ backgroundColor: "#c4f8e2", color: "#06a561" }}
          >
            success
          </Button>
        </div>
        <Divider />
        <div>
          <div className="flex justify-between mt-5">
            <h2 className="text-gray-500">Transaction ID</h2>
            <h4 className="text-gray-500 font-bold">{disbursement?.id}</h4>
          </div>
          <div className="flex justify-between mt-5">
            <h2 className="text-gray-500">Loan Reference</h2>
            <div>
              <h4 className="text-gray-500 font-bold">{disbursement?.loan_ref}</h4>
            </div>
          </div>
          <div className="flex justify-between mt-5 mb-3">
            <h2 className="text-gray-500">Disbursement Type</h2>
            <h4 className="font-bold text-gray-500 text-lg">
              Manually DIsbursed
            </h4>
        </div>
        </div>
        <Divider />
        <div className="flex justify-between mt-5 mb-3">
          <h2 className="font-bold">Total Amount Disbursed</h2>
          <h4 className="font-bold text-blue-700 text-lg">
            {currency(disbursement?.amount)}
          </h4>
        </div>
        <div className="flex justify-between mt-5 mb-3">
          <h2 className="font-bold">Note</h2>
          <h4 className="font-bold text-blue-700 text-lg">
            {disbursement?.manual_disbursement_note}
          </h4>
        </div>
      </Card>
      )}
    </div>
  );
}

export default SuccessPage;
