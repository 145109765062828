import { Avatar, Menu, Dropdown } from "antd";
import { DownOutlined } from "@ant-design/icons";
import React from "react";
import Logo from "../../assets/images/logo.png";
import { useAuth } from "../../context/AuthContext";

function Header(props) {
  const { user, handleLogout } = useAuth();

  const logout = () => {
    handleLogout();
    window.location.replace("/sign-in");
  };

  const menus = (
    <Menu>
      <Menu.Item onClick={logout}>Logout</Menu.Item>
      <Menu.Item>Settings</Menu.Item>
    </Menu>
  );
  return (
    <div className="bg-white h-16 shadow-inner p-3 px-10 w-full flex justify-between">
      <div className="flex-auto  w-full flex  items-center">
        <img alt="evolve logo" src={Logo} />
      </div>
      <div className="flex-auto  w-full flex justify-end  items-center">
        <div className="md:block hidden">
          <Avatar>
            {" "}
            {user?.data?.firstname[0]} {user?.data?.lastname[0]}
          </Avatar>
        </div>
        <Dropdown overlay={menus} className="md:mx-3 mx-1">
          <span className="ant-dropdown-link text-gray-500">
            {user?.data?.firstname} {user?.data?.lastname}{" "}
            <DownOutlined className="md:mx-2 mx-1" />
          </span>
        </Dropdown>
      </div>
    </div>
  );
}

export default Header;
