import React, { useState } from "react";
import { Row, Col, Form, Button, Input, notification, message } from "antd";
import { useHistory } from "react-router-dom";
import Logo from "../assets/images/logo.png";
import Pattern from "../assets/images/patterns.png";
import networkRequest from "../utlis/Request";
import Auth from "../utlis/Auth";
import { useAuth } from "../context/AuthContext";

function SignUp(props) {
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const { setISloggedIn } = useAuth();

  const handleSubmit = async (values) => {
    setLoading(true);
    const register = await networkRequest.POST_WITHOUT_AUTH3("v3/lender", {
      org_user: {
        firstname: String(values.fname),
        lastname: String(values.lname),
        email: String(values.email),
        password: String(values.password),
        phone: String(values.phone),
      },
      org: {
        name: `${values.orgname}`,
        disburse_with_ec: "no",
        bs_analyzer:true,
        lead_generation:false
      },
    });

    if (register.status !== "success") {
      setLoading(false);
      notification.error({ message: register.message });
      return;
    }
    setLoading(false);
    const login = await Auth.Login({
      email: values.email,
      password: values.password,
    });

    if (login.status) {
      setLoading(false);

      setISloggedIn(true);
      message.success(login.message);
      window.location.replace("/");
      return;
    }
    setLoading(false);

    notification.error({ message: login.message });
  };

  return (
    <div className="h-screen flex lg:flex-row flex-col">
      <div className="flex-grow  lg:p-10 p-5 lg:mx-32">
        <img src={Logo} alt="evolve credit logo" />

        <div className="my-20">
          <h1 className="text-2xl font-semibold my-5">
            Sign up to Evolve Credit
          </h1>
          <Form
            onFinish={handleSubmit}
            layout="vertical"
            initialValues={{ remember: true }}
          >
            <Row gutter={[2, 2]}>
              <Col xs={24} md={24} lg={24}>
                <Form.Item
                  label="First Name"
                  name="fname"
                  rules={[
                    {
                      required: true,
                      message: "First Name is required!",
                    },
                  ]}
                >
                  <Input
                    size="large"
                    type="text"
                    className="contact-form_input"
                  />
                </Form.Item>
              </Col>
              <Col xs={24} md={24} lg={24}>
                <Form.Item
                  label="Last Name"
                  name="lname"
                  rules={[
                    {
                      required: true,
                      message: "Last Name is required!",
                    },
                  ]}
                >
                  <Input
                    size="large"
                    type="text"
                    className="contact-form_input"
                  />
                </Form.Item>
              </Col>

              <Col xs={24} md={24} lg={24}>
                <Form.Item
                  label="Business name"
                  name="orgname"
                  rules={[
                    {
                      required: true,
                      message: "Business name is required!",
                    },
                  ]}
                >
                  <Input
                    size="large"
                    type="text"
                    className="contact-form_input"
                  />
                </Form.Item>
              </Col>

              <Col xs={24} md={24} lg={24}>
                <Form.Item
                  label="Email Address"
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: "Email address is required!",
                    },
                  ]}
                >
                  <Input
                    placeholder="you@example.com"
                    size="large"
                    type="email"
                    className="contact-form_input"
                  />
                </Form.Item>
              </Col>
              <Col xs={24} md={24} lg={24}>
                <Form.Item
                  label="Phone Number"
                  name="phone"
                  rules={[
                    {
                      required: true,
                      message: "Phone Number is required!",
                    },
                  ]}
                >
                  <Input
                    size="large"
                    type="number"
                    className="contact-form_input"
                  />
                </Form.Item>
              </Col>
              <Col xs={24} md={24} lg={24}>
                <Form.Item
                  name="password"
                  label="Password"
                  rules={[
                    {
                      required: true,
                      message: "Please input your password!",
                    },
                  ]}
                >
                  <Input.Password size="large" className="contact-form_input" />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item>
              <Button
                style={{ width: "100%" }}
                loading={loading}
                type="primary"
                size="large"
                htmlType="submit"
                className="contact-form_submitbutton"
              >
                Sign Up
              </Button>
            </Form.Item>
            <div className="flex w-full justify-center">
              <Button type="link" onClick={() => history.push("/sign-in")}>
                Already Registered?
              </Button>
            </div>
          </Form>
        </div>
      </div>
      <div
        className="flex-shrink-0 w-2/5 h-full bg-green-400 relative hidden lg:block"
        style={{ paddingBottom: "50%" }}
      >
        <img
          src={Pattern}
          alt="pattern"
          className="absolute h-full w-full object-cover"
        />
      </div>
    </div>
  );
}

export default SignUp;
