import * as React from "react"

function GreenSwipe(props) {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect opacity={0.1} width={40} height={40} rx={20} fill="#00AF7B" />
      <mask
        id="prefix__a"
        maskUnits="userSpaceOnUse"
        x={12}
        y={14}
        width={16}
        height={12}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.825 19.167L12.5 22.5l3.325 3.333v-2.5h5.842v-1.666h-5.842v-2.5zM27.5 17.5l-3.325-3.333v2.5h-5.842v1.666h5.842v2.5L27.5 17.5z"
          fill="#fff"
        />
      </mask>
      <g mask="url(#prefix__a)">
        <path fill="#00AF7B" d="M-.833-.833h41.667v41.667H-.833z" />
        <mask
          id="prefix__b"
          maskUnits="userSpaceOnUse"
          x={-1}
          y={-1}
          width={42}
          height={42}
        >
          <path fill="#fff" d="M-.833-.833h41.667v41.667H-.833z" />
        </mask>
      </g>
    </svg>
  )
}

export default GreenSwipe;
