import React from "react";
import { Row, Col, Tooltip, Card } from "antd";
import { ReactComponent as Chart1 } from "../../assets/svg/chart.svg";
import { ReactComponent as Chart2 } from "../../assets/svg/chart2.svg";
import { ReactComponent as Chart3 } from "../../assets/svg/chart3.svg";
import { formatNumber } from "../../utlis/numberFormter";
import { currency } from "../../utlis/currencyFormater";

function LoanAnalytics({ analytics }) {
  return (
    <div>
      <Row gutter={[16, 16]} className="flex justify-between mt-5">
        <Col className="gutter-row" xs={24} md={12} lg={8}>
          <Card className="h-full w-full">
            <Row>
              <Col xs={24} md={14}>
                <Tooltip title={analytics?.total_loans_count?.msg}>
                  <h3 className="text-gray-500 text-xs">Total Loans</h3>

                  <h3 className="font-bold text-lg">
                    {formatNumber(analytics?.total_loans_count?.value)}
                  </h3>
                </Tooltip>

                <Tooltip title={analytics?.total_loans_value?.msg}>
                  <h3 className="text-gray-500 text-xs my-5">
                    Total Loans Value
                  </h3>

                  <h3 className="font-bold text-lg">
                    {currency(analytics?.total_loans_value?.value)}
                  </h3>
                </Tooltip>
              </Col>

              <Col xs={24} md={10}>
                <div className="w-full h-full flex justify-end items-end">
                  <Chart2 />
                </div>
              </Col>
            </Row>
          </Card>
        </Col>

        <Col className="gutter-row" xs={24} md={12} lg={8}>
          <Card>
            <Row>
              <Col xs={24} md={14}>
                <Tooltip title={analytics?.total_active_count?.msg}>
                  <h3 className="text-gray-500 text-xs">Active Loans</h3>

                  <h3 className="font-bold text-lg">
                    {formatNumber(analytics?.total_active_count?.value)}
                  </h3>
                </Tooltip>

                <Tooltip title={analytics?.total_active_value?.msg}>
                  <h3 className="text-gray-500 text-xs my-5">
                    Active Loans Value
                  </h3>

                  <h3 className="font-bold text-lg">
                    {currency(analytics?.total_active_value?.value)}
                  </h3>
                </Tooltip>
              </Col>

              <Col xs={24} md={10}>
                <div className="w-full h-full flex justify-end items-end">
                  <Chart3 />
                </div>
              </Col>
            </Row>
          </Card>
        </Col>

        <Col className="gutter-row" xs={24} md={12} lg={8}>
          <Card>
            <Row>
              <Col xs={24} md={14}>
                <Tooltip title={analytics?.total_repaid_count?.msg}>
                  <h3 className="text-gray-500 text-xs">Repaid Loans</h3>

                  <h3 className="font-bold text-lg">
                    {formatNumber(analytics?.total_repaid_count?.value)}
                  </h3>
                </Tooltip>

                <Tooltip title={analytics?.total_repaid_value?.msg}>
                  <h3 className="text-gray-500 text-xs my-5">
                    Repaid Loans Value
                  </h3>

                  <h3 className="font-bold text-lg">
                    {currency(analytics?.total_repaid_value?.value)}
                  </h3>
                </Tooltip>
              </Col>

              <Col xs={24} md={10}>
                <div className="w-full h-full flex justify-end items-end">
                  <Chart1 />
                </div>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default LoanAnalytics;
