import React from "react";
import { Card, Avatar } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { currency } from "../../utlis/currencyFormater";

function Summary({ loan }) {
  return (
    <div>
      <Card className="mt-5">
        <div className="flex space-x-12">
          <div className="mt-5">
            {" "}
            <Avatar size={130} icon={<UserOutlined />} />
          </div>
          <div className="flex space-x-64 mt-5">
            <div className="space-y-3">
              <h2 className="font-bold text-lg">
                {` ${loan?.borrower?.firstname || " "} ${
                  loan?.borrower?.lastname || " "
                } `}
              </h2>
              <h3 className="text-gray-500">
                Amount Requested:
                <span className="text-black ml-7">
                  {currency(loan?.amount || 0)}
                </span>
              </h3>
              <h3 className="text-gray-500">
                Product Name:{" "}
                <span className="text-black ml-14">{loan?.product_name}</span>
              </h3>
              <h3 className="text-gray-500">
                Loan Type:{" "}
                <span className="text-black ml-20 uppercase">
                  {loan?.loan_type}
                </span>
              </h3>
            </div>
            <div className="mt-12 space-y-4">
              <h3 className="text-gray-500">
                Status:
                <span className="ml-7">
                  {loan?.active ? "Active" : "Not Active"}
                </span>
              </h3>
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
}

export default Summary;
