import React from "react";
import { ReactComponent as User } from "../../assets/svg/user.svg";
import { ReactComponent as Users } from "../../assets/svg/users.svg";
import { Row, Card, Col } from "antd";
import { formatNumber } from "../../utlis/numberFormter";
import { currency } from "../../utlis/currencyFormater";
import Topup from "../disbursement/Topup";

function APIAnalytics({ app, api_key }) {
  return (
    <div>
      <Row gutter={[10, 10]}>
        <Col xs={24} md={8} lg={8}>
          <Card bodyStyle={{ height: "40%" }}>
            <div className="flex justify-between">
              <div>
                <h1 className="font-bold text-xl">
                  {formatNumber(app?.total_calls || 0)}
                </h1>
                <p className="text-grey my-3">Total API calls</p>
              </div>

              <Users />
            </div>
          </Card>
        </Col>

        <Col xs={24} md={8} lg={8}>
          <Card bodyStyle={{ height: "40%" }}>
            <div className="flex justify-between">
              <div>
                <h1 className="font-bold text-xl">
                  {formatNumber(app?.total_successful_calls || 0)}
                </h1>
                <p className="text-grey my-3">Total Bank Statements Analysed</p>
              </div>

              <User />
            </div>
          </Card>
        </Col>

        <Col xs={24} md={8} lg={8}>
          <Card
            bodyStyle={{ height: "40%" }}
            style={{ backgroundColor: "#5b45e0", borderRadius: 9 }}
          >
            <div className="flex justify-end my-3">
              <Topup api_key={api_key} />
            </div>
            <div className="flex justify-between">
              <div>
                <h1 className="font-bold text-xl text-white">
                  {currency(app?.ngn_balance)}
                </h1>
                <p className="text-white my-3">NGN Balance</p>
              </div>
            </div>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default APIAnalytics;
