import React, { useState } from "react";
import moment from "moment";
import { banks } from "../API/Uploader";
import { Table, Button, Modal, notification } from "antd";
import { useHistory } from "react-router-dom";
import { CSVLink } from "react-csv";
import networkRequest from "../../utlis/Request";
import { useTable } from "../../context/TableContext";
import { currency } from "../../utlis/currencyFormater";
import { useAuth } from "../../context/AuthContext";

function AllStatement({ data }) {
  const history = useHistory();
  const { app_key } = useAuth();
  const [loading, setLoading] = useState(false);
  const [bankStatement, setBankStatement] = useState({});

  const [openModal, setOpenModal] = useState(false);
  const { setPage, page } = useTable();
  const findBank = (code) => banks.find((bank) => bank.id === code);

  const getStatement = async (id, name) => {
    setLoading(false);
    const statement = await networkRequest.GET_V2(
      `/bs-analyzer/statement/${id}?fmt=pretty&&key=${app_key}`,
      app_key
    );

    if (statement.status !== "success") {
      setLoading(false);
      notification.error({ message: statement.message });
      return;
    }

    setBankStatement({ data: statement?.data?.prettyfied, name: name });
    setLoading(false);
    setOpenModal(true);
  };

  const columns = [
    {
      title: "Account Name",
      dataIndex: "account_name",
      key: "account_name",
    },
    {
      title: "Account Number",
      dataIndex: "account_number",
      key: "account_number",
    },

    {
      title: "Bank Code",
      dataIndex: "bank_code",
      key: "bank_code",
    },

    {
      title: "Total Credit",
      dataIndex: "total_sum_of_credit",
      key: "total_sum_of_credit",
    },

    {
      title: "Total Debit",
      dataIndex: "total_sum_of_debit",
      key: "total_sum_of_debit",
    },

    {
      title: "Date",
      dataIndex: "created_at",
      key: "created_at",
      sorter: (a, b) =>
        new Date(a.created_at).valueOf() - new Date(b.created_at).valueOf(),
    },

    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (text, record) => (
        <div>
          <Button
            type="primary"
            onClick={() => {
              history.push(`/analyser/${record.key}`);
            }}
          >
            Details
          </Button>

          <Button
            onClick={() => getStatement(record.key, record.account_name)}
            type="link"
            loading={loading}
          >
            Export to CSV
          </Button>
        </div>
      ),
    },
  ];

  const cleanData = (data) =>
    data?.map((res, index) => ({
      key: res.id,
      account_name: res.account_name,
      account_number: res.account_number,
      total_sum_of_debit: currency(res.total_sum_of_debit),
      total_sum_of_credit: currency(res.total_sum_of_credit),

      bank_code: findBank(res.bank_code)?.name,
      created_at: moment(res.created_at).format("YYYY-MM-DD"),
    }));

  return (
    <>
      <Table
        loading={data === undefined && !data}
        columns={columns}
        dataSource={cleanData(data)}
        pagination={{
          position: ["bottomLeft"],
          current: page,
          onChange: (e) => setPage(e),
        }}
      />
      <Modal
        visible={openModal}
        footer={null}
        onCancel={() => setOpenModal(false)}
      >
        <div className="my-5 flex flex-col justify-center">
          <p className="text-center text-base">CSV file is ready to download</p>
          <CSVLink
            data={bankStatement.data}
            filename={`${bankStatement.name}.csv`}
          >
            Download now
          </CSVLink>
        </div>
      </Modal>
    </>
  );
}

export default AllStatement;
