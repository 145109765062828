import SignIn from "../pages/SignIn";
import EmailReset from "../pages/EmailReset";
import ResetPassword from "../pages/ResetPassword";
import { Redirect } from "react-router-dom";
import SignUp from "../pages/SignUp";
export const authRoutes = [
  {
    path: "/sign-up",
    exact: true,
    sidebar: () => <div>Sign Up</div>,
    main: () => <SignUp />,
  },
  {
    path: "/bsa",
    exact: true,
    sidebar: () => <div>Sign Up</div>,
    main: () => <SignUp />,
  },

  {
    path: "/",
    exact: true,
    sidebar: () => <div>Login</div>,
    main: () => <Redirect to="/sign-in" />,
  },
  {
    path: "/sign-in",
    exact: true,
    sidebar: () => <div>Login</div>,
    main: () => <SignIn />,
  },

  {
    path: "/email-reset",
    sidebar: () => <div>email reset</div>,
    main: () => <EmailReset />,
  },

  {
    path: "/reset-password",
    sidebar: () => <div>Reset</div>,
    main: () => <ResetPassword />,
  },
];
