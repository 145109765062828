import React from "react";
import { Card, Row, Col, Tooltip } from "antd";
import useSWR from "swr";
import networkRequest from "../../utlis/Request";
import { useAuth } from "../../context/AuthContext";
import { currency } from "../../utlis/currencyFormater";

function Affordability({ id }) {
  const { user } = useAuth();

  const {
    data: affordability,
    error: affordableError,
  } = useSWR(`/loan_request/tp/${id}/affordability`, (url) =>
    networkRequest.GET(url, user?.data?.token)
  );

  const { data: webaffordability, error: webaffordableError } = useSWR(
    `/loan_request/tp/${id}/affordability/webform
  `,
    (url) => networkRequest.GET(url, user?.data?.token)
  );

  if (affordableError || webaffordableError) {
    console.log(`----------Error------Data----------`);
  }
  return (
    <div className="mt-2">
      <Row gutter={[5, 5]}>
        <Col xs={24} md={12} lg={12}>
          <Card loading={!affordability}>
            <h2 className="text-center text-lg font-bold my-3">
              Data From Bank Statement
            </h2>
            <div className="w-full flex justify-between border-0 border-b border-solid py-1">
              <Tooltip title={affordability?.data?.average_revenue?.msg}>
                <p className="text-gray-500">Average Revenue</p>
              </Tooltip>
              <p
                style={{
                  color:
                    affordability?.data?.average_revenue?.value > 1
                      ? "black"
                      : "red",
                }}
              >
                {currency(affordability?.data?.average_revenue?.value)}
              </p>
            </div>

            <div className="w-full flex justify-between border-0 border-b border-solid py-1">
              <Tooltip
                title={affordability?.data?.adjusted_average_income?.msg}
              >
                <p className="text-gray-500">Adjusted Average Income</p>
              </Tooltip>
              <p
                style={{
                  color:
                    affordability?.data?.adjusted_average_income?.value > 1
                      ? "black"
                      : "red",
                }}
              >
                {currency(affordability?.data?.adjusted_average_income?.value)}
              </p>
            </div>

            <div className="w-full flex justify-between border-0 border-b border-solid py-1">
              <Tooltip title={affordability?.data?.net_income?.msg}>
                <p className="text-gray-500">Net Income</p>
              </Tooltip>
              <p
                style={{
                  color:
                    affordability?.data?.net_income?.value > 1
                      ? "black"
                      : "red",
                }}
              >
                {currency(affordability?.data?.net_income?.value)}
              </p>
            </div>

            <div className="w-full flex justify-between border-0 border-b border-solid py-1">
              <Tooltip title={affordability?.data?.payment_capacity?.msg}>
                <p className="text-gray-500">Payment Capacity</p>
              </Tooltip>
              <p
                style={{
                  color:
                    affordability?.data?.payment_capacity?.value > 1
                      ? "black"
                      : "red",
                }}
              >
                {currency(affordability?.data?.payment_capacity?.value)}
              </p>
            </div>

            <div className="w-full flex justify-between border-0 border-b border-solid py-1">
              <Tooltip title={affordability?.data?.total_revenue?.msg}>
                <p className="text-gray-500">Total Credits</p>
              </Tooltip>
              <p
                style={{
                  color:
                    affordability?.data?.total_revenue?.value > 1
                      ? "black"
                      : "red",
                }}
              >
                {currency(affordability?.data?.total_revenue?.value)}
              </p>
            </div>

            <div className="w-full flex justify-between border-0 border-b border-solid py-1">
              <Tooltip title={affordability?.data?.total_debits?.msg}>
                <p className="text-gray-500">Total Debits</p>
              </Tooltip>
              <p
                style={{
                  color:
                    affordability?.data?.total_debits?.value > 1
                      ? "black"
                      : "red",
                }}
              >
                {currency(affordability?.data?.total_debits?.value)}
              </p>
            </div>

            <div className="w-full flex justify-between border-0 border-b border-solid py-1">
              <Tooltip title={affordability?.data?.total_expenses?.msg}>
                <p className="text-gray-500">Total Expenses</p>
              </Tooltip>
              <p
                style={{
                  color:
                    affordability?.data?.total_expenses.value > 1
                      ? "black"
                      : "red",
                }}
              >
                {currency(affordability?.data?.total_expenses?.value)}
              </p>
            </div>

            <div className="w-full flex justify-between border-0 border-b border-solid py-1">
              <Tooltip title={affordability?.data?.total_liability?.msg}>
                <p className="text-gray-500">Total Liability</p>
              </Tooltip>
              <p
                style={{
                  color:
                    affordability?.data?.total_liability?.value > 1
                      ? "black"
                      : "red",
                }}
              >
                {currency(affordability?.data?.total_liability?.value)}
              </p>
            </div>

            <div className="w-full flex justify-between border-0 border-b border-solid py-1">
              <Tooltip title={affordability?.data?.total_revenue?.msg}>
                <p className="text-gray-500">Total Revenue</p>
              </Tooltip>
              <p
                style={{
                  color:
                    affordability?.data?.total_revenue?.value > 1
                      ? "black"
                      : "red",
                }}
              >
                {currency(affordability?.data?.total_revenue?.value)}
              </p>
            </div>
          </Card>
        </Col>

        <Col xs={24} md={12} lg={12}>
          <Card loading={!webaffordability}>
            <h2 className="text-center text-lg font-bold my-3">
              Data From Web Form
            </h2>

            <div className="w-full flex justify-between border-0 border-b border-solid py-1">
              <Tooltip title={webaffordability?.data?.average_revenue?.msg}>
                <p className="text-gray-500">Average Revenue</p>
              </Tooltip>
              <p
                style={{
                  color:
                    webaffordability?.data?.average_revenue?.value > 1
                      ? "black"
                      : "red",
                }}
              >
                {currency(webaffordability?.data?.average_revenue?.value)}
              </p>
            </div>

            <div className="w-full flex justify-between border-0 border-b border-solid py-1">
              <Tooltip
                title={webaffordability?.data?.adjusted_average_income?.msg}
              >
                <p className="text-gray-500">Adjusted Average Income</p>
              </Tooltip>
              <p
                style={{
                  color:
                    webaffordability?.data?.adjusted_average_income?.value > 1
                      ? "black"
                      : "red",
                }}
              >
                {currency(
                  webaffordability?.data?.adjusted_average_income?.value
                )}
              </p>
            </div>

            <div className="w-full flex justify-between border-0 border-b border-solid py-1">
              <Tooltip title={webaffordability?.data?.net_income?.msg}>
                <p className="text-gray-500">Net Income</p>
              </Tooltip>
              <p
                style={{
                  color:
                    webaffordability?.data?.net_income?.value > 1
                      ? "black"
                      : "red",
                }}
              >
                {currency(webaffordability?.data?.net_income?.value)}
              </p>
            </div>

            <div className="w-full flex justify-between border-0 border-b border-solid py-1">
              <Tooltip title={webaffordability?.data?.payment_capacity?.msg}>
                <p className="text-gray-500">Payment Capacity</p>
              </Tooltip>
              <p
                style={{
                  color:
                    webaffordability?.data?.payment_capacity?.value > 1
                      ? "black"
                      : "red",
                }}
              >
                {currency(webaffordability?.data?.payment_capacity?.value)}
              </p>
            </div>

            <div className="w-full flex justify-between border-0 border-b border-solid py-1">
              <Tooltip title={webaffordability?.data?.total_revenue?.msg}>
                <p className="text-gray-500">Total Credits</p>
              </Tooltip>
              <p
                style={{
                  color:
                    webaffordability?.data?.total_revenue?.value > 1
                      ? "black"
                      : "red",
                }}
              >
                {currency(webaffordability?.data?.total_revenue?.value)}
              </p>
            </div>

            <div className="w-full flex justify-between border-0 border-b border-solid py-1">
              <Tooltip title={webaffordability?.data?.total_debits?.msg}>
                <p className="text-gray-500">Total Debits</p>
              </Tooltip>
              <p
                style={{
                  color:
                    webaffordability?.data?.total_debits?.value > 1
                      ? "black"
                      : "red",
                }}
              >
                {currency(webaffordability?.data?.total_debits?.value)}
              </p>
            </div>

            <div className="w-full flex justify-between border-0 border-b border-solid py-1">
              <Tooltip title={webaffordability?.data?.total_expenses?.msg}>
                <p className="text-gray-500">Total Expenses</p>
              </Tooltip>
              <p
                style={{
                  color:
                    webaffordability?.data?.total_expenses.value > 1
                      ? "black"
                      : "red",
                }}
              >
                {currency(webaffordability?.data?.total_expenses?.value)}
              </p>
            </div>

            <div className="w-full flex justify-between border-0 border-b border-solid py-1">
              <Tooltip title={webaffordability?.data?.total_liability?.msg}>
                <p className="text-gray-500">Total Liability</p>
              </Tooltip>
              <p
                style={{
                  color:
                    webaffordability?.data?.total_liability?.value > 1
                      ? "black"
                      : "red",
                }}
              >
                {currency(webaffordability?.data?.total_liability?.value)}
              </p>
            </div>

            <div className="w-full flex justify-between border-0 border-b border-solid py-1">
              <Tooltip title={webaffordability?.data?.total_revenue?.msg}>
                <p className="text-gray-500">Total Revenue</p>
              </Tooltip>
              <p
                style={{
                  color:
                    webaffordability?.data?.total_revenue?.value > 1
                      ? "black"
                      : "red",
                }}
              >
                {currency(webaffordability?.data?.total_revenue?.value)}
              </p>
            </div>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default Affordability;
