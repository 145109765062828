import React from "react";
import moment from "moment";
import { Table, Button } from "antd";
import { useHistory } from "react-router-dom";
import { useTable } from "../../context/TableContext";
import { currency } from "../../utlis/currencyFormater";

function DisbursementTable({ data, showChargenowBtn }) {
  const history = useHistory();
  const { setPage, page } = useTable();

  const columns = [
    {
      title: "Loan Reference",
      dataIndex: "reference",
      key: "reference",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
    },

    {
      title: "Date",
      dataIndex: "date",
      key: "date",
    },

    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },

    {
      title: "FullName",
      dataIndex: "fullname",
      key: "fullname",
    },

    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (text, record) => (
        <Button
          onClick={() => {
            history.push(`/disbursements/${record.key}`);
          }}
        >
          View
        </Button>
      ),
    },
  ];

  const cleanData = (data) =>
    data?.map((res, index) => ({
      key: res.id,
      sn: index + 1,
      reference: res?.loan_ref,
      amount: res?.manually_disbursed === false ? currency(res?.flw_trans_res?.data?.amount):
      currency(res?.amount),
      fullname: showChargenowBtn
        ? res?.flw_trans_res?.data?.full_name.split(" ")[0] || ""
        : res?.manually_disbursed === false ? res?.flw_trans_res?.data?.full_name || ""
        : res.receipient,
        
      date: moment(res.created_at).format("YYYY-MM-DD"),
      status: res.status,
    }));

  return (
    <Table
      loading={data === undefined && !data}
      columns={columns}
      dataSource={cleanData(data)}
      pagination={{
        position: ["bottomLeft"],
        current: page,
        onChange: (e) => setPage(e),
      }}
      scroll={{ x: 1400, y: 1500 }}
    />
  );
}

export default DisbursementTable;
