import { message, Row, Card, Col } from "antd";
import React from "react";
import useSWR from "swr";
import { useAuth } from "../../context/AuthContext";
import networkRequest from "../../utlis/Request";
import { currency } from "../../utlis/currencyFormater";
import BankStatementTable from "../Table/BankStatementTable";

function BankStatement({ id, userDetails, loan_request }) {
  const { user } = useAuth();

  const { data: statement, error } = useSWR(
    `/user/${id}/okra_transactions`,
    (url) => networkRequest.GET(url, user?.data?.token)
  );

  const { data: monoInfo, monoError } = useSWR(
    `/user/${id}/mono_account`,
    (url) => networkRequest.GET(url, user?.data?.token)
  );

  if (error || monoError) {
    message.error(error?.message);
  }

  return (
    <div>
      {userDetails?.mono_connected && (
        <Card loading={!monoInfo} className="mt-5">
          <Row gutter={[10, 10]}>
            <Col xs={24} md={12}>
              <div className="flex flex-col justify-between h-10">
                <h2 className="text-lg text-gray-500 capitalize">
                  Bank Name:
                  <span className="text-black ml-2 uppercase">
                    {monoInfo?.data?.account?.institution?.name}
                  </span>
                </h2>
              </div>
            </Col>
            <Col xs={24} md={12}>
              <div className="flex flex-col justify-between h-10">
                <h2 className="text-lg text-gray-500">
                  Account Name:
                  <span className="text-black ml-2 capitalize">
                    {loan_request?.product_id === "ckl6lnutkau5a09023mo3irar"
                      ? "*****"
                      : monoInfo?.data?.account?.name}
                  </span>
                </h2>
              </div>
            </Col>
            <Col xs={24} md={12}>
              <div className="flex flex-col justify-between h-10 ">
                <h2 className="text-lg text-gray-500">
                  Account Number:
                  <span className="text-black ml-2">
                    {loan_request?.product_id === "ckl6lnutkau5a09023mo3irar"
                      ? "*****"
                      : monoInfo?.data?.account?.accountNumber}
                  </span>
                </h2>
              </div>
            </Col>
            <Col xs={24} md={12}>
              <div className="flex flex-col justify-between h-10 ">
                <h2 className="text-lg text-gray-500">
                  Account Balance:
                  <span className="text-black ml-2 capitalize">
                    {currency(monoInfo?.data?.account?.balance / 100)}
                  </span>
                </h2>
              </div>
            </Col>
            <Col xs={24} md={12}>
              <div className="flex flex-col justify-between h-10 ">
                <h2 className="text-lg text-gray-500">
                  Account Type:
                  <span className="text-black ml-2 capitalize">
                    {monoInfo?.data?.account?.institution?.type}
                  </span>
                </h2>
              </div>
            </Col>
          </Row>
        </Card>
      )}
      <div>
        {userDetails?.mono_connected && (
          <BankStatementTable
            data={statement?.data?.statements[1].data?.mono_statements?.data}
            loading={statement}
            loan_request={loan_request}
          />
        )}

        {userDetails?.okra_connected && (
          <BankStatementTable
            data={statement?.data?.statements[0].data?.okra_statements?.data}
            loading={statement}
            loan_request={loan_request}
          />
        )}

        {statement?.data?.statements[2]?.data && (
          <BankStatementTable
            data={statement?.data?.statements[2].data?.mbs?.data}
            loading={statement}
            loan_request={loan_request}
          />
        )}
      </div>
    </div>
  );
}

export default BankStatement;
