import React from "react";
import { Card, Timeline, Empty } from "antd";
import moment from "moment";

function Activities({ loan_request }) {
  const infos = loan_request?.activities || [];
  if (!loan_request?.activities && !loan_request?.viewed_by) {
    return <Empty />;
  }

  const info = infos?.map((value, index) => (
    <p key={index}>
      {value?.who} {value?.action} on{" "}
      {moment(value?.time).format("dddd, MMMM Do YYYY")}
    </p>
  ));
  return (
    <div>
      <Card>
        <Timeline>
          <Timeline.Item>{info}</Timeline.Item>
        </Timeline>
      </Card>
    </div>
  );
}

export default Activities;
