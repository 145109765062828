import React, { useState } from "react";
import { Row, Col, Form, Button, Input, notification, message } from "antd";
import { useHistory } from "react-router-dom";
import Logo from "../assets/images/logo.png";
import Pattern from "../assets/images/patterns.png";
import Auth from "../utlis/Auth";
import { useAuth } from "../context/AuthContext";

function SignIn(props) {
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const { setISloggedIn } = useAuth();

  const handleLogin = async (values) => {
    setLoading(true);
    const login = await Auth.Login({
      email: values.email,
      password: values.password,
    });

    if (login.status) {
      setLoading(false);

      // setISlogged triggers the get user fucntion in the authcontext
      setISloggedIn(true);
      message.success(login.message);

      window.location.replace("/");

      return;
    }
    setLoading(false);

    notification.error({ message: login.message });
  };

  return (
    <div className="h-screen flex lg:flex-row flex-col">
      <div className="flex-grow lg:p-10 p-5 lg:mx-32">
        <img src={Logo} alt="evolve credit logo" />

        <div className="my-20">
          <h1 className="text-2xl font-semibold my-5">
            Sign in to Evolve Credit Dashboard
          </h1>
          <Form
            onFinish={handleLogin}
            layout="vertical"
            initialValues={{ remember: true }}
          >
            <Row gutter={[2, 2]}>
              <Col xs={24} md={24} lg={24}>
                <Form.Item
                  label="Email Address"
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: "Email address is required!",
                    },
                  ]}
                >
                  <Input
                    placeholder="you@example.com"
                    size="large"
                    type="email"
                    className="contact-form_input"
                  />
                </Form.Item>
              </Col>
              <Col xs={24} md={24} lg={24}>
                <Form.Item
                  name="password"
                  label="Password"
                  rules={[
                    {
                      required: true,
                      message: "Please input your password!",
                    },
                  ]}
                >
                  <Input.Password size="large" className="contact-form_input" />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item>
              <Button
                style={{ width: "100%" }}
                loading={loading}
                type="primary"
                size="large"
                htmlType="submit"
                className="contact-form_submitbutton"
              >
                Sign in
              </Button>
            </Form.Item>
            <div className="flex w-full justify-center">
              <Button type="link" onClick={() => history.push("/email-reset")}>
                Forgot password?
              </Button>
              <Button type="link" onClick={() => history.push("/sign-up")}>
                Don't have an account?
              </Button>
            </div>
          </Form>
        </div>
      </div>
      <div
        className="flex-shrink-0 w-2/5 h-full bg-green-400 relative hidden lg:block"
        style={{ paddingBottom: "50%" }}
      >
        <img
          src={Pattern}
          alt="pattern"
          className="absolute h-full w-full object-cover"
        />
      </div>
    </div>
  );
}

export default SignIn;
