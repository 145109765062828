import React from "react";
import { banks } from "../../../utlis/listOfBanks";
import { Divider, Row, Col } from "antd";

function BankDetails({ bankDetails, loan_request }) {
  const bankName = banks.find((bank) => bank.code === bankDetails?.bank_code);
  return (
    <div className="mt-4">
      <Row gutter={[10, 10]}>
        <Col xs={24} md={8}>
          <div className="flex flex-col justify-between  h-10 ">
            <h3 className="text-gray-500"> Account Number </h3>
            <h3>{ loan_request?.product_id === "ckl6lnutkau5a09023mo3irar"  ? "*****" : bankDetails?.account_number}</h3>
          </div>
          <Divider />
        </Col>

        <Col xs={24} md={8}>
          <div className="flex flex-col justify-between  h-10 ">
            <h3 className="text-gray-500"> Bank Name </h3>
            <h3 className="text-black">{bankName?.name}</h3>
          </div>
          <Divider />
        </Col>

        <Col xs={24} md={8}>
          <div className="flex flex-col justify-between  h-10 ">
            <h3 className="text-gray-500"> Account Name </h3>
            <h3 className="text-black">{ loan_request?.product_id === "ckl6lnutkau5a09023mo3irar"  ? "*****" : bankDetails?.account_name}</h3>
          </div>
          <Divider />
        </Col>
      </Row>
    </div>
  );
}

export default BankDetails;
