import {
  VictoryBar,
  VictoryChart,
  VictoryAxis,
  VictoryGroup,
  VictoryTheme,
  VictoryLine,
} from "victory";

import { currency } from "../../utlis/currencyFormater";

function InflowAndOutFlow({ data }) {
  const styleIndicator = {
    height: 10,
    width: 10,
  };

  const cleanDataBalance = (data) =>
    data?.map((datum, index) => ({
      x: index + 1,
      y: Math.floor(datum.balance),
    }));

  const getXAxis = (data) =>
    data?.map((datum, index) => `${datum.month} ${datum.year}`);

  const cleanDataCredit = (data) =>
    data?.map((datum, index) => ({
      x: index + 1,
      y: Math.floor(datum.total_credit),
    }));

  const cleanDataDebit = (data) =>
    data?.map((datum, index) => ({
      x: index + 1,
      y: Math.floor(datum.total_debit),
    }));

  return (
    <div>
      <div className="my-2">
        <p className="text-gray-600 text-center text-lg">
          Total Inflow vs Total Outflow
        </p>
        <p className="text-gray-600 text-center text-lg">Month-on-Month</p>
      </div>
      <VictoryChart
        domainPadding={50}
        width={600}
        height={300}
        theme={VictoryTheme.material}
        style={{
          parent: {
            border: "1px solid #ccc",
          },
        }}
      >
        <VictoryAxis
          tickFormat={getXAxis(data)}
          style={{
            axis: { stroke: "none" },
            tickLabels: {
              fontSize: "8px",
              color: "#757580",
              angle: -45,
            },
          }}
        />
        <VictoryAxis
          dependentAxis
          offsetX={60}
          tickFormat={(x) => `${currency(x)}`}
          style={{
            tickLabels: { fontSize: "8px" },
          }}
        />
        <VictoryGroup offset={15} colorScale={"qualitative"}>
          <VictoryBar
            data={cleanDataCredit(data)}
            style={{ data: { fill: "#37a768" } }}
          />
          <VictoryBar
            data={cleanDataDebit(data)}
            style={{ data: { fill: "#4198d6" } }}
          />
          <VictoryLine
            data={cleanDataBalance(data)}
            style={{ data: { stroke: "#d2b814" } }}
          />
        </VictoryGroup>
      </VictoryChart>

      <div className="flex space-x-5 justify-center my-2">
        <div className="flex space-x-2 items-center">
          <span
            style={{ ...styleIndicator, background: "#37a768" }}
            className="block"
          ></span>
          <span>Total Credit</span>
        </div>

        <div className="flex space-x-2 items-center">
          <span
            style={{ ...styleIndicator, background: "#4198d6" }}
            className="block"
          ></span>
          <span>Total Debit</span>
        </div>

        <div className="flex space-x-2 items-center">
          <span
            style={{ ...styleIndicator, background: "#d2b814" }}
            className="block"
          ></span>
          <span>Total Balance</span>
        </div>
      </div>
    </div>
  );
}

export default InflowAndOutFlow;
