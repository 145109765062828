import React from "react";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { Table, Button } from "antd";
import { useTable } from "../../context/TableContext";
import { currency } from "../../utlis/currencyFormater";

function SingleRepaymentsTable({ data }) {
  const history = useHistory();
  const { setPage, page } = useTable();

  const cleanData = (data) =>
    data?.map((res, index) => ({
      key: res.id,
      id: res.id,
      status: res.status,
      amount: currency(res.amount),
      date: moment(res?.due_date?.split(" ")[0]).format("YYYY-MM-DD"),
    }));

  const columns = [
    {
      title: "Transaction ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
    },

    {
      title: "Date",
      dataIndex: "date",
      key: "date",
    },

    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },

    {
      title: "Type",
      dataIndex: "type",
      key: "type",
    },
    {
      title: "Actions",
      dataIndex: "action",
      key: "action",
      render: (text, record) => (
        <Button
          onClick={() => {
            history.push(`/repayments/${record.key}`);
          }}
        >
          Details
        </Button>
      ),
    },
  ];

  return (
    <Table
      loading={data === undefined && !data}
      columns={columns}
      dataSource={cleanData(data)}
      pagination={{
        position: ["bottomLeft"],
        current: page,
        onChange: (e) => setPage(e),
      }}
    />
  );
}

export default SingleRepaymentsTable;
