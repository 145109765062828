import React, { useEffect, useRef } from "react";
import { Row, Col, Card, Tag } from "antd";
import { banks } from "../components/API/Uploader";
import { ArrowLeftOutlined, LoadingOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import useSWR from "swr";
import networkRequest from "../utlis/Request";
import { useAuth } from "../context/AuthContext";
import { currency } from "../utlis/currencyFormater";
import SingleAnalyserStatement from "../components/Table/SingleAnalyserStatement";
import AllAnalyserStatement from "../components/Table/BankStatemetTwo";
import InflowAndOutFlow from "../components/charts/InflowAndOutFlow";
import Fraud from "../components/API/Fraud";
import PremiumTable from "../components/Table/PremiumTable";

function API(props) {
  const myRef = useRef(null);
  const { id } = useParams();

  const { app_key } = useAuth();
  const history = useHistory();
  const { data: statement } = useSWR(
    `/bs-analyzer/statement/${id}?fmt=pretty&&key=${app_key}`,
    (url) => networkRequest.GET_V2(url, app_key)
  );

  const { data } = useSWR(
    `/bs-analyzer/statement/${id}?fmt=basic&&key=${app_key}`,
    (url) => networkRequest.GET_V2(url, app_key)
  );

  const { data: premium } = useSWR(
    `/bs-analyzer/premium_analysis/${id}?key=${app_key}`,
    (url) => networkRequest.GET_V2(url, app_key)
  );

  const findBank = (code) => banks.find((bank) => bank.id === code);

  useEffect(() => {
    myRef.current.scrollIntoView();
  });
  return (
    <section className="h-full py-10 w-full" ref={myRef}>
      <div className="flex justify-center">
        <Tag color="magenta" className="text-base">
          BETA
        </Tag>
      </div>
      <div className="my-5">
        <span
          className="text-gray-500 cursor-pointer"
          onClick={() => history.goBack()}
        >
          <span>
            <ArrowLeftOutlined />
          </span>
          <span> back</span>
        </span>
      </div>
      <Row gutter={[10, 10]}>
        <Col xs={24} md={24}>
          <Row gutter={[10, 10]}>
            <Col xs={24} md={8}>
              <Card>
                <div className="flex justify-between border-0 pb-2 border-b my-2">
                  <p className="text-gray-400 text-base">Account Name</p>
                  {data?.data?.account_name ? (
                    <p className="text-base">{data?.data?.account_name}</p>
                  ) : (
                    <p className="text-red-700 text-base">Error..</p>
                  )}
                </div>

                <div className="flex justify-between border-0 pb-2 border-b my-2">
                  <p className="text-gray-400 text-base">Account Number</p>
                  {data?.data?.account_number ? (
                    <p className="text-base">{data?.data?.account_number}</p>
                  ) : (
                    <p className="text-red-700 text-base">Error..</p>
                  )}
                </div>

                <div className="flex justify-between border-0 pb-2 border-b my-2">
                  <p className="text-gray-400 text-base">Bank Name</p>
                  <p className="text-base">
                    {findBank(data?.data?.bank_code)?.name}
                  </p>
                </div>

                <div className="flex justify-between border-0 pb-2 border-b my-2">
                  <p className="text-gray-400 text-base">Number of Months</p>
                  <p className="text-base">
                    {statement?.data?.prettyfied?.length}
                  </p>
                </div>

                <div className="flex justify-between border-0 pb-2 border-b my-2">
                  <p className="text-gray-400 text-base">Start Date</p>
                  {data?.data?.user_details?.start_date ? (
                    <p className="text-base">
                      {data?.data?.user_details?.start_date}
                    </p>
                  ) : (
                    <p className="text-red-700 text-base">Error..</p>
                  )}
                </div>

                <div className="flex justify-between border-0 pb-2 border-b my-2">
                  <p className="text-gray-400 text-base">End Date</p>
                  {data?.data?.user_details?.end_date ? (
                    <p className="text-base">
                      {data?.data?.user_details?.end_date}
                    </p>
                  ) : (
                    <p className="text-red-700 text-base">Error..</p>
                  )}
                </div>

                <div className="flex justify-between border-0 pb-2 border-b my-2">
                  <p className="text-gray-400 text-base">Currency</p>
                  <p className="text-base">NGN</p>
                </div>
              </Card>
            </Col>

            <Col xs={24} md={8}>
              <Card>
                <div className="flex justify-between border-0 pb-2 border-b my-2">
                  <p className="text-gray-400 text-base">Total Inflow</p>
                  <p className="text-base">
                    {currency(data?.data?.total_sum_of_credit)}
                  </p>
                </div>

                <div className="flex justify-between border-0 pb-2 border-b my-2">
                  <p className="text-gray-400 text-base">Total Outflow</p>
                  <p className="text-base">
                    {currency(data?.data?.total_sum_of_debit)}
                  </p>
                </div>

                <div className="flex justify-between border-0 pb-2 border-b my-2">
                  <p className="text-gray-400 text-base">
                    Average Monthly Inflow
                  </p>
                  <p className="text-base">
                    {currency(statement?.data?.user_stats?.credit_average)}
                  </p>
                </div>

                <div className="flex justify-between border-0 pb-2 border-b my-2">
                  <p className="text-gray-400 text-base">
                    Average Monthly Outflow
                  </p>
                  <p className="text-base">
                    {currency(statement?.data?.user_stats?.debit_average)}
                  </p>
                </div>

                {!!statement?.data?.user_stats?.credit_median && (
                  <div className="flex justify-between border-0 pb-2 border-b my-2">
                    <p className="text-gray-400 text-base">Median Credit</p>
                    <p className="text-base">
                      {currency(statement?.data?.user_stats?.credit_median)}
                    </p>
                  </div>
                )}

                <div className="flex justify-between border-0 pb-2 border-b my-2">
                  <p className="text-gray-400 text-base">Median Debit</p>
                  <p className="text-base">
                    {currency(statement?.data?.user_stats?.debit_median)}
                  </p>
                </div>

                <div className="flex justify-between border-0 pb-2 border-b my-2">
                  <p className="text-gray-400 text-base">Maximum Credit</p>
                  <p className="text-base">
                    {currency(statement?.data?.user_stats?.credit_max)}
                  </p>
                </div>

                <div className="flex justify-between border-0 pb-2 border-b my-2">
                  <p className="text-gray-400 text-base">Maximum Debit</p>
                  <p className="text-base">
                    {currency(statement?.data?.user_stats?.debit_max)}
                  </p>
                </div>
              </Card>
            </Col>
          </Row>
        </Col>

        <Col xs={24} md={24}>
          <Card bodyStyle={{ padding: 15 }}>
            <PremiumTable data={premium?.data} />
          </Card>
        </Col>

        <Col xs={24} md={24}>
          <Card bodyStyle={{ padding: 15 }}>
            {statement?.data === undefined && (
              <div className="flex items-center">
                <LoadingOutlined />
                <p className="text-base mx-3 text-purple-700">
                  Analysing... this can take up to 5 minutes
                </p>
              </div>
            )}

            <SingleAnalyserStatement
              data={statement?.data?.prettyfied}
              statement={statement}
            />
          </Card>

          <Fraud fraud={statement?.data?.fraud_check} />

          <Card bodyStyle={{ padding: 15 }}>
            <AllAnalyserStatement
              data={statement?.data?.transactions}
              loading={statement?.data}
              statement={statement}
            />
          </Card>
        </Col>

        <Col xs={24} md={24}>
          <Card bodyStyle={{ padding: 15 }}>
            <InflowAndOutFlow data={statement?.data?.prettyfied} />
          </Card>
        </Col>
      </Row>
    </section>
  );
}

export default API;
