import React, { useState } from "react";
import {
  Modal,
  Button,
  Row,
  Form,
  Col,
  Input,
  InputNumber,
  notification,
  message,
} from "antd";
import { useAuth } from "../../context/AuthContext";
import { CheckCircleFilled } from "@ant-design/icons";
import networkRequest from "../../utlis/Request";
import decision from "../../assets/images/decision.png";
import { currency, currencyParser } from "../../utlis/currencyFormater";
import { trigger } from "swr";

const ApproveModal = ({ id, loan_request }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const { user } = useAuth();
  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleApprove = async (values) => {
    setLoading(true);
    const approval = await networkRequest.POST(
      `/loan_request/${id}/approve`,
      user?.data?.token,
      {
        amount: String(values.amount),
        estimated_repayment: String(values.estimated_repayment),
        rate: Number(values.rate),
        tenure: Number(values.tenure),
      }
    );

    if (approval.status !== "success") {
      setLoading(false);
      notification.error({ message: approval.message });
      return;
    }
    setLoading(false);
    message.success(approval.message);
    trigger(`/loan_request/${id}`);
    setIsModalVisible(false);
  };

  return (
    <>
      <Button type="primary" icon={<CheckCircleFilled />} onClick={showModal}>
        Approve
      </Button>
      <Modal
        footer={null}
        visible={isModalVisible}
        onCancel={handleCancel}
        width="30%"
      >
        <div className="">
          <div className=" w-full flex justify-center">
            <img
              alt="success"
              src={decision}
              className="object-center justify-self-auto mt-10 w-20"
            />
          </div>

          <p className="flex justify-center py-2 text-lg">Set Loan terms</p>

          <div>
            <Form
              onFinish={handleApprove}
              layout="vertical"
              initialValues={{ remember: true }}
            >
              <Row gutter={[10, 10]}>
                <Col xs={24} md={24} lg={12}>
                  <Form.Item
                    label="Approved Amount"
                    name="amount"
                    rules={[
                      {
                        required: true,
                        message: "amount is required!",
                      },
                    ]}
                  >
                    <InputNumber
                      formatter={currency}
                      parser={currencyParser}
                      placeholder=""
                      size="large"
                      className="w-full"
                    />
                  </Form.Item>
                </Col>

                <Col xs={24} md={24} lg={12}>
                  <Form.Item
                    label="Rate"
                    name="rate"
                    rules={[
                      {
                        required: true,
                        message: "rate is required!",
                      },
                    ]}
                  >
                    <Input
                      placeholder="3"
                      suffix="%"
                      size="large"
                      className="contact-form_input"
                    />
                  </Form.Item>
                </Col>

                <Col xs={24} md={24} lg={12}>
                  <Form.Item
                    label="Tenure"
                    name="tenure"
                    rules={[
                      {
                        required: true,
                        message: "tenure is required!",
                      },
                    ]}
                  >
                    <Input
                      placeholder="5"
                      suffix="Months"
                      size="large"
                      className="contact-form_input"
                    />
                  </Form.Item>
                </Col>

                <Col xs={24} md={24} lg={12}>
                  <Form.Item
                    label="Estimated Repayment"
                    name="estimated_repayment"
                    rules={[
                      {
                        required: true,
                        message: "estimated repayment is required!",
                      },
                    ]}
                  >
                    <InputNumber
                      formatter={currency}
                      parser={currencyParser}
                      placeholder=""
                      size="large"
                      className="w-full"
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Form.Item>
                <div className="p-2 w-full flex justify-end">
                  <Button
                    htmlType="submit"
                    loading={loading}
                    size="large"
                    type="primary"
                  >
                    Approve
                  </Button>
                </div>
              </Form.Item>
            </Form>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ApproveModal;
