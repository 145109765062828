import React, { useState, useEffect } from "react";
import { Card, DatePicker, message, Button } from "antd";
import moment from "moment";
import useSWR from "swr";
import networkRequest from "../utlis/Request";
import RequestAnalytics from "../components/request/RequestAnalytics";
import RequestsTable from "../components/Table/Requests";
import { useTable } from "../context/TableContext";
import { useAuth } from "../context/AuthContext";
import { CSVLink } from "react-csv";
const { RangePicker } = DatePicker;

function Request(props) {
  const { searchDate, setSearchDate } = useTable();
  const [ csvData, setCsvData ] = useState([]);
  const { user } = useAuth();
  const dateFormat = "YYYY-MM-DD";

  const { data: requests, error } = useSWR(
    `/loan_requests?query=${searchDate.start}&${searchDate.end}`,
    (url) =>
      networkRequest.POST(url, user?.data?.token, {
        start: moment(searchDate.start).format("YYYY-MM-DD"),
        end: moment(searchDate.end).format("YYYY-MM-DD"),
      })
  );

  if (error) {
    message.loading(error);
  }

  useEffect(() => {
    if(requests){
      setCsvData(requests?.data)
    }
    return () => {
      setCsvData([])
    }
  }, [requests])

  return (
    <section>
      <RequestAnalytics />
      <div className="my-10">
        <Card>
          <div className="flex justify-end mb-5">
            <RangePicker
              onChange={(value) =>
                setSearchDate({ start: value[0], end: value[1] })
              }
              defaultValue={[searchDate.end, searchDate.start]}
              format={dateFormat}
            />
            <div className="flex -mt-3">
              <Button type="link" >
                <CSVLink data={csvData || []} filename="requests.csv">Export to CSV</CSVLink>
              </Button>
            </div>
          </div>

          <RequestsTable data={requests?.data} csv={setCsvData} />
        </Card>
      </div>
    </section>
  );
}

export default Request;
