import React, { useState } from "react";
import { useAuth } from "../../context/AuthContext";
import networkRequest from "../../utlis/Request";
import { trigger } from "swr";
import {
  Modal,
  Button,
  notification,
  message,
  Form,
  Col,
  Row,
  Input,
} from "antd";
import Success from "../../assets/images/success.png";
import { currency } from "../../utlis/currencyFormater";

const LoanDisbursed = ({ id, loan_request, closeDrawer }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalVisibleManual, setIsModalVisibleManual] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingManual, setLoadingManual] = useState(false);
  const { user } = useAuth();

  const handleAccept = async () => {
    setLoading(true);
    const approval = await networkRequest.PUT(
      `/loan_request/${id}/disburse`,
      user?.data?.token
    );

    if (approval?.status !== "success") {
      setLoading(false);
      notification.error({ message: approval?.message });
      return;
    }
    setLoading(false);
    closeDrawer();
    message.success(approval?.message);
    trigger(`/loan_request/${id}`);
    showModal();
  };

  const handleDisburse = async () => {
    setLoading(true);
    const approval = await networkRequest.GET(
      `/loan_request/micro_loan/${id}/disburse`,
      user?.data?.token
    );

    if (approval?.status !== "success") {
      setLoading(false);
      notification.error({ message: approval?.message });
      return;
    }
    setLoading(false);
    closeDrawer();
    message.success(approval?.message);
    trigger(`/loan_request/${id}`);
    showModal();
  };

  const handleManualDisburse = async (values) => {
    setLoadingManual(true);
    const approval = await networkRequest.PUT(
      `/loan_request/${id}/disburse/manually`,
      user?.data?.token,
      values
    );

    if (approval?.status !== "success") {
      setLoadingManual(false);
      notification.error({ message: approval?.message });
      return;
    }
    setLoadingManual(false);
    closeDrawer();
    message.success(approval?.message);
    trigger(`/loan_request/${id}`);
    showModal();
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleManualCancel = () => {
    setIsModalVisibleManual(false);
  };

  return (
    <>
      <Button
        loading={loading}
        className="bg-green-500 text-white mb-2"
        block
        onClick={
          loan_request?.product_id !== "ckl6lnutkau5a09023mo3irar"
            ? handleAccept
            : handleDisburse
        }
      >
        Disburse Loan
      </Button>

      <Button
        loading={loadingManual}
        className="bg-purple-500 text-white mb-5"
        block
        onClick={() => setIsModalVisibleManual(true)}
      >
        Disburse Loan Manually
      </Button>
      <Modal visible={isModalVisible} footer={null} onCancel={handleCancel}>
        <div className="grid justify-items-center">
          <img
            alt="success"
            src={Success}
            className="object-center justify-self-auto"
          />
          <h2 className="font-bold text-2xl text-center -mt-5">
            Loan Disbursed!
          </h2>
          <p className="text-gray-500 text-center mt-3 w-56">
            {`You successfully disbursed “${currency(
              loan_request?.terms.amount
            )}” loan request to "${loan_request?.fullname}” `}
          </p>
          {/* <div>
            <Button type="primary" className="mt-5 font-bold" block>
              View Repayment
            </Button>
          </div> */}
        </div>
      </Modal>

      <Modal
        visible={isModalVisibleManual}
        footer={null}
        onCancel={handleManualCancel}
      >
        <div className="py-10">
          <Form
            layout="vertical"
            initialValues={{ remember: true }}
            onFinish={handleManualDisburse}
          >
            <Row gutter={[2, 2]}>
              <Col xs={24} md={24} lg={24}>
                <Form.Item
                  label="Drop a note"
                  name="note"
                  rules={[
                    {
                      required: true,
                      message: "reason is required!",
                    },
                  ]}
                >
                  <Input.TextArea />
                </Form.Item>
              </Col>
            </Row>

            <Form.Item>
              <div className="p-2 w-full flex justify-end">
                <Button htmlType="submit" loading={loadingManual} size="large">
                  Submit
                </Button>
              </div>
            </Form.Item>
          </Form>
        </div>
      </Modal>
    </>
  );
};

export default LoanDisbursed;
