import React from "react";
import { Divider, DatePicker, Tag, Alert } from "antd";
import moment from "moment";
import useSWR from "swr";
import TagManager from "react-gtm-module";
import networkRequest from "../utlis/Request";
import Uploader from "../components/API/Uploader";
import { useAuth } from "../context/AuthContext";
import APIAnalytics from "../components/API/APIanyalystics";
import { useTable } from "../context/TableContext";
import AllStatement from "../components/Table/AllStatement";
const { RangePicker } = DatePicker;

function APIWelcomePage(props) {
  const { user, app_key } = useAuth();

  const tagManagerArgs = {
    dataLayer: {
      userId: user?.data?.slug,
      page: "BSA Landing Page",
    },
    dataLayerName: "PageDataLayer",
  };

  TagManager.dataLayer(tagManagerArgs);

  const dateFormat = "YYYY-MM-DD";
  const { searchDate, setSearchDate } = useTable();

  const { data: analytics, error: analyticsError } = useSWR(
    `/bs-analyzer/metrics?type=${app_key}`,
    (url) => networkRequest.GET_V2(url, app_key)
  );

  const { data: requests, error } = useSWR(
    `/bs-analyzer/statements?query=${searchDate.start}&${searchDate.end}&${app_key}`,
    (url) =>
      networkRequest.PUT_V2(url, app_key, {
        start: moment(searchDate.start).format("YYYY-MM-DD"),
        end: moment(searchDate.end).format("YYYY-MM-DD"),
      })
  );

  if (false) {
    console.log(analyticsError, error);
  }

  return (
    <>
      <div className="my-5">
        <Alert
          style={{ textAlign: "center" }}
          type="info"
          message="Coverage for GTBank coming next week 🙏🏿🚀😇"
        />
      </div>
      <div className="flex justify-center">
        <Tag color="magenta" className="text-base">
          BETA
        </Tag>
      </div>
      <div className="my-5">
        <h1 className="text-center text-xl font-extrabold">
          Hi {user?.data?.firstname}! Welcome to The Evolve Credit PDF Bank
          Statement Analyser
        </h1>

        <div className="w-2/3 m-auto my-3">
          <p className="text-center text-gray-600 text-sm">
            Review and extract all the the insights you need from any PDF bank
            statement in 60 seconds.
          </p>

          <a
            href="https://evolve-credit.gitbook.io/evolvecredit/"
            target="_blank"
            className="text-center block my-2"
            rel="noreferrer"
            style={{ padding: 0, color: "blue" }}
          >
            View API documentation
          </a>
        </div>
        <div className="my-16 lg:px-5">
          <APIAnalytics app={analytics?.data} api_key={app_key} />
        </div>

        <div className="my-16  md:px-5">
          <p className="text-base font-extrabold my-5">
            How to analyse a PDF bank statement online:
          </p>
          <ol className="list-decimal mx-5">
            <li className="my-1">
              To start, select the bank your PDF statement is from.
            </li>
            <li className="my-1">Choose your PDF bank statement file.</li>
            <li className="my-1">
              Our tool will automatically upload and analyse the file.
            </li>
            <li className="my-1">
              View the analysis on the dashboard or Download the CSV file to
              your computer.
            </li>
          </ol>
          <p className="my-3">
            Integrate the analyser API into your system for a more seamless
            experience.
            <span>
              <a
                href="https://evolve-credit.gitbook.io/evolvecredit/"
                target="_blank"
                rel="noreferrer"
                style={{ padding: 0, color: "blue" }}
              >
                View API documentation
              </a>
            </span>
          </p>
          <Divider />
          <Uploader
            triggerUrl={`/bs-analyzer/statements?query=${searchDate.start}&${searchDate.end}&${app_key}`}
          />
        </div>

        <div className="flex justify-end">
          <RangePicker
            onChange={(value) =>
              setSearchDate({ start: value[0], end: value[1] })
            }
            defaultValue={[searchDate.end, searchDate.start]}
            format={dateFormat}
          />
        </div>
        <div className="my-5">
          <AllStatement data={requests?.data} />
        </div>
      </div>
    </>
  );
}

export default APIWelcomePage;
