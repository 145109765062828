import * as React from "react"

function Percent(props) {
  return (
    <svg
      width="45"
      height="45"
      viewBox="0 0 46 47"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        x={0.545}
        y={0.601}
        width={45.184}
        height={45.681}
        rx={13.679}
        fill="#EAECFF"
      />
      <mask
        id="prefix__a"
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={46}
        height={47}
      >
        <rect
          x={0.545}
          y={0.601}
          width={45.184}
          height={45.681}
          rx={13.679}
          fill="#fff"
        />
      </mask>
      <g mask="url(#prefix__a)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M32.03 39.176h3.228V11.84c0-.435-.259-.826-.667-1a1.04 1.04 0 00-1.162.239L32.03 12.47l-1.398-1.392a1.032 1.032 0 00-1.506 0l-1.399 1.392-1.399-1.392a1.032 1.032 0 00-1.506 0l-1.398 1.392-1.399-1.392a1.032 1.032 0 00-1.506 0l-1.398 1.392-1.4-1.392a1.032 1.032 0 00-1.505 0l-1.399 1.392-1.398-1.392a1.04 1.04 0 00-1.162-.24 1.085 1.085 0 00-.667 1.001v27.336h3.227c.595 0 1.076.486 1.076 1.087s-.481 1.088-1.076 1.088H11.59v5.438c0 .435.258.827.667 1 .409.175.86.088 1.162-.239l1.398-1.392 1.399 1.392a1.032 1.032 0 001.506 0l1.399-1.392 1.398 1.392a1.032 1.032 0 001.506 0l1.399-1.392 1.398 1.392a1.032 1.032 0 001.506 0l1.399-1.392 1.399 1.392a1.032 1.032 0 001.506 0l1.398-1.392 1.399 1.392c.194.218.473.327.753.327.129 0 .28-.022.409-.088.409-.173.667-.565.667-1v-5.438H32.03a1.081 1.081 0 01-1.076-1.088c0-.601.482-1.087 1.076-1.087zM21.272 41.35h-2.151a1.081 1.081 0 01-1.076-1.088c0-.601.481-1.087 1.076-1.087h2.151c.595 0 1.076.486 1.076 1.087s-.481 1.088-1.076 1.088zm6.455 0h-2.152a1.081 1.081 0 01-1.075-1.088c0-.601.48-1.087 1.075-1.087h2.152c.595 0 1.076.486 1.076 1.087s-.482 1.088-1.076 1.088z"
          fill="#5B45E0"
        />
        <path
          d="M19.12 24.021c-1.779 0-3.226-1.463-3.226-3.262 0-1.8 1.447-3.263 3.227-3.263 1.78 0 3.227 1.463 3.227 3.263 0 1.799-1.447 3.262-3.227 3.262zM27.727 32.722c-1.78 0-3.227-1.463-3.227-3.262 0-1.8 1.447-3.263 3.227-3.263 1.78 0 3.227 1.463 3.227 3.263 0 1.799-1.447 3.262-3.227 3.262zM28.63 19.192l-5.206 7.896-3.4 5.156c-.323.478-.99.63-1.506.304a1.122 1.122 0 01-.301-1.522l5.206-7.896 3.4-5.156c.344-.5 1.011-.63 1.506-.304.495.348.624 1.022.301 1.522z"
          fill="#F2F2F2"
        />
      </g>
    </svg>
  )
}

export default Percent;
