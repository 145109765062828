import React from "react";
import useSWR from "swr";
import { useAuth } from "../context/AuthContext";
import networkRequest from "../utlis/Request";
import { useParams } from "react-router-dom";
import Summary from "../components/request/Summary";
import Heading from "../components/request/Heading";
import Information from "../components/request/Information";
import HealthInfo from "../components/request/HealthInfo";
import Affordability from "../components/request/Affordability";
import { currency } from "../utlis/currencyFormater";
import { Card } from "antd";
import DTI from "../components/request/DTI";

function SingleRequest(props) {
  const { user } = useAuth();
  const { id } = useParams();

  const { data: loan_request, error } = useSWR(`/loan_request/${id}`, (url) =>
    networkRequest.GET(url, user?.data?.token)
  );

  const {
    data: BVN,
    error: bvnError,
  } = useSWR(`/user/${loan_request?.data?.user_id}/bvn`, (url) =>
    networkRequest.GET(url, user?.data?.token)
  );

  const {
    data: Bank,
    error: bankError,
  } = useSWR(`/user/${loan_request?.data?.user_id}/bank`, (url) =>
    networkRequest.GET(url, user?.data?.token)
  );

  const {
    data: User,
    error: userError,
  } = useSWR(`/user/${loan_request?.data?.user_id}`, (url) =>
    networkRequest.GET(url, user?.data?.token)
  );
  if (bvnError || bankError || userError || error) {
    console.log(`----------Error------Data----------`);
  }

  const mono = User?.data?.mono_connected;
  const mbs = loan_request?.data?.mbs_request_confirmed;

  return (
    <div>
      <Heading
        userDetail={{ ...User?.data, ...User?.data1 }}
        approved={loan_request?.data}
        loan_request={loan_request?.data}
      />
      <Summary
        loan_request={loan_request?.data}
        userDetail={{ ...User?.data, ...User?.data1 }}
      />
      {loan_request?.data?.terms?.amount &&
        loan_request?.data?.lender_status === "approved" && (
          <Card className="mt-2">
            <div className="flex space-x-12 my-3 justify-center">
              <h3 className="text-black text-base">
                Approved Amount:
                <span className="ml-5 text-base">
                  {currency(loan_request?.data?.terms?.amount || 0)}
                </span>
              </h3>

              <h3 className="text-black text-base">
                Approved Rate:
                <span className="text-black ml-5 text-base">
                  {`${loan_request?.data?.terms?.rate}%` || 0}
                </span>
              </h3>

              <h3 className="text-black text-base">
                Approved Tenure:
                <span className="text-black ml-5 text-base">
                  {`${loan_request?.data?.terms?.tenure} Months` || 0}
                </span>
              </h3>

              <h3 className="text-black text-base">
                Estimated Repayment:
                <span className="text-black ml-5 text-base">
                  {currency(
                    loan_request?.data?.terms?.estimated_repayment || 0
                  )}
                </span>
              </h3>
            </div>
          </Card>
        )}
      {user.data.disburse_with_ec === "yes" && (
        <>
          {mono === false && mbs === false ? (
            <Card className="mt-2 bg-yellow-300">
              <h2 className="text-center  text-base">
                {loan_request?.data1?.health_facility?.name || "This Applicant"}{" "}
                has not linked a processable bank statement yet. Hence,
                affordability model is not available for this loan at this time.
              </h2>
            </Card>
          ) : null}
        </>
      )}

      {loan_request?.data?.loan_type && (
        <>
          {" "}
          {mono || mbs ? (
            <Affordability id={id} loanType={loan_request?.data?.loan_type} />
          ) : null}
        </>
      )}
      {loan_request?.data?.loan_type && (
        <>
          {mono || mbs ? (
            <DTI
              id={id}
              healthFacility={loan_request?.data1?.health_facility}
            />
          ) : null}
        </>
      )}
      {loan_request?.data?.loan_type !== "health_loan" && (
        <Information
          loan_request={loan_request?.data}
          verification={loan_request?.data}
          bvnDetail={BVN?.data?.data}
          bankDetail={Bank?.data}
          userDetail={{ ...User?.data, ...User?.data1 }}
        />
      )}

      {loan_request?.data?.loan_type === "health_loan" && (
        <HealthInfo
          loan_request={loan_request?.data}
          userDetail={{ ...User?.data, ...User?.data1 }}
          healthFacility={loan_request?.data1?.health_facility}
          facilityExpense={loan_request?.data1?.facility_expenses}
          verification={loan_request?.data}
          bvnDetail={BVN?.data?.data}
          bankDetail={Bank?.data}
        />
      )}
    </div>
  );
}

export default SingleRequest;
