import React from "react";
import moment from "moment";
import { Table, Button } from "antd";

const columns = [
  {
    title: "Customer ID",
    dataIndex: "customerId",
    key: "customerId",
  },
  {
    title: "Name",
    dataIndex: "lname",
    key: "name",
  },
  {
    title: "Email",
    dataIndex: "email",
    key: "email",
  },

  {
    title: "Status",
    dataIndex: "status",
    key: "status",
  },

  {
    title: "Total Loans",
    dataIndex: "totalLoans",
    key: "totalLoans",
  },

  {
    title: "Last Activity",
    dataIndex: "lastActivity",
    key: "lastActivity",
  },
  {
    title: "Actions",
    dataIndex: "action",
    key: "action",
    render: (text) => <Button>View</Button>,
  },
];

function CustomersTable({ data }) {
  const cleanData = (data) =>
    data?.map((res, index) => ({
      key: res.id,
      sn: index + 1,
      name: res.fullname,
      lender: res.product_name,
      loanType: res.loan_type.split("_").join(" "),
      savedDate: moment(res.created_at).format("YYYY-MM-DD"),
      userStatus: res.user_status,
      adminStatus: res.admin_approval,
      lenderStatus: res.lender_status,
    }));

  return (
    <Table
      loading={!data === false }
      columns={columns}
      dataSource={cleanData(data)}
      pagination={{ position: ["bottomLeft"] }}
    />
  );
}

export default CustomersTable;
