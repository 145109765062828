import React from "react";
import useSWR from "swr";
import { Row, Col, Card, message } from "antd";
import { ReactComponent as Chart1 } from "../assets/svg/chart.svg";
import { ReactComponent as Chart2 } from "../assets/svg/chart2.svg";
import { ReactComponent as Chart3 } from "../assets/svg/chart3.svg";
import { ReactComponent as Chart4 } from "../assets/svg/chart4.svg";
import { ReactComponent as Chart5 } from "../assets/svg/chart5.svg";
import { ReactComponent as Chart6 } from "../assets/svg/chart6.svg";
import networkRequest from "../utlis/Request";
import { useAuth } from "../context/AuthContext";
import Overview from "../components/dashboard/Overview";
import Performance from "../components/dashboard/Performance";
import { formatNumber } from "../utlis/numberFormter";
import { currency } from "../utlis/currencyFormater";

function Dashboard(props) {
  const { user } = useAuth();
  const { data: analytics, error } = useSWR("/analytics", (url) =>
    networkRequest.GET(url, user?.data?.token)
  );

  if (error) {
    message.loading(error);
  }
  return (
    <div>
      <h2 className="font-bold text-2xl mb-7">Dashboard</h2>
      <Row gutter={32} className="flex justify-between">
        <Col className="gutter-row" span={8}>
          <Card>
            <div className="flex justify-between space-x-4 w-auto">
              <div className="flex-1">
                <h3 className="text-gray-500 text-xs">Total Applications</h3>
                <h3 className="font-bold text-lg">
                  {formatNumber(analytics?.data?.total_requests?.count)}
                </h3>
              </div>
              <div>
                <Chart3 />
              </div>
            </div>
          </Card>
        </Col>
        <Col className="gutter-row" span={8}>
          <Card>
            <div className="flex justify-between space-x-4 w-auto">
              <div>
                <h3 className="text-gray-500 text-xs">Total Loan Disbursed</h3>
                <h3 className="font-bold text-lg">
                  {currency(analytics?.data?.total_disbursed_loans?.value)}
                </h3>
              </div>
              <div>
                <Chart2 />
              </div>
            </div>
          </Card>
        </Col>
        <Col className="gutter-row" span={8}>
          <Card>
            <div className="flex justify-between space-x-4 w-auto">
              <div>
                <h3 className="text-gray-500 text-xs">Total Active Loans</h3>
                <h3 className="font-bold text-lg">
                  {formatNumber(analytics?.data?.total_active_loans?.count)}
                </h3>
              </div>
              <div>
                <Chart1 />
              </div>
            </div>
          </Card>
        </Col>
      </Row>
      <Row className="mt-5 flex justify-between">
        <Col span={18}>
          <Card>
            <Overview />
          </Card>
        </Col>
        <Col span={6}>
          <Card>
            <Performance />
          </Card>
        </Col>
      </Row>
      <Row gutter={32} className="mt-5">
        <Col className="gutter-row" span={8}>
          <Card>
            <div className="flex space-x-4 w-auto">
              <div className="flex-1">
                <h3 className="text-gray-500 text-xs">Total Loan Approved</h3>
                <h3 className="font-bold text-lg">
                  {formatNumber(analytics?.data?.approved_applications)}
                </h3>
              </div>
              <div>
                <Chart4 />
              </div>
            </div>
          </Card>
        </Col>
        <Col className="gutter-row" span={8}>
          <Card>
            <div className="flex space-x-4 w-auto">
              <div className="flex-1">
                <h3 className="text-gray-500 text-xs">Total Loan Declined</h3>
                <h3 className="font-bold text-lg">
                  {formatNumber(analytics?.data?.declined_applications)}
                </h3>
              </div>
              <div>
                <Chart5 />
              </div>
            </div>
          </Card>
        </Col>
        <Col className="gutter-row" span={8}>
          <Card>
            <div className="flex w-auto text-xs">
              <div className="flex-1">
                <h3 className="text-gray-500">Value of Active Loans</h3>
                <h3 className="font-bold text-lg">
                  {currency(analytics?.data?.total_active_loans?.value)}
                </h3>
              </div>
              <div>
                <Chart6 />
              </div>
            </div>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default Dashboard;
