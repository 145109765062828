import Request from "./Request";
import SecureLS from "secure-ls";

const securedStorage = new SecureLS();

class auth {
  async Login(data) {
    try {
      const login = await Request.POST_WITHOUT_AUTH2(`/auth`, data);

      if (login?.status !== "success") {
        return { status: false, message: login?.message };
      }

      securedStorage.set("user", {
        data: { ...login.org, ...login.user, token: login.token },
      });
      return { status: true, message: "success ⚡", org: login.org };
    } catch (error) {
      return {
        status: false,
        message: "something went wrong, please try again",
      };
    }
  }

  async SendResetEmail(email) {
    const emailSent = await Request.GET(`/${email}/password/request_reset`);

    if (emailSent?.status !== "success") {
      return { status: false, message: emailSent?.message };
    }

    return { status: true, message: "success ⚡" };
  }

  async ResetPassword(data) {
    const resetPassword = await Request.POST(`/password/reset`, "", data);

    if (resetPassword?.status !== "success") {
      return { status: false, message: resetPassword?.message };
    }

    return { status: true, message: "success ⚡" };
  }
}

export default new auth();
