import { createContext, useContext, useState } from "react";
import useSWR from "swr";
import networkRequest from "../utlis/Request";
import SecureLS from "secure-ls";

const AuthContext = createContext();
const securedStorage = new SecureLS();

function AuthProvider({ children }) {
  const [isLoggedIn, setISloggedIn] = useState(false);

  const [user, setUser] = useState(securedStorage.get("user"));

  const { data } = useSWR(`/priviledges?user=${user?.data?.token}`, (url) =>
    networkRequest.POST_V3(url, user?.data?.token)
  );

  const { data: apps } = useSWR(`/apps?user=${user?.data?.token}`, (url) =>
    networkRequest.GET_V3(url, user?.data?.token)
  );

  const handleLogout = () => {
    securedStorage.removeAll();
    setUser(null);
  };

  const value = {
    isLoggedIn,
    setISloggedIn,
    user,
    handleLogout,
    app_key: apps?.data ? apps?.data[0]?.live_key : "",
    priviledges: data?.data || {},
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

const useAuth = () => {
  const context = useContext(AuthContext);

  if (context === undefined) {
    throw new Error("useAuth must be used within an AuthProvider");
  }

  return context;
};

export { AuthProvider, useAuth };
