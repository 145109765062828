import * as React from "react"

function Enter(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M17.725 12.7l-5.007 5c-.2.2-.4.3-.701.3-.3 0-.501-.1-.701-.3-.4-.4-.4-1 0-1.4L14.62 13H5.007C2.203 13 0 10.8 0 8V1c0-.6.4-1 1.001-1 .601 0 1.002.4 1.002 1v7c0 1.7 1.302 3 3.004 3h9.613l-3.304-3.3c-.4-.4-.4-1 0-1.4.4-.4 1.001-.4 1.402 0l5.007 5c.1.1.2.2.2.3.1.2.1.5 0 .8 0 .1-.1.2-.2.3z"
        fill="#fff"
      />
    </svg>
  )
}

export default Enter;
