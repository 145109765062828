import React from "react";
import moment from "moment";
import { useTable } from "../../context/TableContext";
import { Table, Button } from "antd";
import { currency } from "../../utlis/currencyFormater";
import ChargeBtn from "../repayment/ChargeBtn";
import { useHistory } from "react-router-dom";

function AllRepaymentsTable({ data, showChargenowBtn, ordID }) {
  const { setPage, page } = useTable();
  const history = useHistory();

  const columns = [
    {
      title: "Transaction ID",
      dataIndex: "transactionId",
      key: "transactionId",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },

    {
      title: "Disbursed Date",
      dataIndex: "date",
      key: "date",
      sorter: (a, b) => new Date(a.date).valueOf() - new Date(b.date).valueOf(),
    },

    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },

    {
      title: "Amount",
      dataIndex: "amountRepaid",
      key: "amountRepaid",
    },

    {
      title: "Repayment Date",
      dataIndex: "nextDate",
      key: "nextDate",
    },

    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (text, record) => (
        <div className="space-x-2 flex">
          <Button
            onClick={() => {
              history.push(`/repayments/${record.key}`);
            }}
          >
            View
          </Button>

          {!showChargenowBtn && record.collection_method !== "remita" ? (
            <ChargeBtn ID={record.key} />
          ) : (
            ""
          )}
        </div>
      ),
    },
  ];

  const cleanData = (data) =>
    data?.map((res, index) => ({
      key: res.id,
      sn: index + 1,
      transactionId: res.id,
      name: res?.borrower
        ? showChargenowBtn
          ? res?.borrower?.firstname + " ." + res?.borrower?.lastname[0]
          : res?.borrower?.firstname + " " + res?.borrower?.lastname
        : "----",
      date: moment(res.created_at).format("YYYY-MM-DD"),
      status: res.status,
      amountRepaid: currency(res.amount),
      nextDate: moment(res.due_date.split(" ")[0]).format("YYYY-MM-DD"),
      action: res?.status,
      collection_method: res?.collection_method,
    }));

  return (
    <Table
      loading={data === undefined && !data}
      columns={columns}
      dataSource={cleanData(data)}
      pagination={{
        position: ["bottomLeft"],
        current: page,
        onChange: (e) => setPage(e),
      }}
      scroll={{ x: 1400, y: 1500 }}
    />
  );
}

export default AllRepaymentsTable;
