import * as React from "react"

function CreditCard(props) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.5 5h-15C3.083 5 2 6.083 2 7.5v10C2 18.917 3.083 20 4.5 20h15c1.417 0 2.5-1.083 2.5-2.5v-10C22 6.083 20.917 5 19.5 5zm-15 1.667h15c.5 0 .834.333.834.833V10H3.668V7.5c0-.5.333-.833.833-.833zm0 11.666h15c.5 0 .834-.333.834-.833v-5.833H3.668V17.5c0 .5.333.833.833.833z"
        fill={props.match ? "#fff" : "#7E84A3"}
      />
    </svg>
  )
}

export default CreditCard;
