import React from "react";
import { Divider, Row, Col } from "antd";

function PersonalDetails({ userDetails }) {
  return (
    <div className="mt-4">
      <Row gutter={[10, 10]}>
        <Col xs={24} md={8}>
          <div className="flex flex-col justify-between  h-10 ">
            <h3 className="text-gray-500"> Name</h3>
            <h3>
              {userDetails?.firstname}
              {userDetails?.lastname}
            </h3>
          </div>
          <Divider />
        </Col>

        <Col xs={24} md={8}>
          <div className="flex flex-col justify-between  h-10">
            <h3 className="text-gray-500"> Email </h3>
            <h3 className="text-black">
              <a
                target="_blank"
                rel="noreferrer"
                href={`mailto:${userDetails?.email}`}
              >
                {userDetails?.email}
              </a>
            </h3>
          </div>
          <Divider />
        </Col>

        <Col xs={24} md={8}>
          <div className="flex flex-col justify-between  h-10">
            <h3 className="text-gray-500"> Phone </h3>
            <h3 className="text-black">
              <a
                target="_blank"
                rel="noreferrer"
                href={`https://wa.me/234${userDetails?.phone}`}
              >
                {userDetails.phone}
              </a>
            </h3>
          </div>
          <Divider />
        </Col>
      </Row>
    </div>
  );
}

export default PersonalDetails;
