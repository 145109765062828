import React from "react";
import { Switch, Route } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";
import { dashboardRoutes } from "../../routes/dashboardRoutes";
import Header from "./Header";

function Main(props) {
  const { user } = useAuth();

  return (
    <div className="h-full overflow-y-auto flex-grow">
      <Header />
      <div className="overflow-y-auto h-full p-3 px-10">
        <Switch>
          {dashboardRoutes(user).map(
            (route, index) =>
              route && (
                <Route
                  key={index}
                  path={route.path}
                  exact={route.exact}
                  children={<route.main />}
                />
              )
          )}

          <Route path="*">
            <h1>404</h1>
          </Route>
        </Switch>
      </div>
    </div>
  );
}

export default Main;
