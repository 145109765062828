import React from "react";
import useSWR from "swr";
import { Row, Col, Divider, message } from "antd";
import { useAuth } from "../../../context/AuthContext";
import networkRequest from "../../../utlis/Request";
import { currency } from "../../../utlis/currencyFormater";

function SMEDetails({ id }) {
  const { user } = useAuth();
  const {
    data: SME,
    error: SMEError,
  } = useSWR(`/loan_request/${id}/get_sme_data`, (url) =>
    networkRequest.GET(url, user?.data?.token)
  );

  if (SMEError) {
    message.loading("retrying...");
  }
  return (
    <div className="mt-4">
      <Row gutter={[10, 10]}>
        <Col xs={24} md={8}>
          <div className="flex flex-col justify-between  h-10 ">
            <h3 className="text-gray-500">Business Name</h3>
            <h3>
              {SME?.data?.business_name === "undefined"
                ? ""
                : SME?.data?.business_name}
            </h3>
          </div>
          <Divider />
        </Col>

        <Col xs={24} md={8}>
          <div className="flex flex-col justify-between  h-10 ">
            <h3 className="text-gray-500">Monthly Revenue</h3>
            <h3>
              {currency(
                SME?.data?.annual_revenue === "undefined"
                  ? 0
                  : SME?.data?.annual_revenue
              )}
            </h3>
          </div>
          <Divider />
        </Col>

        <Col xs={24} md={8}>
          <div className="flex flex-col justify-between  h-10 ">
            <h3 className="text-gray-500">Duration Of Operation</h3>
            <h3>
              {SME?.data?.duration_of_operation === "undefined"
                ? ""
                : SME?.data?.duration_of_operation}
            </h3>
          </div>
          <Divider />
        </Col>

        <Col xs={24} md={8}>
          <div className="flex flex-col justify-between  h-10 ">
            <h3 className="text-gray-500">Location</h3>
            <h3>
              {SME?.data?.location === "undefined" ? "" : SME?.data?.location}
            </h3>
          </div>
          <Divider />
        </Col>
      </Row>
    </div>
  );
}

export default SMEDetails;
