import React, { useState } from "react";
import { Col, message, Row, notification, Upload, Button } from "antd";
import { trigger } from "swr";
import {
  LoadingOutlined,
  FilePdfOutlined,
  FileAddOutlined,
} from "@ant-design/icons";
import BankImage from "./BankImage";
import networkRequest from "../../utlis/Request";
import { useAuth } from "../../context/AuthContext";
import { useHistory } from "react-router-dom";

const { Dragger } = Upload;

export const banks = [
  {
    name: "Access Bank",
    id: "044",
    src: "https://media.graphcms.com/output=format:jpg/FvYgdHFwQ6UVa9xnJ0Cg",
  },

  {
    name: "First Bank of Nigeria",
    id: "011",
    src: "https://media.graphcms.com/output=format:jpg/HzQXfKeZQ1wbkUPjFk1Q",
  },

  {
    name: "United Bank For Africa",
    id: "033",
    src: "https://media.graphcms.com/output=format:jpg/pxdXlRTBTC4hCZJl44ew",
  },

  // {
  //   name: "Guaranty Trust Bank",
  //   id: "058",
  //   src: "https://media.graphcms.com/output=format:jpg/qvmjsPTxQuOuSfKPbuMQ",
  // },

  {
    name: "Zenith Bank",
    id: "057",
    src: "https://media.graphcms.com/output=format:jpg/mQg37CR0RmKt8HJlRtB7",
  },
];

function Uploader({ app, triggerUrl }) {
  const [loading, setLoading] = useState(false);
  const [currentID, setCurrentID] = useState(null);
  const { app_key } = useAuth();
  const history = useHistory();

  const props = {
    name: "file",
    accept: ".pdf",
    disabled: currentID ? false : true,
    beforeUpload: () => false,
    onChange(info) {
      handleChange(info);
    },
  };

  const uploadButton = (
    <div>
      {loading ? (
        <div className="flex justify-center">
          <LoadingOutlined className="text-center" style={{ color: "white" }} />
        </div>
      ) : (
        <div className="flex justify-center">
          <FilePdfOutlined style={{ fontSize: "40px", color: "white" }} />
        </div>
      )}
      {loading ? (
        <>
          <p className="text-center text-base font-bold text-white">
            analysing...
          </p>
          <p className="text-center text-base font-bold text-white">
            This may take a few minutes.
          </p>
        </>
      ) : (
        <div>
          <Button
            icon={<FileAddOutlined />}
            className="my-3"
            disabled={currentID ? false : true}
            size="large"
          >
            CHOOSE FILE
          </Button>
          <p className="text-white">or drop file here</p>
          {!currentID && (
            <p className="text-center text-base  text-white">
              please select a bank first
            </p>
          )}
        </div>
      )}
    </div>
  );

  function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  }

  const handleChange = (info) => {
    if (info.file.type !== "application/pdf") {
      return message.error("Only Pdfs are allowed");
    }
    getBase64(info.file, (imageUrl) => {
      GetAnalyser({
        bank_code: currentID,
        base64: imageUrl.split("base64,")[1],
      });
    });
  };

  const GetAnalyser = async (values) => {
    setLoading(true);
    const creatingAPP = await networkRequest.POST_V2(
      `/bs-analyzer/analyze?fmt=basic`,
      app_key,
      values
    );

    if (creatingAPP.status !== "success") {
      setLoading(false);
      notification.error({ message: creatingAPP.message });
      return;
    }

    setLoading(false);
    trigger(triggerUrl);
    trigger(`/bs-analyzer/metrics?type=${app_key}`);
    history.push(`/analyser/${creatingAPP?.data?.id}`);
    message.success(creatingAPP.message);
  };

  return (
    <div>
      <Row gutter={[10, 10]}>
        <Col xs={24} md={12}>
          <div className="border p-8 shadow-sm rounded-md relative">
            <p
              className="absolute left-0 top-0 transform -translate-y-3 translate-x-5"
              style={{ backgroundColor: "#f5f6fa" }}
            >
              Select Bank
            </p>
            <Row gutter={[10, 10]}>
              {banks.map((bank) => (
                <Col xs={12} md={8} key={bank.id}>
                  <BankImage
                    src={bank.src}
                    id={bank.id}
                    bankName={bank.name}
                    currentID={currentID}
                    onClick={() => setCurrentID(bank.id)}
                  />
                </Col>
              ))}
            </Row>
          </div>
        </Col>
        <Col xs={24} md={12}>
          <Dragger {...props} style={{ backgroundColor: "#5B45E0" }}>
            {uploadButton}
          </Dragger>
        </Col>
      </Row>
    </div>
  );
}

export default Uploader;
