import React, { useRef } from "react";
import moment from "moment";
import { Table, Button, Space, Input } from "antd";
import Highlighter from "react-highlight-words";
import { SearchOutlined } from "@ant-design/icons";
import { useTable } from "../../context/TableContext";
import { useHistory } from "react-router-dom";
import { currency } from "../../utlis/currencyFormater";

function RequestsTable({ data, csv }) {
  const history = useHistory();
  const searchInput = useRef();

  const {
    requestTableNameSearchText,
    setRequestTableNameSearchText,
    requestTableNameSearchedColumn,
    setRequestTableNameSearchedColumn,
    page,
    setPage,
    requestTableSorter,
    setRequestTableSorters,
  } = useTable();
  const cleanData = (data) =>
    data?.map(
      (res, index) =>
        res.lender_status !== "rejected" &&
        res.lender_status !== "archived" && {
          key: res.id,
          sn: index + 1,
          name: res.fullname,
          lender: res.product_name,
          amount: currency(res.amount),
          savedDate: moment(res.created_at).format("YYYY-MM-DD"),
          userStatus:
            res.user_status === "saved"
              ? "In progress"
              : res.user_status === "archived"
              ? "archived"
              : "submitted",
          adminStatus: res.admin_approval,
          lenderStatus:
            res.lender_status === "saved"
              ? "pending"
              : res.lender_status === "approved"
              ? "approved"
              : (res.lender_status = "archived" ? "archived" : "rejected"),
        }
    );

  const getColumnSearchProps = (
    dataIndex,
    handleSearch,
    handleReset,
    requestTableSearchedColumn,
    requestTableSearchText
  ) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current.focus(), 100);
      }
    },
    render: (text) =>
      requestTableSearchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[requestTableSearchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const handleNameSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setRequestTableNameSearchText(selectedKeys[0]);
    setRequestTableNameSearchedColumn(dataIndex);
  };

  const handleNameReset = (clearFilters) => {
    clearFilters();
    setRequestTableNameSearchText("");
  };

  const columns = [
    {
      title: "Full Name",
      dataIndex: "name",
      filteredValue: [requestTableNameSearchText] || null,
      sorter: (a, b) => a.name.length - b.name.length,
      ...getColumnSearchProps(
        "name",
        handleNameSearch,
        handleNameReset,
        requestTableNameSearchedColumn,
        requestTableNameSearchText
      ),
      key: "name",
      render: (text) => <span className="overflow-clip uppercase">{text}</span>,
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (text) => <span className="overflow-clip uppercase">{text}</span>,
    },

    {
      title: "Started On",
      dataIndex: "savedDate",
      defaultSortOrder: "descend",
      key: "savedDate",
      sorter: (a, b) =>
        new Date(a.savedDate).valueOf() - new Date(b.savedDate).valueOf(),
    },

    {
      title: "Borrower",
      dataIndex: "userStatus",
      key: "userStatus",
      filteredValue: requestTableSorter.userStatus || null,
      filters: [
        {
          text: "In progress",
          value: "In progress",
        },
        {
          text: "submitted",
          value: "submitted",
        },
      ],
      onFilter: (value, record) => record.userStatus.indexOf(value) === 0,
      render: (text) => <span className="overflow-clip uppercase">{text}</span>,
    },

    {
      title: "You",
      dataIndex: "lenderStatus",
      key: "lenderStatus",
      filteredValue: requestTableSorter.lenderStatus || null,
      filters: [
        {
          text: "pending",
          value: "pending",
        },
        {
          text: "approved",
          value: "approved",
        },
        {
          text: "rejected",
          value: "rejected",
        },
      ],
      onFilter: (value, record) => record.lenderStatus.indexOf(value) === 0,
      render: (text) => <span className="overflow-clip uppercase">{text}</span>,
    },

    {
      title: "Actions",
      dataIndex: "action",
      key: "8",
      render: (text, record) => (
        <Button
          onClick={() => {
            history.push(`/requests/${record.key}`);
          }}
        >
          View Request
        </Button>
      ),
    },
  ];

  const handleChange = (
    pagination,
    filters,
    sorter,
    selectedRowKeys,
    selectedRows
  ) => {
    csv(selectedRowKeys?.currentDataSource);
    setRequestTableSorters((prevState) => ({ ...prevState, ...filters }));
  };

  return (
    <Table
      size="large"
      loading={data === undefined && !data}
      columns={columns}
      onChange={handleChange}
      dataSource={cleanData(data)}
      pagination={{
        position: ["bottomLeft"],
        current: page,
        onChange: (e) => setPage(e),
      }}
    />
  );
}

export default RequestsTable;
