import React, { useState } from "react";
import { Card, Drawer, Avatar, Row, Col } from "antd";
import Overview from "./Overview";

import { CloseSquareFilled } from "@ant-design/icons";

function AppCard({ app }) {
  const [openDrawer, setOpenDrawer] = useState(false);
  return (
    <div>
      <Card style={{ borderRadius: 9 }} onClick={() => setOpenDrawer(true)}>
        <Avatar>{app?.name[0]?.toUpperCase()}</Avatar>

        <div className="py-3">
          <p className="text-lg text-gray-700 font-extrabold">{app?.name}</p>
        </div>
      </Card>

      <Drawer
        visible={openDrawer}
        width="100%"
        onClose={() => setOpenDrawer(false)}
        closeIcon={<CloseSquareFilled className="text-2xl text-red-700" />}
      >
        <div className="lg:p-20 p-5">
          <Row className="mt-5 flex justify-between">
            <Col span={24}>
              <Card>
                <Overview app={app} />
              </Card>
            </Col>
          </Row>
        </div>
      </Drawer>
    </div>
  );
}

export default AppCard;
