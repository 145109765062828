import React from "react";
import { ArrowLeftOutlined } from "@ant-design/icons";
import DecisionButton from "../button/decisionButton";
import { useHistory } from "react-router-dom";

function Heading({ userDetail, loan_request }) {
  const history = useHistory();

  return (
    <div className="mt-2">
      <span
        className="text-gray-500 cursor-pointer"
        onClick={() => history.goBack()}
      >
        <span>
          <ArrowLeftOutlined />
        </span>
        <span> back</span>
      </span>
      {userDetail?.firstname && (
        <div className="flex justify-between">
          <h2 className="font-bold text-lg">
            {userDetail?.firstname}'s Loan Request
          </h2>
          <DecisionButton loan_request={loan_request} userDetail={userDetail} />
        </div>
      )}
    </div>
  );
}

export default Heading;
