import { useState } from "react";
import { usePaystackPayment } from "react-paystack";
import { notification, Button, InputNumber, Modal } from "antd";
import { useAuth } from "../../context/AuthContext";
import { trigger } from "swr";
import networkRequest from "../../utlis/Request";
import { currencyParser, currency } from "../../utlis/currencyFormater";
import { formatNumber } from "../../utlis/numberFormter";
function Topup({ balance, api_key }) {
  const { user } = useAuth();

  const [openModal, setOpenModal] = useState(false);
  const [currentTopupMethod, setCurrentTopup] = useState(true);

  const [amount, setAmount] = useState(50000);
  const config = {
    email: user?.data?.admin_email,
    amount: amount * 100,
    publicKey: process.env.REACT_APP_PAYSTACKKEY,
  };
  const initializePayment = usePaystackPayment(config);

  const onSuccess = (reference) => {
    networkRequest
      .POST_V3(`/bs_analyzer/payment`, user?.data?.token, {
        reference: reference.reference,
      })
      .then((res) => {
        if (res.status !== "success") {
          notification.error({ message: res.message });
          return;
        }
        trigger(`/bs-analyzer/metrics?type=${api_key}`);
        notification.success({ message: res.message });
      })
      .catch((error) => {
        notification.error({ message: error.message });
      });
  };

  // you can call this function anything
  const onClose = () => {
    // implementation for  whatever you want to do when the Paystack dialog closed.
    console.log("closed");
  };

  const handleOpenPaystack = () => {
    setOpenModal(false);
    initializePayment(onSuccess, onClose);
  };

  return (
    <div>
      <Button onClick={() => setOpenModal(true)}>Top up balance</Button>

      <Modal
        visible={openModal}
        title="Fund wallet"
        closable={false}
        footer={null}
      >
        <>
          <p className="my-3 text-center">
            How much do you want to add to your wallet
          </p>
          <InputNumber
            formatter={currency}
            parser={currencyParser}
            value={amount}
            className="w-full h-10 rounded"
            onChange={(value) => setAmount(value)}
          />

          <div className="my-4">
            <Button
              type="primary"
              className="w-full uppercase"
              disabled={!!!amount}
              onClick={handleOpenPaystack}
            >
              Fund wallet
            </Button>
          </div>
        </>
        {currentTopupMethod && (
          <p className="text-center">
            With{" "}
            <strong className="mx-1 text-purple-600 text-lg">
              {currency(amount)}
            </strong>
            , you can analyse
            <strong className="mx-1 text-purple-600 text-lg">
              {formatNumber(Math.floor(amount / 50))}
            </strong>
            PDF bank statements.
          </p>
        )}
        <div className="flex justify-between mt-16">
          <Button onClick={() => setOpenModal(false)}>Cancel</Button>
          <Button
            onClick={() => setCurrentTopup(true)}
            style={{ color: "#5B45E0" }}
          >
            Estimate amount
          </Button>
        </div>
      </Modal>
    </div>
  );
}

export default Topup;
