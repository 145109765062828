import React, { useState } from "react";
import useSWR, { trigger } from "swr";
import { Button, notification, message } from "antd";
import networkRequest from "../../utlis/Request";
import { useAuth } from "../../context/AuthContext";
import Enter from "../../assets/customIcons/enter";
import ApproveModal from "../request/approveConfirmation";
import DeclineModal from "../request/declineConfirmation";
import DisburseDrawer from "../popups/DisburseDrawer";

const DecisionButton = ({ loan_request, userDetail }) => {
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);
  const { user } = useAuth();
  const [loading, setLoading] = useState(false);

  const showDrawer = () => {
    setIsDrawerVisible(true);
  };

  const handleCancel = () => {
    setIsDrawerVisible(false);
  };

  const resync = async () => {
    const sync = await networkRequest.GET(
      `/user/${userDetail?.id}/mono/sync`,
      user?.data?.token
    );

    if (sync.status !== "success") {
      setLoading(false);
      notification.error({ message: sync.message });
      return;
    }
    setLoading(false);
    message.success(sync.message);
    trigger(`/loan_request/${loan_request?.id}`);
  };

  const { data: Bank } = useSWR(`/user/${loan_request?.user_id}/bank`, (url) =>
    networkRequest.GET(url, user?.data?.token)
  );

  return (
    <div className="button flex justify-end space-x-4 mt-5">
      {userDetail?.mono_connected &&
        loan_request?.product_id !== "ckl6lnutkau5a09023mo3irar" && (
          <Button
            loading={loading}
            type="default"
            style={{ backgroundColor: "#00af7b" }}
            className=" flex text-white"
            onClick={() => resync()}
          >
            Resync Mono
          </Button>
        )}
      <div className="space-x-3 sm:space-x-4 ">
        {loan_request?.lender_status === "saved" && (
          <DeclineModal
            id={loan_request?.id}
            username={loan_request?.fullname}
            loan_request={loan_request}
          />
        )}
        <ApproveModal id={loan_request?.id} loan_request={loan_request} />
      </div>

      {loan_request?.lender_status === "approved" && (
        <Button
          type="default"
          style={{ backgroundColor: "#00af7b" }}
          className=" flex text-white"
          onClick={showDrawer}
        >
          <Enter className="mt-1 mr-1" />
          Disburse Loan
        </Button>
      )}

      <DisburseDrawer
        showDrawer={showDrawer}
        handleCancel={handleCancel}
        isDrawerVisible={isDrawerVisible}
        loan_request={loan_request}
        bankDetails={Bank?.data}
      />
    </div>
  );
};

export default DecisionButton;
