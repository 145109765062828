import React, { useEffect, useState } from "react";
import {
  Card,
  Row,
  Col,
  Form,
  Input,
  Button,
  Divider,
  message,
  notification,
} from "antd";
import useSWR from "swr";
import networkRequest from "../../utlis/Request";
import { useAuth } from "../../context/AuthContext";
import { currency } from "../../utlis/currencyFormater";

function DTI({ id, healthFacility }) {
  const [loading, setLoading] = useState(false);
  const [estimated_monthly, setEstimatedMonthly] = useState(0);
  const [dti, setDti] = useState(20);
  const { user } = useAuth();
  const {
    data: affordability,
    error: affordableError,
  } = useSWR(`/loan_request/tp/${id}/affordability`, (url) =>
    networkRequest.GET(url, user?.data?.token)
  );

  const handleCalculate = async (values) => {
    setLoading(true);
    const approval = await networkRequest.PUT(
      `/loan_request/tp/${id}/affordability/dynamic`,
      user?.data?.token,
      {
        dti: Number(values.dti),
        monthly_net_income: affordability?.data?.payment_capacity?.value,
      }
    );

    if (approval?.status !== "success") {
      setLoading(false);
      notification.error({ message: approval?.message });
      return;
    }
    setLoading(false);
    setEstimatedMonthly(approval?.data?.estimated_monthly);
    setDti(approval?.data?.dti);
    message.success(approval?.message);
  };

  useEffect(() => {
    networkRequest
      .PUT(`/loan_request/tp/${id}/affordability/dynamic`, user?.data?.token, {
        dti: 20,
        monthly_net_income: affordability?.data?.payment_capacity?.value,
      })
      .then((res) => {
        setEstimatedMonthly(res?.data?.estimated_monthly);
        setDti(res?.data?.dti);
      })
      .catch((error) => {
        message.error("An error occured calculating DTI");
      });
  }, [affordability, user, id]);
  if (affordableError) {
    console.log(`----------Error------Data----------`);
  }
  return (
    <Row gutter={[10, 10]}>
      <Col xs={24} md={12}>
        <Card>
          <h2 className="text-center text-lg font-bold my-3">
            Debt To Income Ratio Calculator
          </h2>
          <Divider />
          <Form
            onFinish={handleCalculate}
            layout="vertical"
            initialValues={{ remember: true }}
          >
            <Row>
              {/* <Col xs={24} md={8}>
                <Form.Item
                  label="Rate"
                  name="rate"
                  rules={[
                    {
                      required: true,
                      message: "rate is required!",
                    },
                  ]}
                >
                  <InputNumber style={{ width: "100%" }} />
                </Form.Item>
              </Col>
              <Col xs={24} md={8}>
                <Form.Item
                  label="Tenure"
                  name="tenure"
                  rules={[
                    {
                      required: true,
                      message: "tenure is required!",
                    },
                  ]}
                >
                  <InputNumber style={{ width: "100%" }} />
                </Form.Item>
              </Col> */}
              <Col xs={24} md={24}>
                <Form.Item
                  label="DTI"
                  name="dti"
                  rules={[
                    {
                      required: true,
                      message: "dti is required!",
                    },
                  ]}
                >
                  <Input suffix="%" style={{ width: "100%" }} />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item>
              <div className="flex justify-end">
                <Button
                  htmlType="submit"
                  loading={loading}
                  size="large"
                  style={{
                    backgroundColor: "#00af7b",
                    color: "#fff",
                    width: "100%",
                  }}
                >
                  Calculate
                </Button>
              </div>
            </Form.Item>
          </Form>
        </Card>
      </Col>
      <Col xs={24} md={12}>
        <Card style={{ height: "100%", padding: 20 }}>
          <h2 className="text-center text-lg font-bold my-3">
            Debt To Income Ratio
          </h2>

          <p className="text-center">
            With a
            <span className="text-green-500 font-bold text-lg mx-2">
              {dti} %
            </span>
            DTI,
            <span className="text-green-500 font-bold text-lg mx-1">
              {healthFacility?.name}
            </span>
            will be able to pay back
            <span className="text-green-500 font-bold text-lg mx-2 break-all">
              {currency(estimated_monthly)}
            </span>
            every month.
          </p>
        </Card>
      </Col>
    </Row>
  );
}

export default DTI;
