import React from "react";
import { Card, Tabs } from "antd";
import PersonalDetails from "./healthDetails/PersonalDetails";
import HealthFacility from "./healthDetails/HealthFacility";
import FacilityExpenses from "./healthDetails/FacilityExpenses";
import BankDetails from "./healthDetails/BankDetails";
import VerificationDetails from "./healthDetails/Verification";
import BankStatement from "../Bankstatement";
import Activities from "./details/Activities";
const { TabPane } = Tabs;

function callback(key) {
  return key;
}

function HealthInfo({
  userDetail,
  healthFacility,
  facilityExpense,
  bankDetail,
  bvnDetail,
  verification,
  loan_request,
}) {
  return (
    <div className="mt-2">
      <Card loading={!userDetail?.firstname}>
        <Tabs defaultActiveKey="1" onChange={callback}>
          <TabPane tab="Personal Details" key="1">
            <PersonalDetails userDetails={userDetail} />
          </TabPane>
          <TabPane tab="Health Facility" key="2">
            <HealthFacility healthFacilities={healthFacility} />
          </TabPane>
          <TabPane tab="Facility Expenses" key="3">
            <FacilityExpenses facilityExpenses={facilityExpense} />
          </TabPane>
          <TabPane tab="Bank Details" key="4">
            <BankDetails bankDetails={bankDetail} />
          </TabPane>
          <TabPane tab="Verification & Documents" key="5">
            <VerificationDetails
              loan_request={loan_request}
              userDetails={userDetail}
              bvnDetails={bvnDetail}
              documents={verification}
              bankDetails={bankDetail}
            />
          </TabPane>

          <TabPane tab="Bank Statements" key="6">
            <BankStatement id={userDetail?.id} userDetails={userDetail} />
          </TabPane>
          <TabPane tab="Activities" key="7">
            <Activities loan_request={loan_request} />
          </TabPane>
        </Tabs>
      </Card>
    </div>
  );
}

export default HealthInfo;
