import React, { useState } from "react";
import { Link, useRouteMatch } from "react-router-dom";
import {
  AppstoreOutlined,
  LeftOutlined,
  RightOutlined,
} from "@ant-design/icons";
import Home from "../../assets/customIcons/home";
import List from "../../assets/customIcons/list";
import Tag from "../../assets/customIcons/tag";
import Folder from "../../assets/customIcons/folder";
import CreditCard from "../../assets/customIcons/creditCard";
import People from "../../assets/customIcons/people";
import { useAuth } from "../../context/AuthContext";

function SiderBar(props) {
  const { user } = useAuth();
  const [expand, setExpand] = useState(true);
  return (
    <div
      className={`flex-shrink-0 ${expand ? "" : "w-64"} overflow-y-auto   ${
        expand ? "" : "px-5"
      } sidebar md:block hidden h-full`}
    >
      <div className="flex justify-end py-3 px-2">
        {expand ? (
          <RightOutlined
            style={{ fontSize: "20px" }}
            className="cursor-pointer"
            onClick={() => setExpand(false)}
          />
        ) : (
          <LeftOutlined
            style={{ fontSize: "20px" }}
            className="cursor-pointer"
            onClick={() => setExpand(true)}
          />
        )}
      </div>
      <div className="pb-8">
        {user?.data?.lead_generation && (
          <SideLink
            label="Dashboard"
            to="/home"
            activeOnlyWhenExact={true}
            expand={expand}
            icon="home"
          />
        )}

        {/* <Dropdwon /> */}
        {user?.data?.lead_generation && (
          <SideLink
            label="Request"
            to="/requests"
            activeOnlyWhenExact={true}
            expand={expand}
            icon="list"
          />
        )}

        {/* {user?.data?.type === "micro" && (
        <SideLink
          label="Money Market"
          to="/microloan"
          activeOnlyWhenExact={true}
           expand={expand}
          icon="list"
        />
      )} */}

        {user?.data?.disburse_with_ec === "yes" && (
          <SideLink
            label="Loans"
            to="/loans"
            activeOnlyWhenExact={false}
            expand={expand}
            icon="tag"
          />
        )}
        {user?.data?.disburse_with_ec === "yes" && (
          <SideLink
            label="Disbursements"
            to="/disbursements"
            activeOnlyWhenExact={false}
            expand={expand}
            icon="folder"
          />
        )}
        {user?.data?.disburse_with_ec === "yes" && (
          <SideLink
            label="Repayments"
            to="/repayments"
            activeOnlyWhenExact={false}
            expand={expand}
            icon="creditCard"
          />
        )}

        {user?.data?.bs_analyzer && (
          <SideLink
            label="Analyser"
            to="/analyser"
            activeOnlyWhenExact={false}
            expand={expand}
            icon="folder"
          />
        )}

        {user?.data?.bs_analyzer && (
          <SideLink
            label="API Apps"
            to="/apps"
            activeOnlyWhenExact={true}
            expand={expand}
            icon="app"
          />
        )}
      </div>
    </div>
  );
}

function SideLink({ label, to, activeOnlyWhenExact, icon, expand }) {
  let match = useRouteMatch({
    path: to,
    exact: activeOnlyWhenExact,
  });

  const iconselect = (icon, match) => {
    const icons = {
      home: <Home match={match} />,
      list: <List match={match} />,
      tag: <Tag match={match} />,
      folder: <Folder match={match} />,
      creditCard: <CreditCard match={match} />,
      people: <People match={match} />,
      app: (
        <AppstoreOutlined
          style={{ fontSize: "20px", color: match ? "white" : "gray" }}
          className="block"
        />
      ),
    };

    return icons[icon];
  };

  return (
    <div
      className={`my-4 flex items-center  ${match ? "match" : ""}  ${
        expand ? "" : "px-5 rounded-md"
      } h-10`}
    >
      <Link
        className={`no-underline flex-grow `}
        style={{ color: match ? "#fff" : "#7E84A3" }}
        to={to}
      >
        <div>{iconselect(icon, match)}</div>
      </Link>
      <Link
        className={`no-underline flex-grow ${expand ? "hidden" : ""}`}
        style={{ color: match ? "#fff" : "#7E84A3" }}
        to={to}
      >
        {label}
      </Link>
    </div>
  );
}

export default SiderBar;
