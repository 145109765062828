import React from "react";
import { Divider, Row, Col } from "antd";

function PersonalDetails({ userDetails, loan_request }) {
  return (
    <div className="mt-4">
      <Row gutter={[10, 10]}>
        <Col xs={24} md={8}>
          <div className="flex flex-col justify-between  h-10 ">
            <h3 className="text-gray-500"> First Name</h3>
            <h3>{userDetails?.firstname}</h3>
          </div>
          <Divider />
        </Col>

        <Col xs={24} md={8}>
          <div className="flex flex-col justify-between  h-10">
            <h3 className="text-gray-500">Last Name</h3>
            <h3>{userDetails?.lastname}</h3>
          </div>
          <Divider />
        </Col>

        <Col xs={24} md={8}>
          <div className="flex flex-col justify-between  h-10">
            <h3 className="text-gray-500"> Marital Status </h3>
            <h3 className="text-black">{userDetails?.marital_status}</h3>
          </div>
          <Divider />
        </Col>

        <Col xs={24} md={8}>
          <div className="flex flex-col justify-between  h-10">
            <h3 className="text-gray-500"> Email </h3>
            <h3 className="text-black">
            { loan_request?.product_id === "ckl6lnutkau5a09023mo3irar"  ? "*****" : 
              <a
                target="_blank"
                rel="noreferrer"
                href={`mailto:${userDetails?.email}`}
              >
                {userDetails?.email}
              </a>
            }
            </h3>
          </div>
          <Divider />
        </Col>

        <Col xs={24} md={8}>
          <div className="flex flex-col justify-between  h-10">
            <h3 className="text-gray-500"> Phone </h3>
            <h3 className="text-black">
            { loan_request?.product_id === "ckl6lnutkau5a09023mo3irar"  ? "*****" : 
              <a
                target="_blank"
                rel="noreferrer"
                href={`https://wa.me/234${userDetails?.phone}`}
              >
                {userDetails.phone}
              </a>
            }
            </h3>
          </div>
          <Divider />
        </Col>

        <Col xs={24} md={8}>
          <div className="flex flex-col justify-between  h-10">
            <h3 className="text-gray-500"> State </h3>
            <h3 className="text-black">{userDetails?.state_of_residence}</h3>
          </div>
          <Divider />
        </Col>

        <Col xs={24} md={8}>
          <div className="flex flex-col justify-between  h-10">
            <h3 className="text-gray-500"> Gender </h3>
            <h3 className="text-black">{userDetails?.gender}</h3>
          </div>
          <Divider />
        </Col>

        <Col xs={24} md={8}>
          <div className="flex flex-col justify-between  h-10">
            <h3 className="text-gray-500"> Address </h3>
            <h3 className="text-black"> { loan_request?.product_id === "ckl6lnutkau5a09023mo3irar"  ? "*****" : userDetails?.address}</h3>
          </div>
          <Divider />
        </Col>

        <Col xs={24} md={8}>
          <div className="flex flex-col justify-between  h-10">
            <h3 className="text-gray-500">Next of kin full name</h3>
            <h3 className="text-black">{ loan_request?.product_id === "ckl6lnutkau5a09023mo3irar"  ? "*****" : userDetails?.next_of_kin?.fullname}</h3>
          </div>
          <Divider />
        </Col>

        <Col xs={24} md={8}>
          <div className="flex flex-col justify-between  h-10">
            <h3 className="text-gray-500">Next of kin email</h3>
            <h3 className="text-black"> { loan_request?.product_id === "ckl6lnutkau5a09023mo3irar"  ? "*****" : userDetails?.next_of_kin?.email}</h3>
          </div>
          <Divider />
        </Col>

        <Col xs={24} md={8}>
          <div className="flex flex-col justify-between  h-10">
            <h3 className="text-gray-500">Next of kin phone number</h3>
            <h3 className="text-black"> { loan_request?.product_id === "ckl6lnutkau5a09023mo3irar"  ? "*****" : userDetails?.next_of_kin?.phone}</h3>
          </div>
          <Divider />
        </Col>
      </Row>
    </div>
  );
}

export default PersonalDetails;
