import React from "react";

function BankImage({ src, onClick, id, currentID, bankName }) {
  return (
    <div
      onClick={onClick}
      className={`border-2 cursor-pointer transition ${
        id === currentID ? "border-purple-700" : "border-gray-300"
      }  p-2 rounded-md flex justify-center`}
    >
      <img
        src={src}
        alt={bankName}
        style={{ width: "100px", height: "100px" }}
        className="rounded"
      />
    </div>
  );
}

export default BankImage;
